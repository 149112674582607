<section class="bg-gray-light" id="paymentsSection">
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="alert alert-warning my-3" role="alert" *ngIf="!canSubmitCustomAccount">
          Your business profile is not fully completed. We need your bank details, please complete all required fields plus : <br/>
          <span *ngIf="business.businessType === 'company' && !business.taxId">
            - <a [routerLink]="['/list-business/step-1']">your business registration number</a><br/>
          </span>
          <span *ngIf="business.businessType === 'company' && !business.website">
            - <a [routerLink]="['/list-business/step-1']">your business website</a><br/>
          </span>
          <span *ngIf="!business.representativeIdentityProof">
          - <a [routerLink]="['/list-business/step-3']">your government-issued ID</a><br/>
          </span>
        </div>
        <div class="text-center my-7" *ngIf="payments.length === 0">
          <p>Hey you don't have any payments listed yet.</p>
          <button class="btn btn-primary d-block mt-3 mx-auto" [routerLink]="['/list-product']">START SELLING</button>
        </div>
        <div class="card" *ngIf="payments.length">
          <div class="card-header" id="headingPaymentsList">
            <h2 class="mb-0">
              <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#paymentsList" aria-expanded="true" aria-controls="paymentsList">
                MY SALES <span class="primary-color">({{payments.length}})</span>
              </button>
            </h2>
          </div>

          <div id="paymentsList" class="collapse show" aria-labelledby="headingPaymentsList">
            <div class="card-body" *ngFor="let payment of payments; let i=index">
                <div class=" mt-3">
                  <p class="text-truncate mb-0"><b>REFERENCE:</b> {{payment.id}}</p>
                  <p class="text-truncate mb-0"><b>DATE:</b> {{payment.charger_at | timeToLabel :'31/MM/YYYY'}}</p>
                  <p class="text-truncate mb-0"><b>MY PROFIT:</b> {{payment.amount | currency:'AUD':'symbol'}}</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
