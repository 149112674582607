<section class="bg-gray-light">
  <div class="container pt-5 py-md-5">
    <form class="" (ngSubmit)="updateProduct()" #updateProductForm="ngForm">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="form-group">
            <label class="col-form-label">ARE YOU SHIPPING FROM YOUR BUSINESS ADRESS?</label>
            <div class="form-group">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="isShippingFromBusiness" [(ngModel)]="product.isShippingFromBusiness" (change)="onChange()" id="isShippingFromBusiness" [value]="true" required>
                <label class="form-check-label" for="isShippingFromBusiness">YES</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" [(ngModel)]="product.isShippingFromBusiness" name="isShippingFromBusiness" (change)="onChange()" id="isNotShippingFromBusiness" [value]="false">
                <label class="form-check-label" for="isNotShippingFromBusiness">NO, CREATE A NEW</label>
              </div>
            </div>
          </div>
          <div *ngIf="!product.isShippingFromBusiness && product.shippingFromAddress">
            <div class="form-group">
              <label class="col-form-label" for="streetAddress">STREET<span class="required">*</span></label>
              <input type="text" class="form-control form-control-lg" id="streetAddress" placeholder="Enter your street address" [(ngModel)]="product.shippingFromAddress.streetAddress" (change)="onChange()" name="streetAddress" required>
            </div>
            <div class="form-group">
              <label class="col-form-label" for="city">CITY<span class="required">*</span></label>
              <input type="text" class="form-control form-control-lg" id="city" placeholder="Enter your city" [(ngModel)]="product.shippingFromAddress.city" (change)="onChange()" name="city" required>
            </div>
            <div class="form-group">
              <label class="col-form-label" for="postcode">POSTCODE<span class="required">*</span></label>
              <input type="number" class="form-control form-control-lg" id="postcode" placeholder="Enter your postcode" [(ngModel)]="product.shippingFromAddress.postcode" (change)="onChange()" name="postcode" required>
            </div>
            <div class="form-group">
              <label class="col-form-label" for="state">STATE<span class="required">*</span></label>
              <input type="text" class="form-control form-control-lg" id="state" placeholder="Enter your state" [(ngModel)]="product.shippingFromAddress.state" (change)="onChange()" name="state" required>
            </div>
            <div class="form-group" *ngIf="countries">
              <label class="col-form-label" for="country">COUNTRY<span class="required">*</span></label>
              <select class="form-control form-control-lg" id="country" [(ngModel)]="selectedCounty" (ngModelChange)="selectCountry($event)" name="country" required>
                <option *ngFor="let item of countries" [ngValue]="item" >{{item.name}}{{item.alpha2? ' (' + item.alpha2 + ')' : ''}}</option>
              </select>
            </div>
          </div>

          <hr/>
          <div class="row mt-5 mb-5">
            <div class="col-6 text-left">
              <button type="button" class="btn btn-outline-primary" (click)="goBack()">GO BACK</button>
            </div>
            <div class="col-6 text-right">
              <button type="button" *ngIf="!product.hasAgreeToTerms" class="btn btn-primary" data-toggle="modal" data-target="#agreeToTermsModal" [disabled]="!updateProductForm.form.valid">CONFIRM</button>
              <button type="submit" *ngIf="product.hasAgreeToTerms" class="btn btn-primary" [disabled]="!updateProductForm.form.valid">CONFIRM</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>


<div class="modal fade" id="agreeToTermsModal" tabindex="-1" role="dialog" aria-labelledby="agreeToTermsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content position-relative" *ngIf="!showTerms">
      <div class="modal-header pb-0">
        <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body pt-0 text-center">
        <h5><span class="text-capitalize">{{user.firstName}}</span>, you have completed your listing!</h5>
        <p>Before we can review and approve your listing, we need you to confirm.</p>
      </div>
      <div class="text-right modal-footer-outside">
        <button type="button" class="btn btn-primary" (click)="showTerms = true;">CONFIRM</button>
      </div>
    </div>
    <div class="modal-content position-relative" *ngIf="showTerms">
      <div class="modal-header pb-0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-0 text-center">
        <h5><span class="text-capitalize">{{user.firstName}}</span>, you have completed your listing!</h5>
        <p>Before we can review and approve your listing, we need you to confirm.</p>
        <div class="row">
          <div class="col-6 text-right">
            <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close">No</button>
          </div>
          <div class="col-6 text-left">
            <button type="button" class="btn btn-primary" (click)="agreeToTerms()">Yes</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
