<header class="d-flex">
  <div class="container text-center mt-auto">
    <!-- Content here -->
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-9 mt-5">
        <span *ngIf="user && user.profileImage" [style.background-image]="'url(' + user.profileImage + ')'" class="custom-avatar avatar-img"></span>
        <img *ngIf="!user || !user.profileImage" height="80" alt="Luxify" src="../../assets/images/logo/luxify-letter-logo-revers.svg">
        <h1 class="mt-3 mb-5">MY ACCOUNT</h1>
        <nav class="nav nav-pills flex-column flex-sm-row">
          <button class="flex-sm-fill text-sm-center nav-link active" [ngClass]="{'active': show.profile}" (click)="switchView('profile')">MY PROFILE</button>
          <button class="flex-sm-fill text-sm-center nav-link" [ngClass]="{'active': show.products}" (click)="switchView('products')">MY PRODUCT LISTING</button>
          <button class="flex-sm-fill text-sm-center nav-link" [ngClass]="{'active': show.sales}" (click)="switchView('sales')">MY SALES & ORDERS</button>
          <button class="flex-sm-fill text-sm-center nav-link" [ngClass]="{'active': show.payments}" (click)="switchView('payments')">MY PAYMENTS</button>
          <button class="flex-sm-fill text-sm-center nav-link" [ngClass]="{'active': show.help}" (click)="switchView('help')">HELP CENTRE</button>
        </nav>
      </div>
    </div>
  </div>
</header>

<section class="bg-gray-light" *ngIf="!accountLink.isVerified || updateAccountReturn">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="mt-3 text-center" >
          <div class="alert alert-warning text-left" role="alert" *ngIf="!updateAccountReturn && !accountLink.isVerified && accountLink.url">
            You need to provide more details to activate your payout :<br/>
            <small class="small">- <u><b><a [attr.href]="accountLink.url" [href]="accountLink.url">{{accountLink.disabled_reason || 'here'}}</a></b></u></small>
            <br/>
            <small class="small">The validation can take few hours, if you already submit the update please wait minimum a day.</small>
          </div>
          <div class="alert alert-success" role="alert" *ngIf="updateAccountReturn && updateAccountReturn === 'success'">
            Your payout has been successfully updated!
          </div>
          <div class="alert alert-danger" role="alert" *ngIf="updateAccountReturn && updateAccountReturn === 'failure'">
            An error occurred please try again :<br/>
            - <a [attr.href]="accountLink.url" [href]="accountLink.url"><u><b>{{accountLink.disabled_reason || 'here'}}</b></u></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-account-profile *ngIf="show.profile && loadedData" [user]="user" [business]="business" [bankDetails]="bankDetails"></app-account-profile>
<app-account-products *ngIf="show.products && loadedData"  [user]="user" [business]="business"></app-account-products>
<app-account-sales *ngIf="show.sales && loadedData" [user]="user"></app-account-sales>
<app-account-payments *ngIf="show.payments && loadedData" [user]="user" [business]="business"></app-account-payments>

<section *ngIf="show.help">
  <section class="bg-gray-light">
    <div class="container pt-5 py-md-5">
      <div class="row justify-content-center">
        <div class="col-lg-9 text-center my-5 mb-5">
          <h3 class="mb-5">Help & Support</h3>
          <p>If you need to contact us,<br/> you can send us an email at <a href="mailto:support@luxify.com">support@luxify.com</a></p>
        </div>
      </div>
    </div>
  </section>
</section>
