<section class="bg-gray-light photo-section">
  <div class="container pt-5 py-md-5">
    <form class="" (ngSubmit)="goNext()" #updateProductMediasForm="ngForm">
      <div class="row justify-content-center">
        <div class="col-lg-9" *ngIf="product.images && fileUploadFolder">
          <div class="form-group mb-0">
          <label class="col-form-label">PHOTOS<span class="required">*</span></label>
          <p>When taking your photos, make sure to place your items on a white background so it is clearly visible.</p>
          <p class="primary-color small font-italic">The file must be JPG, JPEG or PNG and less than 5MB.</p>
          </div>
          <hr/>
          <div class="row photo-row" *ngFor="let item of photosList; let i=index" >
            <div class="col-sm-5">
              <div class="form-group mb-0">
                <label class="col-form-label" [for]="'image-'+i" [ngClass]="{'mt-5': i !== 0}">{{i === 0 ? 'MAIN PHOTO' : 'PHOTO '+ i}}<span class="required">*</span></label>
                <p>{{item.label}}</p>
                <app-file-upload [currentfileurl]="product.images[i] ? product.images[i].src : null" [folder]="fileUploadFolder" [hide-preview]="false" [view-height]="200" [max-size]="5242880" [is-public]="true"
                             [extensions]="['jpg','jpeg','png']" (fileDeleted)="deleteIdFilePath(i, 'images')" (filepath)="getIdFilePath($event, i, 'images')" [id]="item.key"></app-file-upload>
              </div>
            </div>
            <div class="col-sm-2 position-relative" *ngIf="item.image">
              <hr class="hr-vertical"  [ngClass]="{'first-hr': i === 0}" />
            </div>
            <div class="col-sm-5 d-none d-sm-block text-center" *ngIf="item.image">
              <div class="form-group mb-0" >
                <label class="col-form-label" *ngIf="i === 0">EXAMPLES</label>
                <div class="example-photo" [ngClass]="{'first-photo': i === 0}">
                  <img [src]="item.image" class="img-fluid mx-auto d-block" alt="Distributors and Resellers">
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="row photo-row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-form-label mb-5" for="video">VIDEO (Optional)</label>
                <app-file-upload *ngIf=" fileUploadFolder" [currentfileurl]="product.video" [folder]="fileUploadFolder" [hide-preview]="false" [view-height]="200" [max-size]="5242880" [media-type]="'video'" [is-public]="true"
                             [extensions]="['mov','mpeg4','avi', 'wmv', 'mpeg-ps', 'flv', '3gpp', 'webm', 'mp4']" (fileDeleted)="deleteIdFilePath(0, 'video')" (filepath)="getIdFilePath($event, 0, 'video')" id="video"></app-file-upload>
              </div>
            </div>
            <div class="col-sm-6">
              <p class="mt-5">You can add a video to show your item more in details.</p>
              <p class="primary-color small font-italic">The file must be a MOV, MPEG4, AVI, WMV, MPEG-PS, FLV, 3GPP or WebM and no less than 5 MB.</p>
            </div>
          </div>

          <hr/>

          <div class="row mt-5 mb-5">
            <div class="col-6 text-left">
              <button type="button" class="btn btn-outline-primary" (click)="goBack()">GO BACK</button>
            </div>
            <div class="col-6 text-right">
              <button type="submit" class="btn btn-primary" [disabled]="!updateProductMediasForm.form.valid || !isValid()">CONFIRM</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
