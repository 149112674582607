import {
  Component,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver, Input, OnInit
} from '@angular/core';

import {AlertService, AppService, BusinessService, PaymentService, UserService} from '../../../app/_services';
import {Business, User, BusinessPayout} from '../../../app/_models';
import {AlertComponent} from '../../../app/_directives';
import {LoaderService} from '../../../app/shared/loader/loader.service';

@Component({
  selector: 'app-account-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],

})
export class AccountProfileComponent implements OnInit {
  @Input('user') user: User = {};
  @Input('business') business: Business = {};
  @Input('bankDetails') bankDetails: BusinessPayout = {};
  @ViewChild('alertPayoutMessage', { read: ViewContainerRef }) alertPayoutMessage: ViewContainerRef;
  @ViewChild('alertResetPwdMessage', { read: ViewContainerRef }) alertResetPwdMessage: ViewContainerRef;

  datePickerOption = {
    maxDate : {year: (new Date().getFullYear()) - 17, month: 12, day: 31},
    minDate : {year: 1900, month: 1, day: 1}
  };
  fileUploadFolder: string;
  currencies: any[] = this.appService.currenciesList();
  selectedCurrency: any = {};
  needToUpdatePayout = false;
  needToUpdateUser = false;
  needToUpdateBusiness = false;
  isLoading = false;

  edit = {
    identification: false,
    profileImage: false,
    profileDetails: false,
    representative: false,
    businessDetails: false,
    businessProfile: false,
    businessShipping: false,
    bankDetails: false,
  };

  canSubmitCustomAccount = true;

  constructor(private loaderService: LoaderService, private cfr: ComponentFactoryResolver,
              private alertService: AlertService,
              private appService: AppService,
              private paymentService: PaymentService,
              private userService: UserService,
              private businessService: BusinessService) {
  }

  ngOnInit() {
    this.fileUploadFolder = `images/users/${this.user.id}`;
    if (this.user.businessId) {
      this.loaderService.show();
      if (!this.business || !this.business.name || !this.business.address
        || !this.business.address.countryCode || !this.business.representativeIdentityProof
        || (this.business.businessType === 'company' && (!this.business.taxId || !this.business.website))) {
        this.canSubmitCustomAccount = false;
      }
      if (!this.business || !this.business.shippingRates) {
        this.business.shippingRates = [];
      }
      if (this.bankDetails && this.bankDetails.tokenBankAccount) {
        this.bankDetails.last4 = 'XXXXX' + this.bankDetails.last4;
        if (this.currencies.length > 0 && this.bankDetails.currency) {
          this.selectedCurrency = this.currencies.find(x => x.code === this.bankDetails.currency.toUpperCase());
        } else {
          this.selectedCurrency = this.currencies[0];
        }
      }
      this.loaderService.hide();
    }
  }

  selectCurrency(currency) {
    this.selectedCurrency = currency;
    this.bankDetails.currency = currency.code;
    this.needToUpdatePayout = true;
  }

  getIdFilePath(filePath: { absUrl: string, path: string }) {
    this.isLoading = true;
    this.loaderService.show();
    this.user.profileImage = filePath.absUrl;
    this.userService.updateField(this.user, 'profileImage', this.user.profileImage).then(() => {
      this.isLoading = false;
      this.loaderService.hide();
    });
  }

  deleteIdFilePath() {
    this.isLoading = true;
    this.loaderService.show();
    this.user.profileImage = null;

    this.userService.updateField(this.user, 'profileImage', null).then(() => {
      this.isLoading = false;
      this.loaderService.hide();
    });
  }


  onChange(type) {
    if (type === 'user') {
      this.needToUpdateUser = true;
    } else if (type === 'payout') {
      this.needToUpdatePayout = true;
    } else {
      this.needToUpdateBusiness = true;
    }
  }

  updateBusiness() {
    return new Promise((resolve) => {
      if (this.needToUpdateBusiness) {
        this.businessService.update(this.business, this.user)
          .then(() => {
            resolve(true);
          });
      } else {
        resolve(true);
      }
    });
  }

  updateUser() {
    return new Promise((resolve) => {
      if (this.needToUpdateUser) {
        this.userService.update(this.user)
          .then(() => {
            resolve(true);
          });
      } else {
        resolve(true);
      }
    });
  }

  updatePayout() {
    return new Promise((resolve) => {
      if (this.needToUpdatePayout) {
        this.loaderService.show();
        this.paymentService.onSubmitCustomAccount(this.bankDetails, this.business)
          .then((res: any) => {
            if (res.success) {
              this.showAlert('alertPayoutMessage');
              this.alertService.success('Successfully update!');
              resolve(true);
            } else {
              this.editBlock('bankDetails');
              this.showAlert('alertPayoutMessage');
              this.alertService.error(res.message);
              resolve(false);
            }
          });
      } else {

        resolve(true);
      }
    });
  }

  updateAccount() {
    this.isLoading = true;
    this.loaderService.show();
    this.updateBusiness()
      .then(() => {
        return this.updatePayout();
      }).then(() => {
      return this.updateUser();
    }).then(() => {
      this.isLoading = false;
      this.loaderService.hide();
      this.editBlock(null);
    });
  }

  editBlock(block) {
    this.edit = {
      identification: false,
      profileImage: false,
      profileDetails: false,
      representative: false,
      businessDetails: false,
      businessProfile: false,
      businessShipping: false,
      bankDetails: false,
    };
    this.edit[block] = true;
  }

  resetPassword() {
    if (this.isLoading) {
      return false;
    }
    this.isLoading = true;
    this.loaderService.show();
    this.userService.forgotPassword(this.user.email).then(
      () => {
        this.showAlert('alertResetPwdMessage');
        this.alertService.success(
          'Password reset instruction has been sent to your email.',
          true);
        this.isLoading = false;
        this.editBlock(null);
        this.loaderService.hide();
      },
      error => {
        this.showAlert('alertForgotPass');
        this.alertService.error(error);
        this.isLoading = false;
        this.loaderService.hide();
      });
  }

  addRate() {
    this.business.shippingRates.push({min: null, max: null, price: null});
  }
  removeRate(index) {
    this.business.shippingRates.splice(index, 1);
  }

  showAlert(target) {
    this[target].clear();
    const factory = this.cfr.resolveComponentFactory(AlertComponent);
    const ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }
}
