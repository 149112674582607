<div>
  <app-menu></app-menu>
  <div class="router-outlet-content position-relative">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
<app-loader></app-loader>


<!-- SignUp Modal -->
<div class="modal fade" id="SignUpModal" tabindex="-1" role="dialog" aria-labelledby="SignUpModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pb-0">
        <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body pt-0" *ngIf="!needToValidateEmail">
        <h2 class="text-center mb-4">Create an account</h2>
        <p class="text-center">
          Before you can start selling, you have to create an account and start listing your products. Your account and listing will be reviewed and validated by us within 48 hours.
        </p>
        <ng-template #alertSignup></ng-template>
        <form (ngSubmit)="signup()" #signupForm="ngForm" >
          <div class="form-group">
            <input type="text" class="form-control" id="firstName" placeholder="First name" [(ngModel)]="newUser.firstName" name="firstName" required>
          </div>
          <div class="form-group">
            <input type="text" class="form-control" id="lastName" placeholder="Last Name" [(ngModel)]="newUser.lastName" name="lastName" required>
          </div>
          <div class="form-group">
            <input type="email" class="form-control" id="signInEmail" placeholder="Email" [email]="true" [(ngModel)]="newUser.email" name="signInEmail" required>
          </div>
          <div class="form-group">
            <input type="password" class="form-control" id="signInPassword" placeholder="Password" minlength="6" [(ngModel)]="newUser.password" name="signInPassword" required>
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-secondary btn-block" [disabled]="!signupForm.form.valid || isLoading">CREATE ACCOUNT</button>
          </div>
          <p class="text-center mt-3">
            By clicking this button, I agree to the <b><a class="white-color" [routerLink]="['/terms-of-service']">Terms & Conditions</a></b> and <b><a class="white-color" [routerLink]="['/privacy-policy']">Privacy Policy</a></b> of Luxify.
          </p>
          <hr/>
          <div class="text-center">
            <button type="button" class="btn btn-google" (click)="loginGoogleUser()">
              <span class="google-logo">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
              </span>
              <span class="google-label">Login with Google</span>
            </button>
          </div>
        </form>
        <p class="text-center">
          <button type="button" class="btn btn-link " (click)="showSignIn()">Login with your email</button>
        </p>
      </div>
      <div class="modal-body pt-0" *ngIf="needToValidateEmail">
        <h2 class="text-center mb-4">Thank you!</h2>
        <p class="text-center">
          To complete your registration please check your email.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="SignInModal" tabindex="-1" role="dialog" aria-labelledby="SignUpModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" *ngIf="show.signIn">
      <div class="modal-header pb-0">
        <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body pt-0">
        <h2 class="text-center mb-4">Login</h2>
        <form class="" (ngSubmit)="signin()" #signinForm="ngForm">
          <ng-template #alertSignin></ng-template>

          <button *ngIf="show.resendVerifiedEmail" type="button" class="btn btn-link float-right"
                  (click)="resendVerifiedEmail()">Re-send validation email !
          </button>
          <div class="form-group">
            <input type="email" class="form-control" [(ngModel)]="model.email" name="SignUpEmail" id="SignUpEmail"
                   placeholder="Email" [email]="true" required>
          </div>
          <div class="form-group">
            <input type="password" class="form-control" [(ngModel)]="model.password" name="SignUpPassword"
                   id="SignUpPassword" placeholder="Password" required>
          </div>

          <p class="text-right">
            <button type="button" class="btn btn-link " (click)="showLoginTab('forgotPass')">Forgot password ?</button>
          </p>
          <div class="text-center">
            <button type="submit" class="btn btn-secondary btn-block text-uppercase" [disabled]="!signinForm.form.valid || isLoading">Sign in</button>
          </div>
        </form>
        <hr/>
        <div class="text-center">
          <button type="button" class="btn btn-google" (click)="loginGoogleUser()">
              <span class="google-logo">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
              </span>
            <span class="google-label">login with Google</span>
          </button>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-link btn-block" (click)="showSignUp()">Sign up with your email</button>
        </div>
      </div>
    </div>

    <div class="modal-content" *ngIf="show.forgotPass">
      <div class="modal-header pb-0">
        <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body pt-0">
        <h2 class="text-center mb-4">Forgot password ?</h2>
        <form class="" (ngSubmit)="forgotPass()" #forgotPassForm="ngForm" *ngIf="!needToValidateEmail">
          <ng-template #alertForgotPass></ng-template>

          <div class="form-group">
            <input type="email" class="form-control" [(ngModel)]="model.email" name="forgotPassEmail"
                   id="forgotPassEmail" [email]="true" placeholder="Email" required>
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-secondary btn-block" [disabled]="!forgotPassForm.form.valid || isLoading">SEND</button>
          </div>
        </form>
        <p *ngIf="needToValidateEmail" class="white-color">Password recovery instruction has been sent to your email.</p>
        <p class="text-center">
          <button type="button" class="btn btn-link " (click)="showLoginTab('signIn')">Back to Sign in ?</button>
        </p>
      </div>
    </div>
  </div>
</div>

