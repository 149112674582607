import {
  Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';

import {BusinessService} from '../../../app/_services';
import {BusinessPayout, Business, User} from '../../../app/_models';
import {LoaderService} from '../../../app/shared/loader/loader.service';

@Component({
  selector: 'app-list-business-representative',
  templateUrl: './representative.component.html',
  styleUrls: ['./representative.component.scss'],

})
export class ListBusinessRepresentativeComponent implements OnInit {
  @Input('business') business: Business = {};
  @Input('user') user: User = {};
  @Input('fileUploadFolder') fileUploadFolder: string;
  @Output() stepReturn: EventEmitter<{ step: string, business: Business, customAccount: BusinessPayout}>
    = new EventEmitter<{ step: string, business: Business, customAccount: BusinessPayout }>();
  datePickerOption = {
    maxDate : {year: (new Date().getFullYear()) - 17, month: 12, day: 31},
    minDate : {year: 1900, month: 1, day: 1}
  };
  isLoading: boolean;
  needToUpdate = false;
  isPhoneNumberValid = true;

  constructor(private loaderService: LoaderService, private businessService: BusinessService) {}

  ngOnInit() {
    if (!this.business.representativeIdentityProof) {
      this.business.representativeIdentityProof = null;
    }
  }

  goBack() {
    return this.stepReturn.emit({step: 'profile', business : null, customAccount: null});
  }

  onPhoneChanges(phoneNumber: string): void {
    this.business.representativePhone = phoneNumber;
    this.needToUpdate = true;
  }
  onPhoneError(isSuccess: boolean): void {
    this.isPhoneNumberValid = isSuccess;
  }

  getIdFilePath(filePath: { absUrl: string, path: string }) {
    this.isLoading = true;
    this.loaderService.show();
    // product: Product, fieldName: string, data: any
    this.business.representativeIdentityProof = filePath.absUrl;
    this.businessService
      .updateField(this.business, 'representativeIdentityProof', this.business.representativeIdentityProof)
      .then(() => {
      this.isLoading = false;
      this.loaderService.hide();
    });
  }
  deleteIdFilePath() {
    this.isLoading = true;
    this.loaderService.show();
    // product: Product, fieldName: string, data: any
    this.business.representativeIdentityProof = null;
    this.businessService
      .updateField(this.business, 'representativeIdentityProof', null)
      .then(() => {
      this.isLoading = false;
      this.loaderService.hide();
    });
  }

  updateBusiness() {
    if (!this.needToUpdate) {
      return this.stepReturn.emit({step: 'shipping', business : null, customAccount: null});
    }
    this.isLoading = true;
    this.loaderService.show();
    this.businessService.update(this.business, this.user).then(() => {
      return this.stepReturn.emit({step: 'shipping', business : this.business, customAccount: null});
    }).then(() => {
      this.isLoading = false;
      this.loaderService.hide();
    });
  }

  onChange() {
    this.needToUpdate = true;
  }
}
