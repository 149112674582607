<section class="bg-gray-light">
  <div class="container py-5 py-md-5">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="text-center my-5" *ngIf="!hasProduct">
          <p>Hey you don’t have any product listed yet, so go ahead list your first product.</p>
          <button class="btn btn-primary d-block mt-5 mx-auto" [routerLink]="['/list-product']">START LISTING</button>
          <div class="image-empty-state w-100 mt-5"></div>
        </div>
        <div class="alert alert-warning mb-3 text-center" role="alert" *ngIf="hasProduct && !business.hasAgreeToTerms">
          Your business profile is not fully completed. You didn't agree to Luxify’s <u><a [routerLink]="['/terms-of-service']">Terms & Conditions</a></u>.<br/>Complete your <a [routerLink]="['/list-business/step-6']">business profile</a>
        </div>
        <div class="accordion" id="productsSection">
          <div class="card mb-5" *ngIf="publishedForWomen.length">
            <div class="card-header" id="headingPublishedForWomen">
              <h2 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#publishedForWomen" aria-expanded="true" aria-controls="publishedForWomen">
                  MY PUBLISHED PRODUCT FOR WOMEN <span class="primary-color">({{publishedForWomen.length}})</span>
                </button>
              </h2>
            </div>
            <div id="publishedForWomen" class="collapse show" aria-labelledby="headingPublishedForWomen">
              <div class="card-body" *ngFor="let product of publishedForWomen; let i=index">
                <div class="row">
                  <div class="col-sm-4 col-md-3 row-eq-height d-flex mt-3">
                    <span *ngIf="!product.image" class="custom-avatar m-auto">L</span>
                    <div class="img-preview d-flex" *ngIf="product.image">
                      <img [src]="product.image.src" alt="your uploaded image"
                           class="img-fluid m-auto d-block">
                    </div>
                  </div>
                  <div class="col-sm-8 col-md-6 row-eq-height mt-3">
                    <p class="text-truncate mb-0" *ngIf="product.category"><b>CATEGORY:</b> {{product.category}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.subCategory"><b>SUB-CATEGORY:</b> {{product.subCategory}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.brand"><b>BRAND:</b> {{product.brand}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.variants && product.variants[0]"><b>PRICE:</b> {{product.variants[0].price | currency:'AUD':'symbol'}}</p>
                    <p class="text-truncate mb-2" *ngIf="product.variants && product.variants[0]"><b>FOR ME:</b> {{product.variants[0].price | currency:'AUD':'symbol'}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.published_at">Published ({{product.published_at | timeUntilNow}})</p>
                  </div>
                  <div class="col-md-3 row-eq-height d-flex mt-3">
                    <div class="mt-auto w-100 d-flex d-md-block">
                      <button class="btn btn-primary d-block btn-block my-0 mt-md-3 mr-1 mr-md-0" [routerLink]="['/list-product/prices/' + product.id]">EDIT PRICE</button>
                      <button class="btn btn-primary d-block btn-block my-0 mt-md-3 mx-1 mx-md-0" [routerLink]="['/list-product/medias/' + product.id]">ADD PHOTOS</button>
                      <button type="button" class="btn btn-primary d-block btn-block my-0 mt-md-3 ml-1 ml-md-0" (click)="deleteModal(product)">DELETE</button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-5"  *ngIf="publishedForMen.length">
            <div class="card-header" id="headingPublishedForMen">
              <h2 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#publishedForMen" aria-expanded="true" aria-controls="publishedForMen">
                  MY PUBLISHED PRODUCT FOR MEN <span class="primary-color">({{publishedForMen.length}})</span>
                </button>
              </h2>
            </div>
            <div id="publishedForMen" class="collapse show" aria-labelledby="headingPublishedForMen">
              <div class="card-body" *ngFor="let product of publishedForMen; let i=index">
                <div class="row">
                  <div class="col-sm-4 col-md-3 row-eq-height d-flex mt-3">
                    <span *ngIf="!product.image" class="custom-avatar m-auto">L</span>
                    <div class="img-preview d-flex" *ngIf="product.image">
                      <img [src]="product.image.src" alt="your uploaded image"
                           class="img-fluid m-auto d-block">
                    </div>
                  </div>
                  <div class="col-sm-8 col-md-6 row-eq-height mt-3">
                    <p class="text-truncate mb-0" *ngIf="product.category"><b>CATEGORY:</b> {{product.category}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.subCategory"><b>SUB-CATEGORY:</b> {{product.subCategory}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.brand"><b>BRAND:</b> {{product.brand}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.variants && product.variants[0]"><b>PRICE:</b> {{product.variants[0].price | currency:'AUD':'symbol'}}</p>
                    <p class="text-truncate mb-2" *ngIf="product.variants && product.variants[0]"><b>FOR ME:</b> {{product.variants[0].price | currency:'AUD':'symbol'}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.published_at">Published ({{product.published_at | timeUntilNow}})</p>
                  </div>
                  <div class="col-md-3 row-eq-height d-flex mt-3">
                    <div class="mt-auto w-100 d-flex d-md-block">
                      <button class="btn btn-primary d-block btn-block my-0 mt-md-3 mr-1 mr-md-0" [routerLink]="['/list-product/prices/' + product.id]">EDIT PRICE</button>
                      <button class="btn btn-primary d-block btn-block my-0 mt-md-3 mx-1 mx-md-0" [routerLink]="['/list-product/medias/' + product.id]">ADD PHOTOS</button>
                      <button type="button" class="btn btn-primary d-block btn-block my-0 mt-md-3 ml-1 ml-md-0" (click)="deleteModal(product)">DELETE</button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-5" *ngIf="publishedForUnisex.length">
            <div class="card-header" id="headingPublishedForUnisex">
              <h2 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#publishedForUnisex" aria-expanded="true" aria-controls="publishedForUnisex">
                  MY PUBLISHED PRODUCT FOR {{publishedForMen.length || publishedForWomen.length ? 'BOTH' : 'MEN & WOMEN'}} <span class="primary-color">({{publishedForUnisex.length}})</span>
                </button>
              </h2>
            </div>
            <div id="publishedForUnisex" class="collapse show" aria-labelledby="headingPublishedForUnisex">
              <div class="card-body" *ngFor="let product of publishedForUnisex; let i=index">
                <div class="row">
                  <div class="col-sm-4 col-md-3 row-eq-height d-flex mt-3">
                    <span *ngIf="!product.image" class="custom-avatar m-auto">L</span>
                    <div class="img-preview d-flex" *ngIf="product.image">
                      <img [src]="product.image.src" alt="your uploaded image"
                           class="img-fluid m-auto d-block">
                    </div>
                  </div>
                  <div class="col-sm-8 col-md-6 row-eq-height mt-3">
                    <p class="text-truncate mb-0" *ngIf="product.category"><b>CATEGORY:</b> {{product.category}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.subCategory"><b>SUB-CATEGORY:</b> {{product.subCategory}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.brand"><b>BRAND:</b> {{product.brand}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.variants && product.variants[0]"><b>PRICE:</b> {{product.variants[0].price | currency:'AUD':'symbol'}}</p>
                    <p class="text-truncate mb-2" *ngIf="product.variants && product.variants[0]"><b>FOR ME:</b> {{product.variants[0].price | currency:'AUD':'symbol'}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.published_at">Published ({{product.published_at | timeUntilNow}})</p>
                  </div>
                  <div class="col-md-3 row-eq-height d-flex mt-3">
                    <div class="mt-auto w-100 d-flex d-md-block">
                      <button class="btn btn-primary d-block btn-block my-0 mt-md-3 mr-1 mr-md-0" [routerLink]="['/list-product/prices/' + product.id]">EDIT PRICE</button>
                      <button class="btn btn-primary d-block btn-block my-0 mt-md-3 mx-1 mx-md-0" [routerLink]="['/list-product/medias/' + product.id]">ADD PHOTOS</button>
                      <button type="button" class="btn btn-primary d-block btn-block my-0 mt-md-3  ml-1 ml-md-0" (click)="deleteModal(product)">DELETE</button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-5" *ngIf="notValidated.length">
            <div class="card-header" id="headingNotValidated">
              <h2 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#notValidated" aria-expanded="true" aria-controls="notValidated">
                  PRODUCTS WAITING FOR APPROVAL <span class="primary-color">({{notValidated.length}})</span>
                </button>
              </h2>
            </div>
            <div id="notValidated" class="collapse show" aria-labelledby="headingNotValidated">
              <div class="card-body" *ngFor="let product of notValidated; let i=index">
                <div class="row">
                  <div class="col-sm-4 col-md-3 row-eq-height d-flex mt-3">
                    <span *ngIf="!product.image" class="custom-avatar m-auto">L</span>
                    <div class="img-preview d-flex" *ngIf="product.image">
                      <img [src]="product.image.src" alt="your uploaded image"
                           class="img-fluid m-auto d-block">
                    </div>
                  </div>
                  <div class="col-sm-8 col-md-6 row-eq-height mt-3">
                    <p class="text-truncate mb-0" *ngIf="product.category"><b>CATEGORY:</b> {{product.category}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.subCategory"><b>SUB-CATEGORY:</b> {{product.subCategory}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.brand"><b>BRAND:</b> {{product.brand}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.variants && product.variants[0]"><b>PRICE:</b> {{product.variants[0].price | currency:'AUD':'symbol'}}</p>
                    <p class="text-truncate mb-2" *ngIf="product.variants && product.variants[0]"><b>FOR ME:</b> {{product.variants[0].price | currency:'AUD':'symbol'}}</p>
                    <p class="text-truncate mb-0" *ngIf="product.created_at">Created ({{product.created_at | timeUntilNow}})</p>
                  </div>
                  <div class="col-md-3 row-eq-height d-flex mt-3">
                    <div class="mt-auto w-100">
                      <button type="button" class="btn btn-primary d-block btn-block" (click)="deleteModal(product)">DELETE</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card" *ngIf="notCompleted.length">
            <div class="card-header" id="headingNotCompleted">
              <h2 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#notCompleted" aria-expanded="true" aria-controls="notCompleted">
                  UNCOMPLETED LISTINGS <span class="primary-color">({{notCompleted.length}})</span>
                </button>
              </h2>
            </div>
            <div id="notCompleted" class="collapse show" aria-labelledby="headingNotCompleted">
              <div class="card-body" *ngFor="let product of notCompleted; let i=index">
                <div class="row">
                  <div class="col-sm-4 col-md-3 row-eq-height d-flex mt-3">
                    <span *ngIf="!product.image" class="custom-avatar m-auto">L</span>
                    <div class="img-preview d-flex" *ngIf="product.image">
                      <img [src]="product.image.src" alt="your uploaded image"
                           class="img-fluid m-auto d-block">
                    </div>
                  </div>
                  <div class="col-sm-8 col-md-6 row-eq-height mt-3">
                    <p class="text-truncate mb-0 gray-color" *ngIf="product.category"><b>CATEGORY:</b> {{product.category}}</p>
                    <p class="text-truncate mb-0 gray-color" *ngIf="product.subCategory"><b>SUB-CATEGORY:</b> {{product.subCategory}}</p>
                    <p class="text-truncate mb-0 gray-color" *ngIf="product.brand"><b>BRAND:</b> {{product.brand}}</p>
                    <p class="text-truncate mb-0 gray-color" *ngIf="product.variants && product.variants[0]"><b>PRICE:</b> {{product.variants[0].price | currency:'AUD':'symbol'}}</p>
                    <p class="text-truncate mb-2 gray-color" *ngIf="product.variants && product.variants[0]"><b>FOR ME:</b> {{product.variants[0].price | currency:'AUD':'symbol'}}</p>
                    <p class="text-truncate mb-0 gray-color" *ngIf="product.created_at">Created ({{product.created_at | timeUntilNow}})</p>
                  </div>
                  <div class="col-md-3 row-eq-height d-flex mt-3">
                    <div class="mt-auto w-100 d-flex d-md-block">
                      <button class="btn btn-primary d-block btn-block my-0 mt-md-3 mr-1 mr-md-0" [routerLink]="['/list-product/' + product.id]">FINISH LISTING</button>
                      <button type="button" class="btn btn-primary d-block my-0 mt-md-3 btn-block ml-1 ml-md-0" (click)="deleteModal(product)">DELETE</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<div class="modal fade" id="removeProductModal" tabindex="-1" role="dialog" aria-labelledby="removeProductModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content position-relative">
      <div class="modal-header pb-0">
        <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body pt-0 text-center">

        <div class="form-group">
          <label class="col-form-label">REASON FOR DELETING PRODUCT</label>
          <div class="form-group text-left my-3">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="removeReason" [(ngModel)]="selectedProduct.removeReason" id="noLongerWantIt" value="no-longer-want-it" required>
              <label class="form-check-label text-uppercase" for="noLongerWantIt">I no longer want to sell this item</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" [(ngModel)]="selectedProduct.removeReason" name="removeReason" id="soldElsewhere" value="sold-elsewhere">
              <label class="form-check-label" for="soldElsewhere">I ALREADY SOLD THIS PRODUCT ELSEWHERE</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" [(ngModel)]="selectedProduct.removeReason" name="removeReason" id="other" value="other">
              <label class="form-check-label" for="other">OTHER</label>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right modal-footer-outside">
        <button type="button" class="btn btn-primary" (click)="confirmDelete()" [disabled]="!selectedProduct.removeReason">DELETE THIS PRODUCT PERMENANTLY</button>
      </div>
    </div>
  </div>
</div>
