import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {User} from '../_models/';
import * as moment from 'moment';


@Injectable()
export class UserService {
  constructor(public afAuth: AngularFireAuth, private db: AngularFirestore) {
  }

  create(user: User, password: string) {
    return this.afAuth.auth.createUserWithEmailAndPassword(user.email, password)
      .then((userResult: any) => {
        user.id = userResult.user.uid; // user auth id as user id so we can retrieve the user easier after login
        const usersCollection = this.db.collection<User>('users');
        user.created_at = moment().format();
        // add new user
        usersCollection.doc(user.id).set(user)
          .then(() => {
            const currentUser = this.afAuth.auth.currentUser;
            return currentUser.updateProfile({displayName: user.firstName, photoURL: null})
              .then(() => {
                return currentUser.sendEmailVerification();
              })
              .catch(
                (error) => {
                  throw error;
                }
              );
          })
          .catch(
            (error) => {
              throw error;
            }
          );
      })
      .catch(error => {
        throw error;
      });
  }

  list(): Observable<User[]> { // note: to use Observable<User[]> here we have to set all User properties as optional with ?
    return this.db.collection('users')
      .snapshotChanges().pipe( // we can not call valueChanges() here as it would not return the id so this is a workaround
        map((actions: any) => {
          return actions.map((a: any) => {
            const data = a.payload.doc.data() as User;
            data.id = a.payload.doc.id;
            return data;
          });
        }),
        catchError(this.handleError('list user', []))
      );
  }

  update(user: User) {
    const userDoc = this.db.doc('users/' + user.id);
    const newUser = {
      businessId: user.businessId || null,
      role: user.role || null,
      phone: user.phone || null,
      firstName: user.firstName || null,
      lastName: user.lastName || null,
      dateOfBirth: user.dateOfBirth || null};
    return userDoc.update(newUser);
  }
  updateField(user: User, fieldName: string, data: any): Promise<void> {
    const userDoc = this.db.doc('users/' + user.id);
    const newUser: any = {};
    newUser[fieldName] = data || null;
    return userDoc.update(newUser);
  }

  get(id: string): Observable<User> {
    return this.db.doc('users/' + id)
      .snapshotChanges().pipe(
        map((a: any) => {
          const data = a.payload.data() as User;
          if (data) {
            data.id = a.payload.id;
          }
          return data;
        }),
        catchError(this.handleError<User>(`getUser id=${id}`))
      );
  }

  forgotPassword(email: string) {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead - if we dont do this we wont see the error in red in the console
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
