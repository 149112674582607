import {
  Component, Input, Output, EventEmitter, OnInit
} from '@angular/core';

import {ProductService} from '../../../app/_services';
import {Business, Product, User} from '../../../app/_models';
import {LoaderService} from '../../../app/shared/loader/loader.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-account-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],

})
export class AccountProductsComponent implements OnInit {
  @Output() tabReturn: EventEmitter<{ products: Product[] }> = new EventEmitter<{ products: Product[] }>();
  @Input('user') user: User;
  @Input('business') business: Business = {};
  selectedProduct: Product = {};
  hasProduct = false;

  publishedForWomen: Product[] = [];
  publishedForMen: Product[] = [];
  publishedForUnisex: Product[] = [];
  notValidated: Product[] = [];
  notCompleted: Product[] = [];

  constructor(private loaderService: LoaderService, private router: Router,
              private productService: ProductService) {
  }

  ngOnInit() {
    if (this.user.businessId) {
      this.loaderService.show();
      this.productService.listForBusiness(this.user.businessId)
        .subscribe((products: Product[]) => {

          this.publishedForWomen = [];
          this.publishedForMen =  [];
          this.publishedForUnisex =  [];
          this.notValidated =  [];
          this.notCompleted = [];
          products.forEach((product: Product) => {
            if (product.validated) {
              if (product.variants[0].customerType === 'women') {
                this.publishedForWomen.push(product);
              } else if (product.variants[0].customerType === 'men') {
                this.publishedForMen.push(product);
              } else {
                this.publishedForUnisex.push(product);
              }
            } else if (product.hasAgreeToTerms) {
              this.notValidated.push(product);
            } else {
              this.notCompleted.push(product);
            }
          });
          this.hasProduct = products.length > 0;
          this.loaderService.hide();
        });
    } else {
      this.loaderService.show();
      return this.router.navigate(['/list-business']).then(() => {
        this.loaderService.hide();
      });
    }
  }

  deleteModal(product) {
    this.selectedProduct = product;
    $('#removeProductModal').modal('show');
  }

  confirmDelete() {
    this.loaderService.show();
    this.productService.remove(this.selectedProduct.id, this.selectedProduct.removeReason)
      .then(() => {
        $('#removeProductModal').modal('hide');
        this.loaderService.hide();
      });
  }
}
