<section class="my-5">
  <div class="container text-center">
    <img class="main-logo img-fluid" src="../../assets/images/logo/luxify-logo-revers.svg" alt="Luxify">
  </div>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb my-4">
        <li class="breadcrumb-item text-capitalize"><a [routerLink]="['/dashboard/listings']">{{breadcrumb.type}}</a></li>
        <li class="breadcrumb-item text-capitalize"><a [routerLink]="['/dashboard/listings']">{{breadcrumb.brand}}</a></li>
        <li class="breadcrumb-item text-capitalize active" aria-current="page">{{breadcrumb.title}}</li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-sm-4">
        <div class="image-bg" *ngIf="selectedImage" [ngStyle]="{background: 'url(' + selectedImage.src + ')'}"></div>
        <img class="img-fluid" *ngIf="!selectedImage" src="../../assets/images/temps/placeholder-image.jpg" alt="empty image">
        <ngb-carousel *ngIf="imagesGroups" [showNavigationIndicators]="false">
          <ng-template ngbSlide *ngFor="let images of imagesGroups">
            <div  class="row justify-content-center" >
              <div class="col-sm-4" *ngFor="let item of images; index as i" (click)="selectImage(item)">
                <img class="img-fluid" [src]="item.src" alt="Random slide">
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
      <div class="col-sm-8">
        <h3>{{currentProduct.brand}} {{currentProduct.model}}</h3>
        <p *ngIf="defaultVariant.orgPrice"><del>{{defaultVariant.orgPrice | currency:'USD':'symbol'}}</del></p>
        <p><span *ngIf="defaultVariant.orgPrice">your final price</span> {{defaultVariant.price | currency:'USD':'symbol'}}</p>
        <hr/>
        <p>Availability: <span>{{defaultVariant.inventory_quantity>0 ? 'In stock':'Sold out'}}</span></p>
        <p class="">Quantity :</p>
        <div class="row">
          <div class="col-sm-6 col-lg-4">
            <div class="form-group">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <button class="btn btn-outline-secondary" type="button" id="qty-minus" (click)="qtyMinus()" [disabled]="quantity<=1">-</button>
                </div>
                <label for="checkoutQty" hidden></label>
                <input type="number" class="form-control text-center" name="checkoutQty" id="checkoutQty" [(ngModel)]="quantity" (blur)="onQtyChange()">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" id="qty-plus" (click)="qtyPlus()" [disabled]="quantity >= defaultVariant.inventory_quantity">+</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-5 col-lg-3 offset-sm-1 offset-lg-5">
            <button type="button" class="btn btn-secondary btn-block" data-toggle="modal" data-target="#paymentModal" (click)="onShowModal()" [disabled]=" true || !defaultVariant.inventory_quantity || defaultVariant.inventory_quantity<=0">Buy now</button>
          </div>
        </div>
        <p class="">PRODUCT DETAILS:</p>
        <p>{{currentProduct.bodyHtml}}</p>
      </div>
    </div>
  </div>
</section>


<!--<div class="modal fade" id="paymentModal" tabindex="-1" role="dialog" aria-labelledby="paymentModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paymentModalLabel">Payment</h5>
        <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <app-payment  [quantity]="quantity" [product]="currentProduct" *ngIf="currentProduct.id && showModal"
                     (paymentResult)="getPaymentResult($event)"></app-payment>
      </div>
    </div>
  </div>
</div>-->
