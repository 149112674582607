import {
  Component,
  ComponentFactoryResolver, ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {AlertService, UserService, AuthenticationService} from './_services';
import {LoaderService} from './shared/loader/loader.service';
import {AlertComponent} from './_directives';
import {User} from './_models';

declare var $: any;
// https://www.positronx.io/angular-seo-set-dynamic-page-title-meta-tags-in-universal-app/
import {Title, Meta} from '@angular/platform-browser';
import * as bootstrap from 'bootstrap';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  model: any = {};

  show: any = {
    signIn: true,
    forgotPass: false,
    resendVerifiedEmail: false
  };

  needToValidateEmail = false;
  isLoading = false;
  newUser: any = {};
  validationEmailMode: string;

  @ViewChild('alertSignin', { read: ViewContainerRef }) alertSignin: ViewContainerRef;
  @ViewChild('alertSignup', { read: ViewContainerRef }) alertSignup: ViewContainerRef;
  @ViewChild('alertForgotPass', { read: ViewContainerRef }) alertForgotPass: ViewContainerRef;

  constructor(private router: Router,
              private userService: UserService,
              private route: ActivatedRoute,
              private authService: AuthenticationService,
              private alertService: AlertService,
              private cfr: ComponentFactoryResolver,
              private loaderService: LoaderService,
              private metaTagService: Meta,
              private titleService: Title) {
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.titleService.setTitle('');
    this.metaTagService.addTags([
      {name: 'keywords', content: 'Angular SEO Integration, Music CRUD, Angular Universal'},
      {name: 'robots', content: 'index, follow'},
      {name: 'author', content: 'Digamber Singh'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'date', content: '2019-10-31', scheme: 'YYYY-MM-DD'},
      {name: 'description', content: 'Add song template'},
      {charset: 'UTF-8'}
    ]);
  }

  showLoginTab(tab) {
    this.show.forgotPass = false;
    this.show.signIn = false;
    this.show[tab] = true;
  }

  showSignUp() {
    $('#SignInModal').modal('hide');
    $('#SignUpModal').modal('show');
  }

  showSignIn() {
    $('#SignUpModal').modal('hide');
    $('#SignInModal').modal('show');
    this.showLoginTab('signIn');
  }


  resendVerifiedEmail() {
    this.isLoading = true;
    this.loaderService.show();

    this.authService.sendEmailVerification(this.model.email).then(
      () => {
        this.showAlert('alertSignin');
        this.alertService.success('The verification email has been successful send!');

        this.show.resendVerifiedEmail = false;
        this.isLoading = false;
        this.loaderService.hide();
      },
      error => {
        this.showAlert('alertSignin');
        this.alertService.error(error.message);
        this.isLoading = false;
        this.loaderService.hide();
      });
  }

  signin() {
    this.isLoading = true;
    this.loaderService.show();
    this.authService.login(this.model.email, this.model.password).then(
      () => {
        $('#SignUpModal').modal('hide');
        $('#SignInModal').modal('hide');
        return this.router.navigate(['/account/products']).then(() => {
          this.isLoading = false;
          this.loaderService.hide();
        });
      },
      error => {
        this.showAlert('alertSignin');
        if (error.code === 'auth/email-not-verified') {
          this.show.resendVerifiedEmail = true;
        }
        this.alertService.error(error.message);
        this.isLoading = false;
        this.loaderService.hide();
      });
  }

  loginGoogleUser() {
    this.isLoading = true;
    this.loaderService.show();
    this.authService.loginGoogleUser().then(
      user => {
        $('#SignUpModal').modal('hide');
        $('#SignInModal').modal('hide');
        return this.router.navigate(['/account/products']).then(() => {
          this.isLoading = false;
          this.loaderService.hide();
        });
      },
      error => {
        this.showAlert('alertSignup');
        this.alertService.error(error);
        this.isLoading = false;
        this.loaderService.hide();
      });
  }

  forgotPass() {
    this.isLoading = true;
    this.userService.forgotPassword(this.model.email).then(
      () => {
        this.isLoading = false;
        this.model = {};
        this.needToValidateEmail = true;
      },
      error => {
        this.showAlert('alertForgotPass');
        this.alertService.error(error);
        this.isLoading = false;
      });
  }


  signup() {
    this.isLoading = true;
    this.loaderService.show();
    const user: User = Object.assign({}, this.newUser);
    delete user.password;
    this.userService.create(user, this.newUser.password).then(
      () => {
        /* this.showAlert('alertSignup');
         this.alertService.success(
           'Thank you. To complete your registration please check your email.',
           true);*/
        this.needToValidateEmail = true;
        this.isLoading = false;
        this.loaderService.hide();
      },
      error => {
        this.showAlert('alertSignup');
        this.alertService.error(error);
        this.isLoading = false;
        this.loaderService.hide();
      });
  }


  showAlert(target) {
    this[target].clear();
    const factory = this.cfr.resolveComponentFactory(AlertComponent);
    const ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }
}
