<section class="bg-gray-light">
  <div class="container pt-5 py-md-5">
    <form class="" (ngSubmit)="updateProduct()" #updateProductCharacteristicsForm="ngForm">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="form-group">
            <label class="col-form-label" for="bodyHtml">PRODUCT DESCRIPTION<span class="required">*</span></label>
            <p class="small font-italic">In this section, describe your product as objective and preciesly as possible. Mention the small
              defaults, any possible retouches that needs to be made, the absence of the label or if any scratches,
              etc.. </p>
            <textarea type="text" rows="5" class="form-control form-control-lg" id="bodyHtml"
                      [(ngModel)]="product.bodyHtml" name="bodyHtml" (change)="onChange()" required></textarea>
          </div>

          <div class="form-group">
            <label class="col-form-label">VINTAGE </label>
            <p>If the product is more than 15 years</p>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" name="hasAgreeToTerms"
                     [(ngModel)]="product.isVintage" (change)="onChange()" id="isVintage">
              <label class="form-check-label" for="isVintage">YES, VINTAGE</label>
            </div>
          </div>
          <hr/>
          <div class="form-group">
            <label class="col-form-label">MEASUREMENTS (Optional)<a data-toggle="modal" data-target="#helpModal"><i class="fas fa-question primary-color pl-2"></i></a></label>
            <p>If the product is more than 15 years</p>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group row">
                  <label class="col-form-label col-4 text-uppercase" for="length">length</label>
                  <label for="lengthUnit" hidden>length Unit</label>
                  <div class="col-4 pl-0">
                    <input type="number" class="form-control" id="length" [(ngModel)]="variant.length"
                           (change)="onChange()" name="length">
                  </div>
                  <div class="col-4 pl-0">
                    <select class="form-control form-control" id="lengthUnit" [(ngModel)]="variant.length_unit"
                            (ngModelChange)="onChange()" name="lengthUnit" [required]="variant.length"
                            [disabled]="!variant.length">
                      <option [value]="null">Unit</option>
                      <option value="cm">Cm</option>
                      <option value="inch">inch</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group row">
                  <label class="col-form-label col-4" for="width">WIDTH</label>
                  <label  hidden for="widthUnit">WIDTH Unite</label>
                  <div class="col-4 pl-0">
                    <input type="number" class="form-control" id="width" [(ngModel)]="variant.width"
                           (change)="onChange()" name="width">
                  </div>
                  <div class="col-4 pl-0">
                    <select class="form-control form-control" id="widthUnit" [(ngModel)]="variant.width_unit"
                            (ngModelChange)="onChange()" name="widthUnit" [required]="variant.width"
                            [disabled]="!variant.width">
                      <option [value]="null">Unit</option>
                      <option value="cm">Cm</option>
                      <option value="inch">inch</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group row">
                  <label class="col-form-label col-4" for="height">HEIGHT</label>
                  <label for="heightUnit" hidden>HEIGHT Unit</label>
                  <div class="col-4 pl-0">
                    <input type="number" class="form-control" id="height" [(ngModel)]="variant.height"
                           (change)="onChange()" name="height">
                  </div>
                  <div class="col-4 pl-0">
                    <select class="form-control form-control" [(ngModel)]="variant.height_unit"
                            (ngModelChange)="onChange()" name="heightUnit" id="heightUnit" [required]="variant.height"
                            [disabled]="!variant.height">
                      <option [value]="null">Unit</option>
                      <option value="cm">Cm</option>
                      <option value="inch">inch</option>
                    </select>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <hr/>
          <div class="form-group">
            <label class="col-form-label">ORIGINS (Optional)</label>
            <p>This information will not be visible online</p>
            <div class="">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" (change)="onChange()" [(ngModel)]="variant.productOrigin"
                       name="productOrigin" id="productOriginStore" value="store">
                <label class="form-check-label" for="productOriginStore">FROM A STORE</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" (change)="onChange()" [(ngModel)]="variant.productOrigin"
                       name="productOrigin" id="productOriginPrivate" value="private-sales">
                <label class="form-check-label" for="productOriginPrivate">PRIVATE SALES</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" (change)="onChange()" [(ngModel)]="variant.productOrigin"
                       name="productOrigin" id="productOriginLuxify" value="luxify">
                <label class="form-check-label" for="productOriginLuxify">LUXIFY</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" (change)="onChange()" [(ngModel)]="variant.productOrigin"
                       name="productOrigin" id="productOriginOther" value="other">
                <label class="form-check-label" for="productOriginOther">OTHER (GIFT, ETC..)</label>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group ">
                  <label class="col-form-label" for="pricePurchased">COST AT PURCHASE</label>
                  <div class="input-group">
                    <input type="number" class="form-control" (change)="onChange()" [(ngModel)]="variant.pricePurchased"
                           name="pricePurchased" id="pricePurchased">
                    <div class="input-group-append">
                      <span class="input-group-text input-group-text-primary">$AUD</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group ">
                  <label class="col-form-label" for="yearPurchased">YEAR PURCHASED</label>
                  <select class="form-control" id="yearPurchased" [(ngModel)]="variant.yearPurchased" (ngModelChange)="onChange()"
                          name="yearPurchased">
                    <option *ngFor="let item of years" [ngValue]="item">{{item}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="form-group">
            <label class="col-form-label" for="serialNumber">SERIAL NUMBER<span class="required">*</span></label>
            <p>This information will not be visible online</p>
            <input type="text" class="form-control form-control-lg" id="serialNumber" [(ngModel)]="variant.serialNumber"
                   (change)="onChange()" name="serialNumber" required>
          </div>
          <hr/>
          <div class="form-group">
            <label class="col-form-label mb-5">INVOICE (Optional)</label>
            <p>This information will not be visible online</p>
            <app-file-upload *ngIf=" fileUploadFolder" [currentfileurl]="variant.invoice ? variant.invoice.src :null"
                         [folder]="fileUploadFolder" [hide-preview]="false" [view-height]="200" [max-size]="5242880"
                         [media-type]="'invoice'"
                         [extensions]="['jpg','jpeg','png']" (fileDeleted)="deleteIdFilePath()"
                         (filepath)="getIdFilePath($event)"
                         id="invoice"></app-file-upload>
            <p class="text-left form-text text-muted small">The file must be JPG, JPEG or PNG and less than 5MB.</p>
          </div>
          <hr/>
          <div class="form-group">
            <label class="col-form-label">PACKAGING (Optional)</label>
            <p>Please confirm which item is included with your product</p>
            <div class="">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" (change)="onChange()"
                       [(ngModel)]="variant.hasOriginalBox" name="hasOriginalBox" id="hasOriginalBox">
                <label class="form-check-label" for="hasOriginalBox">ORIGINAL BOX</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" (change)="onChange()"
                       [(ngModel)]="variant.hasAuthenticityCert" name="hasAuthenticityCert" id="hasAuthenticityCert">
                <label class="form-check-label" for="hasAuthenticityCert">AUTHENTICITY CERTIFICATE</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" (change)="onChange()" [(ngModel)]="variant.hasDustbag"
                       name="hasDustbagv" id="hasDustbag">
                <label class="form-check-label" for="hasDustbag">DUSTBAG</label>
              </div>

            </div>
          </div>
          <hr/>
          <div class="row mt-5 mb-5">
            <div class="col-6 text-left">
              <button type="button" class="btn btn-outline-primary" (click)="goBack()">GO BACK</button>
            </div>
            <div class="col-6 text-right">
              <button type="submit" class="btn btn-primary" [disabled]="!updateProductCharacteristicsForm.form.valid">
                CONFIRM
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>


<div class="modal fade" id="helpModal" tabindex="-1" role="dialog" aria-labelledby="helpModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg modal-container" role="document">
    <div class="modal-content">
      <div class="modal-header pb-0">
        <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body pt-0">
        <h5 class="mb-0">MEASUREMENTS</h5>
        <p class="small font-weight-light">Enter the measurements of your product either in cm or in inches.</p>
        <h5 class="mb-0 mt-4">Length (Optional)</h5>
        <p class="small font-weight-light">On the side of the bag, take the widest part from one seam to the other.</p>
        <h5 class="mb-0 mt-4">Width</h5>
        <p class="small font-weight-light">With the bag closed and on a flat surface, measure the widest part.</p>
        <h5 class="mb-0 mt-4">Height</h5>
        <p class="small font-weight-light">With the bag closed and on a flat surface, measure the highest part, excluding the handles.</p>
      </div>
    </div>
  </div>
</div>
