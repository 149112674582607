<app-create-business [business]="business"  [user]="user" *ngIf="show.create"></app-create-business>


<div [hidden]="show.create">
  <header class="d-flex">
    <div class="container text-center mt-auto">
      <!-- Content here -->
      <div class="row row-footer align-items-center">
        <div class="col-12 my-5 my-sm-0 ">
          <div *ngIf="show.details">
            <h1>Step 1</h1>
            <p class="large" >Your business details</p>
          </div>
          <div *ngIf="show.profile">
            <h1>Step 2</h1>
            <p class="large">Your business profile</p>
          </div>
          <div *ngIf="show.representative">
            <h1>Step 3</h1>
            <p class="large">Your business representative details</p>
          </div>
          <div *ngIf="show.shipping">
            <h1>Step 4</h1>
            <p class="large">Your business shipping details</p>
          </div>
          <div *ngIf="show.bankDetails">
            <h1>Step 5</h1>
            <p class="large">Get paid</p>
          </div>
          <div *ngIf="show.review">
            <h1>Step 6</h1>
            <p class="large" *ngIf="show.review">Review and confirmation</p>
          </div>
          <div *ngIf="show.congratulation">
            <h1 class="mb-7 pb-6">Completed</h1>
            <p class="large" *ngIf="show.review">Review and confirmation</p>
          </div>

          <div class="text-center pt-6 steps-view mb-4" *ngIf="!show.congratulation">
            <i class="fas fa-circle" [ngClass]="{'active': show.details}"></i>
            <i class="fas fa-circle" [ngClass]="{'active': show.profile}"></i>
            <i class="fas fa-circle" [ngClass]="{'active': show.representative}"></i>
            <i class="fas fa-circle" [ngClass]="{'active': show.shipping}"></i>
            <i class="fas fa-circle" [ngClass]="{'active': show.bankDetails}"></i>
            <i class="fas fa-circle" [ngClass]="{'active': show.review}"></i>
          </div>
        </div>
      </div>
    </div>
  </header>
  <app-list-business-details *ngIf="show.details" (stepReturn)="stepReturn($event)" [business]="business" [user]="user" [countries]="countries"></app-list-business-details>
  <app-list-business-profile *ngIf="show.profile" (stepReturn)="stepReturn($event)" [business]="business" [user]="user" [fileUploadFolder]="fileUploadFolder"></app-list-business-profile>
  <app-list-business-representative *ngIf="show.representative" (stepReturn)="stepReturn($event)" [fileUploadFolder]="fileUploadFolder" [user]="user" [business]="business"></app-list-business-representative>
  <app-list-business-shipping *ngIf="show.shipping" (stepReturn)="stepReturn($event)" [customAccount]="customAccount" [user]="user" [business]="business"></app-list-business-shipping>
  <app-list-business-bank-details *ngIf="show.bankDetails"  (stepReturn)="stepReturn($event)" [business]="business" [user]="user" [countries]="countries" [currencies]="currencies" [customAccount]="customAccount"></app-list-business-bank-details>
  <app-list-business-review *ngIf="show.review" (stepReturn)="stepReturn($event)" [customAccount]="customAccount" [user]="user" [business]="business"></app-list-business-review>
  <section class="" *ngIf="show.congratulation">
    <div class="container pt-5 py-md-5">
      <div class="row justify-content-center">
        <div class="col-8 text-center">
          <h2 class="my-3 ">Congratulations!<br/>
            Your seller account is ready.</h2>
          <p class="text-center mb-5">
            You can now start your product listing
          </p>
          <a [routerLink]="['/list-product']" class="btn btn-outline-primary">START LISTING</a>

        </div>
      </div>
    </div>
  </section>

</div>
