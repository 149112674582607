import {
  Component, ViewContainerRef, ViewChild, ComponentFactoryResolver, OnInit, Input, Output, EventEmitter
} from '@angular/core';

import {AlertService, AppService, BusinessService, PaymentService} from '../../../app/_services';
import {BusinessPayout, Business, Country, User} from '../../../app/_models';
import {AlertComponent} from '../../../app/_directives';
import {LoaderService} from '../../../app/shared/loader/loader.service';

@Component({
  selector: 'app-list-business-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss'],

})
export class ListBusinessPayoutComponent implements OnInit {
  selectedCurrency: any;
  isLoading: boolean;
  dateOfBirth: any = null;
  error: string;

  identityProof: string;
  needToUpdate = false;
  canSubmitCustomAccount = true;

  @ViewChild('alertMessage', { read: ViewContainerRef }) alertMessage: ViewContainerRef;
  @Input('countries') countries: Country[] = [];
  @Input('currencies') currencies: any[] = [];
  @Input('business') business: Business = {};
  @Input('user') user: User = {};
  @Input('customAccount') customAccount: BusinessPayout = {};

  @Output() stepReturn: EventEmitter<{ step: string, business: Business, customAccount: BusinessPayout}>
    = new EventEmitter<{ step: string, business: Business, customAccount: BusinessPayout }>();
  constructor(private loaderService: LoaderService, private cfr: ComponentFactoryResolver,
              private alertService: AlertService,
              private businessService: BusinessService,
              private appService: AppService,
              private paymentService: PaymentService) {}

  ngOnInit() {
    if (!this.business || !this.business.name || !this.business.address
      || !this.business.address.countryCode || !this.business.representativeIdentityProof
      || (this.business.businessType === 'company' && (!this.business.taxId || !this.business.website))) {
      this.canSubmitCustomAccount = false;
    }
    this.currencies = this.appService.currenciesList();
    this.currencies.unshift({name: '-- Select a currency --', code: null});

    if (this.business && this.business.id && this.business.address) {
      this.identityProof = this.business.representativeIdentityProof;
      this.dateOfBirth = this.business.representativeDateOfBirth;
      this.customAccount = {...this.customAccount, ...{
        type: this.business.businessType,
        first_name: this.business.representativeFirstName,
        last_name: this.business.representativeLastName,
        dateOfBirth: this.business.representativeDateOfBirth,
        identityProof: this.business.representativeIdentityProof,
        address: {
          countryCode: this.business.address.countryCode,
          country: this.business.address.country,
          line1: this.business.address.streetAddress,
          city: this.business.address.city,
          state: this.business.address.state,
          postal_code: this.business.address.postcode
        }
      }};
    }

    if (this.customAccount && this.currencies.length > 0 && this.customAccount.currency) {
      this.selectedCurrency = this.currencies.find(x => x.code === this.customAccount.currency.toUpperCase());
    } else {
      this.selectedCurrency = this.currencies[0];
    }
    if (!this.customAccount || !this.customAccount.bankAccountName) {
      this.customAccount.bankAccountName = this.business.representativeFirstName + ' ' + this.business.representativeLastName;
    }
  }

  selectCurrency(currency) {
    this.selectedCurrency = currency;
    this.customAccount.currency = currency.code;
    this.needToUpdate = true;
  }

  updatePaymentDetails() {
    if (this.needToUpdate && this.canSubmitCustomAccount) {
      this.loaderService.show();
      this.paymentService.onSubmitCustomAccount(this.customAccount, this.business)
        .then((res: any) => {
          if (res.success) {
            this.showAlert('alertMessage');
            this.alertService.success('Payment details updated!');
            this.businessService.updateField(this.business, 'hasPaymentDetails', true)
              .then(() => {
                return this.stepReturn.emit({step: 'review', business : null, customAccount: this});
              })
              .then(() => {
                this.isLoading = false;
                this.loaderService.hide();
              });
          } else {
            this.showAlert('alertMessage');
            this.alertService.error(res.message);
            this.isLoading = false;
            this.loaderService.hide();
          }
        });
    } else {
      return this.stepReturn.emit({step: 'review', business : null, customAccount: null});
    }
  }

  showAlert(target) {
    this[target].clear();
    const factory = this.cfr.resolveComponentFactory(AlertComponent);
    const ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

  goBackTo(step) {
    return this.stepReturn.emit({step, business : null, customAccount: null});
  }

  onChange() {
    this.needToUpdate = true;
  }
}
