<section class="bg-gray-light">
  <div class="container pt-5 py-md-5">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div *ngIf="user">
          <h6 class="mb-5">IDENTIFICATION</h6>
          <div class="summary-view" *ngIf="!edit.identification">
            <p>Email: <span class="gray-color">{{user.email}}</span></p>
            <p>Password: <span class="gray-color">******</span></p>
            <div class="text-right">
              <button type="button" (click)="editBlock('identification')" class="btn btn-link primary-color">MODIFY
              </button>
            </div>
          </div>
          <div class="edit-view" *ngIf="edit.identification">
            <div class="form-group row">
              <label class="col-form-label col-sm-3">Email:</label>
              <div class="col-sm-9">
                <p class="gray-color mb-0">{{user.email}}</p>
                <p class="primary-color small font-italic">(email address can only be changed by contacting our Customer
                  Service)</p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-sm-3">Password:</label>
              <div class="col-sm-9">
                <p class=" small"><span class="primary-color" (click)="resetPassword()" style="cursor: pointer;"><u>Reset my password</u></span>
                  <span class="gray-color"> by sending me a link to reset it.</span></p>
              </div>
            </div>
            <div class="text-right">
              <button type="button" class="btn btn-primary" (click)="editBlock(null)">CONFIRM</button>
            </div>
          </div>
          <ng-template #alertResetPwdMessage></ng-template>
          <hr/>
        </div>
        <div>
          <h6 class="mb-5">MY PROFILE PHOTO</h6>
          <div class="summary-view" *ngIf="!edit.profileImage && user.profileImage">
            <div class="row">
              <div class="img-preview d-flex col-sm-4">
                <img *ngIf="user.profileImage" [src]="user.profileImage" alt="your uploaded image"
                     class="img-fluid m-auto d-block">
              </div>
            </div>
            <div class="text-right">
              <button type="button" (click)="editBlock('profileImage')" class="btn btn-link primary-color">MODIFY
              </button>
            </div>
          </div>
          <div class="edit-view " *ngIf="edit.profileImage || !user.profileImage">
            <div class="form-group row">
              <div class="col-sm-4">
                <app-file-upload *ngIf="user.id && fileUploadFolder"
                                 [currentfileurl]="user.profileImage" [max-size]="5242880"
                                 [folder]="fileUploadFolder" [hide-preview]="false" [view-height]="200"
                                 [extensions]="['png','jpg','jpeg']" (fileDeleted)="deleteIdFilePath()"
                                 (filepath)="getIdFilePath($event)"
                                 id="profileImage"></app-file-upload>
                <p class="text-left form-text text-muted small">The file must be JPG, JPEG or PNG and less than 5MB.</p>
              </div>
            </div>
          </div>
          <hr/>
        </div>
        <div>
          <h6 class="mb-5">MY PROFILE DETAILS</h6>
          <div class="summary-view" *ngIf="!edit.profileDetails">
            <p>Name: <span class="gray-color text-capitalize">{{user.firstName}}</span></p>
            <p>Last name: <span class="gray-color text-capitalize">{{user.lastName}}</span></p>
            <p>Date of birth: <span class="gray-color">{{user.dateOfBirth | timeToLabel :'DD/MM/YYYY'}}</span></p>
            <p>Phone number: <span class="gray-color">{{user.phone}}</span></p>
            <div class="text-right">
              <button type="button" (click)="editBlock('profileDetails')" class="btn btn-link primary-color">MODIFY
              </button>
            </div>
          </div>
          <div class="edit-view" *ngIf="edit.profileDetails">
            <form class="" (ngSubmit)="updateAccount()" #userDetailsForm="ngForm">
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="firstNameUser">Name:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="firstNameUser"
                         placeholder="Enter first name here" [(ngModel)]="user.firstName" (change)="onChange('user')"
                         name="firstNameUser" required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="lastNameUser">Last name:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="lastNameUser" placeholder="Enter last name here"
                         [(ngModel)]="user.lastName" (change)="onChange('user')" name="lastNameUser" required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="userDateOfBirth">Date of birth:</label>
                <div class="col-sm-9">
                  <input class="form-control" placeholder="DD/MM/YYYY" id="userDateOfBirth"
                         name="dp" [(ngModel)]="user.dateOfBirth" ngbDatepicker #d="ngbDatepicker"
                         [maxDate]="datePickerOption.maxDate" [minDate]="datePickerOption.minDate"  (change)="onChange('user')"
                         (click)="d.toggle()" readonly required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="UserPhone">Phone number:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="UserPhone" placeholder="Enter phone here"
                         [(ngModel)]="user.phone" (change)="onChange('user')" name="UserPhone">
                </div>
              </div>
              <div class="text-right">
                <button type="submit" class="btn btn-primary" [disabled]="!userDetailsForm.form.valid">CONFIRM</button>
              </div>
            </form>
          </div>
          <hr/>
        </div>
        <div>
          <h6 class="mb-5">YOUR BUSINESS REPRESENTATIVE</h6>
          <div class="summary-view" *ngIf="!edit.representative">
            <p>Name: <span class="gray-color text-capitalize">{{business.representativeFirstName}}</span></p>
            <p>Last name: <span class="gray-color text-capitalize">{{business.representativeLastName}}</span></p>
            <p>Date of birth: <span
              class="gray-color">{{business.representativeDateOfBirth | timeToLabel :'DD/MM/YYYY'}}</span></p>
            <p>Phone number: <span class="gray-color">{{business.representativePhone}}</span></p>
            <p>Email: <span class="gray-color">{{business.representativeEmail}}</span></p>
            <div class="text-right">
              <button type="button" (click)="editBlock('representative')" class="btn btn-link primary-color">MODIFY
              </button>
            </div>
          </div>
          <div class="edit-view" *ngIf="edit.representative">
            <form class="" (ngSubmit)="updateAccount()" #representativeForm="ngForm">
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="firstName">Name:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="firstName" placeholder="Enter first name here"
                         [(ngModel)]="business.representativeFirstName" (change)="onChange('business')" name="firstName"
                         required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="lastName">Last name:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="lastName" placeholder="Enter last name here"
                         [(ngModel)]="business.representativeLastName" (change)="onChange('business')" name="lastName"
                         required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="representativeDateOfBirth">Date of birth:</label>
                <div class="col-sm-9">
                  <input class="form-control" placeholder="DD/MM/YYYY" id="representativeDateOfBirth"
                         name="dp" [(ngModel)]="business.representativeDateOfBirth" ngbDatepicker #d="ngbDatepicker"
                         [maxDate]="datePickerOption.maxDate" [minDate]="datePickerOption.minDate"  (change)="onChange('business')"
                         (click)="d.toggle()" readonly required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="representativePhone">Phone number:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="representativePhone"
                         placeholder="Enter phone here" [(ngModel)]="business.representativePhone"
                         (change)="onChange('business')" name="representativePhone">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="representativeEmail">Email:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="representativeEmail"
                         placeholder="Enter email here" [(ngModel)]="business.representativeEmail"
                         (change)="onChange('business')" name="representativeEmail">
                </div>
              </div>
              <div class="text-right">
                <button type="submit" class="btn btn-primary" [disabled]="!representativeForm.form.valid">CONFIRM
                </button>
              </div>
            </form>
          </div>
          <hr/>
        </div>
        <div *ngIf="business && business.address">
          <h6 class="mb-5">MY BUSINESS DETAILS</h6>
          <div class="summary-view" *ngIf="!edit.businessDetails">
            <p>Business name: <span class="gray-color text-capitalize">{{business.name}}</span></p>
            <p>Business Type: <span class="gray-color text-capitalize">{{business.businessType}}</span></p>
            <p>Registration No: <span class="gray-color">{{business.taxId}}</span></p>
            <p>Address: <span class="gray-color text-capitalize">{{business.address.streetAddress}}</span></p>
            <p>City: <span class="gray-color text-capitalize">{{business.address.city}}</span></p>
            <p>Postcode: <span class="gray-color">{{business.address.postcode}}</span></p>
            <p>State: <span class="gray-color text-capitalize">{{business.address.state}}</span></p>
            <p>Country: <span class="gray-color text-capitalize">{{business.address.country}}</span></p>
            <div class="text-right">
              <button type="button" (click)="editBlock('businessDetails')" class="btn btn-link primary-color">MODIFY
              </button>
            </div>
          </div>
          <div class="edit-view" *ngIf="edit.businessDetails">
            <form class="" (ngSubmit)="updateAccount()" #businessDetailsForm="ngForm">
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="businessName">Business name:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="businessName"
                         placeholder="Enter your business name" [(ngModel)]="business.name"
                         (change)="onChange('business')" name="businessName" required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="businessType">Business Type:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="businessType" [value]="business.businessType"
                         name="businessType" required readonly>
                  <p class="small primary-color font-italic">(Business Type can only be changed by contacting our Customer
                    Service)</p>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="taxId">Registration No:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="taxId"
                         placeholder="Enter your registration number" [(ngModel)]="business.taxId"
                         (change)="onChange('business')" name="taxId">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="streetAddress">Address:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="streetAddress"
                         placeholder="Enter your street address" [(ngModel)]="business.address.streetAddress"
                         (change)="onChange('business')" name="streetAddress" required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="city">City:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="city" placeholder="Enter your city"
                         [(ngModel)]="business.address.city" (change)="onChange('business')" name="city" required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="postcode">Postcode:</label>
                <div class="col-sm-9">
                  <input type="number" class="form-control" id="postcode" placeholder="Enter your postcode"
                         [(ngModel)]="business.address.postcode" (change)="onChange('business')" name="postcode"
                         required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="state">state:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="state" placeholder="Enter your state"
                         [(ngModel)]="business.address.state" (change)="onChange('business')" name="state" required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="country">Country:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="country" [value]="business.address.country" name="country"
                         required readonly>
                  <p class="small primary-color font-italic">(Country can only be changed by contacting our Customer Service)</p>
                </div>
              </div>
              <div class="text-right">
                <button type="submit" class="btn btn-primary" [disabled]="!businessDetailsForm.form.valid">CONFIRM
                </button>
              </div>
            </form>
          </div>
          <hr/>
        </div>
        <div>
          <h6 class="mb-5">MY BUSINESS PROFILE</h6>
          <div class="summary-view" *ngIf="!edit.businessProfile">
            <p>Profile description:</p>
            <p class="pl-3 gray-color"><span>{{business.description}}</span></p>
            <p>Facebook page: <span class="gray-color">{{business.facebookPage}}</span></p>
            <p>Instagram page: <span class="gray-color">{{business.instagramPage}}</span></p>
            <p>Linkedin page: <span class="gray-color">{{business.linkedinPage}}</span></p>
            <div class="text-right">
              <button type="button" (click)="editBlock('businessProfile')" class="btn btn-link primary-color">MODIFY
              </button>
            </div>
          </div>
          <div class="edit-view" *ngIf="edit.businessProfile">
            <form class="" (ngSubmit)="updateAccount()" #businessProfileForm="ngForm">
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="businessDescription">Profile description:</label>
                <div class="col-sm-9">
                   <textarea type="text" rows="5" class="form-control" id="businessDescription"
                             placeholder="Enter your business description" [(ngModel)]="business.description"
                             name="businessDescription" (change)="onChange('business')" required></textarea>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="facebookPage">Facebook page:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="facebookPage"
                         placeholder="Enter your Facebook page URL" [(ngModel)]="business.facebookPage"
                         (change)="onChange('business')" name="facebookPage">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="instagramPage">Instagram page:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="instagramPage"
                         placeholder="Enter your instagram profile URL" [(ngModel)]="business.instagramPage"
                         (change)="onChange('business')" name="instagramPage">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-3" for="linkedinPage">Linkedin page:</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="linkedinPage"
                         placeholder="Enter your Linkedin profile URL" [(ngModel)]="business.linkedinPage"
                         (change)="onChange('business')" name="linkedinPage">
                </div>
              </div>
              <div class="text-right">
                <button type="submit" class="btn btn-primary" [disabled]="!businessProfileForm.form.valid">CONFIRM
                </button>
              </div>
            </form>
          </div>
          <hr/>
        </div>
        <div>
          <h6 class="mb-5">MY BUSINESS SHIPPING</h6>
          <div class="summary-view" *ngIf="!edit.businessShipping">
            <p>Shipping rates:</p>
            <p *ngFor="let rate of business.shippingRates; let i=index">From: <span class="gray-color">{{rate.min}}</span> <span *ngIf="rate.max">To: <span class="gray-color">{{rate.max}}</span></span> At : {{rate.price}}{{bankDetails.currency || '$'}}</p>
            <p *ngIf="!business.shippingRates || business.shippingRates.length<1">All your product will have a Free shipping.</p>
            <div class="text-right">
              <button type="button" (click)="editBlock('businessShipping')" class="btn btn-link primary-color">MODIFY
              </button>
            </div>
          </div>
          <div class="edit-view" *ngIf="edit.businessShipping">
            <form class="" (ngSubmit)="updateAccount()" #businessShippingForm="ngForm">
              <div class="alert alert-primary" role="alert" *ngIf="!business.shippingRates || business.shippingRates.length<1">
                All your product will have a Free shipping.
              </div>
              <div class="row align-items-end" *ngFor="let rate of business.shippingRates; let i=index">
                <div class="col">
                  <div class="form-group">
                    <label class="col-form-label" [for]="'min-'+i">Minimum<span class="required">*</span></label>
                    <input type="text" class="form-control form-control-lg" [id]="'min-'+i" placeholder="From" [(ngModel)]="rate.min" (change)="onChange('business')" [name]="'min-' + i" required>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="col-form-label" [for]="'max-'+i">Maximum</label>
                    <input type="text" class="form-control form-control-lg" [id]="'max-'+i" placeholder="To" [(ngModel)]="rate.max" (change)="onChange('business')" [name]="'max-' + i">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="col-form-label" [for]="'price-'+i">Amount</label>
                    <div class="input-group">
                      <input type="text" class="form-control form-control-lg" [id]="'price-'+i" placeholder="$" [(ngModel)]="rate.price" (change)="onChange('business')" [name]="'price-' + i" required>
                      <div class="input-group-append">
                        <span class="input-group-text input-group-text-primary">{{bankDetails.currency || '$'}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <button type="button" class="btn btn-outline-danger" (click)="removeRate(i)"><i class="fas fa-trash"></i></button>
                  </div>
                </div>
              </div>
              <div class="text-right">
                <button type="button" (click)="addRate()" class="btn btn-outline-primary mr-2" >ADD RATE</button>
                <button type="submit" class="btn btn-primary" [disabled]="!businessShippingForm.form.valid">CONFIRM</button>
              </div>
            </form>
          </div>
          <hr/>
        </div>
        <div *ngIf="bankDetails">
          <h6 class="mb-5">MY BANK DETAILS</h6>
          <div class="summary-view" *ngIf="!edit.bankDetails">
            <p>Currency: <span class="gray-color text-uppercase">{{bankDetails.currency}}</span></p>
            <p>Account owner name: <span class="gray-color text-capitalize">{{bankDetails.bankAccountName}}</span></p>
            <p>Account number: <span class="gray-color">{{bankDetails.last4}}</span></p>
            <p>Routing number: <span class="gray-color">{{bankDetails.bankNumber}}</span></p>
            <p>Bank name: <span class="gray-color text-capitalize">{{bankDetails.bankName}}</span></p>
            <div class="text-right">
              <button type="button" (click)="editBlock('bankDetails')" class="btn btn-link primary-color">MODIFY
              </button>
            </div>
          </div>
          <div class="edit-view" *ngIf="edit.bankDetails">
            <div class="alert alert-warning my-3" role="alert" *ngIf="!canSubmitCustomAccount">
              Your business profile is not fully completed. To be able to add your bank details you need to complete all <a [routerLink]="['/list-business/step-1']">required fields</a> plus : <br/>
              <span *ngIf="business.businessType === 'company' && !business.taxId">
                - <a [routerLink]="['/list-business/step-1']">your business registration number</a><br/>
              </span>
                  <span *ngIf="business.businessType === 'company' && !business.website">
                - <a [routerLink]="['/list-business/step-1']">your business website</a><br/>
              </span>
                  <span *ngIf="!business.representativeIdentityProof">
              - <a [routerLink]="['/list-business/step-3']">your government-issued ID</a><br/>
              </span>
            </div>
            <form class="" (ngSubmit)="updateAccount()" #payoutForm="ngForm">
              <div class="form-group row">
                <label class="col-form-label col-sm-4" for="currency">Currency</label>
                <div class="col-sm-8">
                  <select class="form-control" [(ngModel)]="selectedCurrency" id="currency"
                          (ngModelChange)="selectCurrency($event)" name="country" required>
                    <option *ngFor="let item of currencies" [ngValue]="item">{{item.name}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-4" for="bankAccountName">Account owner name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" id="bankAccountName"
                         placeholder="Enter your account owner name" [(ngModel)]="bankDetails.bankAccountName"
                         (change)="onChange('payout')" name="bankAccountName" required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-4" for="bankAccountNumber">Account number</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" id="bankAccountNumber"
                         placeholder="{{bankDetails.last4 ? bankDetails.last4 : 'Enter your account number'}}"
                         [(ngModel)]="bankDetails.bankAccountNumber" (change)="onChange('payout')"
                         name="bankAccountNumber" required="">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-4 " for="bankNumber">Routing number</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" id="bankNumber"
                         placeholder="Enter your routing number" [(ngModel)]="bankDetails.bankNumber"
                         (change)="onChange('payout')" name="bankNumber" required>
                  <p class="small text-right mb-0">*Example: 110000</p>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-4" for="bankName">Bank name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" id="bankName" placeholder="Enter your bank name"
                         [(ngModel)]="bankDetails.bankName" (change)="onChange('payout')" name="bankName" required>
                </div>
              </div>
              <div class="text-right mb-2">
                <button type="submit" class="btn btn-primary" *ngIf="canSubmitCustomAccount" [disabled]="!payoutForm.form.valid">CONFIRM</button>
                <button type="button" class="btn btn-primary px-4" *ngIf="!canSubmitCustomAccount" (click)="editBlock(null)">CLOSE</button>
              </div>
            </form>
          </div>
          <ng-template #alertPayoutMessage></ng-template>
        </div>
      </div>
    </div>
  </div>
</section>

