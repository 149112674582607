import {
  Component,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver, OnInit, Input, Output, EventEmitter
} from '@angular/core';

import {AlertService, BusinessService} from '../../../app/_services';
import {BusinessPayout, Business, User} from '../../../app/_models';
import {AlertComponent} from '../../../app/_directives';
import {LoaderService} from '../../../app/shared/loader/loader.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-list-business-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ListBusinessReviewComponent implements OnInit {

  @Input('business') business: Business = {};
  @Input('user') user: User = {};
  @Input('customAccount') customAccount: BusinessPayout = {};
  @Output() stepReturn: EventEmitter<{ step: string, business: Business, customAccount: BusinessPayout}>
    = new EventEmitter<{ step: string, business: Business, customAccount: BusinessPayout }>();

  isLoading = false;
  needToUpdate = false;
  error: {
    businessDetails: boolean
    businessProfile: boolean
    representativeProfile: boolean
    customAccount: boolean
  };
  @ViewChild('alertMessage', { read: ViewContainerRef }) alertMessage: ViewContainerRef;

  constructor(private loaderService: LoaderService, private cfr: ComponentFactoryResolver,
              private alertService: AlertService,
              private router: Router,
              private businessService: BusinessService) {
    this.error = {
      businessDetails: false,
      businessProfile: false,
      representativeProfile: false,
      customAccount: false,
    };
  }

  ngOnInit() {}

  goBackTo(step) {
    return this.stepReturn.emit({step, business : null, customAccount: null});
  }

  completeBusiness() {
    this.error = {
      businessDetails: false,
      businessProfile: false,
      representativeProfile: false,
      customAccount: false,
    };
    const listError = '';
    const cptError = 0;
    if (this.error.representativeProfile || this.error.businessDetails || this.error.customAccount || this.error.businessProfile) {
      this.showAlert('alertMessage');
      this.alertService
        .error(`The ${cptError > 1 ? 'steps' : 'step'} : ${listError} ${cptError > 1 ? 'are' : 'is'} incomplete`);
      return 0;
    } else {
      if (this.needToUpdate) {
        this.isLoading = true;
        this.loaderService.show();
        this.businessService.update(this.business, this.user).then(() => {
          return this.stepReturn.emit({step: 'congratulation', business : this.business, customAccount: null});
        }).then(() => {
          this.isLoading = false;
          this.loaderService.hide();
          window.scrollTo(0, 0);
        });
      } else {
        return this.stepReturn.emit({step: 'congratulation', business : this.business, customAccount: null});
      }
    }
  }

  onChange() {
    this.needToUpdate = true;
  }

  showAlert(target) {
    this[target].clear();
    const factory = this.cfr.resolveComponentFactory(AlertComponent);
    const ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }
}
