import {
  Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';

import {BusinessService} from '../../../app/_services';
import {BusinessPayout, Business, User} from '../../../app/_models';
import {LoaderService} from '../../../app/shared/loader/loader.service';

@Component({
  selector: 'app-list-business-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss'],

})
export class ListBusinessShippingComponent implements OnInit {
  @Input('business') business: Business = {};
  @Input('user') user: User = {};
  @Input('customAccount') customAccount: BusinessPayout = {};

  @Output() stepReturn: EventEmitter<{ step: string, business: Business, customAccount: BusinessPayout}>
    = new EventEmitter<{ step: string, business: Business, customAccount: BusinessPayout }>();
  isLoading: boolean;
  needToUpdate = false;

  constructor(private loaderService: LoaderService, private businessService: BusinessService) {}

  ngOnInit() {
    if (!this.business.shippingRates) {
      this.business.shippingRates = [];
    }
  }

  goBack() {
    return this.stepReturn.emit({step: 'profile', business : null, customAccount: null});
  }
  addRate() {
    this.business.shippingRates.push({min: null, max: null, price: null});
  }
  removeRate(index) {
    this.business.shippingRates.splice(index, 1);
  }

  update() {
    if (!this.needToUpdate) {
      return this.stepReturn.emit({step: 'bankDetails', business : null, customAccount: null});
    }
    this.isLoading = true;
    this.loaderService.show();
    this.businessService.update(this.business, this.user).then(() => {
      return this.stepReturn.emit({step: 'bankDetails', business : this.business, customAccount: null});
    }).then(() => {
      this.isLoading = false;
      this.loaderService.hide();
    });
  }

  onChange() {
    this.needToUpdate = true;
  }
}
