import {
  Component, Input, Output, EventEmitter, OnInit
} from '@angular/core';

import {PaymentService} from '../../../app/_services';
import {Product, User, BusinessOrder, Business} from '../../../app/_models';
import {LoaderService} from '../../../app/shared/loader/loader.service';

@Component({
  selector: 'app-account-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],

})
export class AccountPaymentsComponent implements OnInit {
  @Output() tabReturn: EventEmitter<{ products: Product[] }> = new EventEmitter<{ products: Product[] }>();
  @Input('user') user: User = {};
  @Input('business') business: Business = {};

  payments: BusinessOrder[] = [];
  canSubmitCustomAccount = true;

  constructor(private loaderService: LoaderService,
              private paymentService: PaymentService) {
  }

  ngOnInit() {
    if (this.user.businessId) {
      this.loaderService.show();
      this.paymentService.listForBusiness(this.user.businessId)
        .subscribe((payments: BusinessOrder[]) => {
          this.payments = payments;
          this.loaderService.hide();
        });
    }
    if (!this.business || !this.business.name || !this.business.address
      || !this.business.address.countryCode || !this.business.representativeIdentityProof
      || (this.business.businessType === 'company' && (!this.business.taxId || !this.business.website))) {
      this.canSubmitCustomAccount = false;
    }
  }
}
