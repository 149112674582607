// multiple database
import {Injectable, NgZone} from '@angular/core';
import {environment} from '../../environments/environment';
import {AngularFireStorage} from '@angular/fire/storage';

@Injectable()
export class MarketplacePublicFireStorageService extends AngularFireStorage {}

export function MarketplacePublicFireStorageFactory(platformId: any, zone: NgZone) {
    return new AngularFireStorage(environment.firebase, null,  environment.firebase.storageBucket, platformId, zone);
}

@Injectable()
export class MarketplacePrivateFireStorageService extends AngularFireStorage {}
export function MarketplacePrivateFireStorageFactory(platformId: any, zone: NgZone) {
    return new AngularFireStorage(environment.firebase, null,  environment.privateStorageBucket, platformId, zone);
}
