import {
  Component,
  ViewContainerRef,
  ViewChild, Input, Output, EventEmitter, OnInit
} from '@angular/core';

import { PaymentService} from '../../../app/_services';
import {Product, User, BusinessOrder} from '../../../app/_models';
import {LoaderService} from '../../../app/shared/loader/loader.service';

@Component({
  selector: 'app-account-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],

})
export class AccountSalesComponent implements OnInit {
  @Output() tabReturn: EventEmitter<{ products: Product[] }> = new EventEmitter<{ products: Product[] }>();
  @Input('user') user: User = {};

  sales: BusinessOrder[] = [];

  constructor(private loaderService: LoaderService, private paymentService: PaymentService) {}

  ngOnInit() {
    if (this.user.businessId) {
      this.loaderService.show();
      this.paymentService.listForBusiness(this.user.businessId)
        .subscribe((payments: BusinessOrder[]) => {
          this.sales = payments;
          this.loaderService.hide();
        });
    }
  }
}
