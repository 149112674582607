import {
  Component
} from '@angular/core';

import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],

})
export class TermsComponent {
  showTerms = false;
  constructor(private router: Router) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.showTerms = (evt.url === '/terms-of-service');
    });
  }
}
