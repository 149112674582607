<ng-template #alertPayment></ng-template>
<form #checkoutForm="ngForm" (ngSubmit)="onSubmitPayment()" class="checkout">
  <p class="text-center">Total to pay is : {{amount}}</p>
  <label for="card-info">Détails de paiement</label>
  <div class="form-row mb-3">
    <label for="userCardEmail" hidden></label>
    <input class="form-control m-input" type="text" [(ngModel)]="userCardDetails.email" name="userCardEmail"
           id="userCardEmail" placeholder="Email" required>
  </div>
  <div class="form-row">
    <label for="userCardName" hidden></label>
    <input class="form-control m-input" type="text" [(ngModel)]="userCardDetails.name" name="userCardName"
           id="userCardName" placeholder="Name" required>
  </div>
  <div class="form-row">
    <div id="card-info" #cardInfo></div>
    <div id="card-errors" role="alert" *ngIf="error" style="color: #fa755a; margin-bottom: 20px;">{{ error }}</div>
  </div>
  <div class="text-center">
    <button type="submit" class="btn btn-outline-success" [disabled]="isLoading || !checkoutForm.form.valid">Suivant
    </button>
  </div>
</form>
