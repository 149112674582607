import {Injectable} from '@angular/core';
import * as dataStructure from '../../assets/json/data-structure.json';

@Injectable()
export class AppService {
  constructor() {
  }

  getListingProductDatas() {
    return {
      Jewelry: {photos: [
          {label: 'Main image', key: 'mainImage'}
        ]},
      'Luxury Watches': {photos: [
          {label: 'Front side', key: 'fontSide'},
          {label: 'Back side', key: 'backSide'}
        ]},
      'Designer Handbags': {
        photos: [
          {label: 'Front side', key: 'fontSide', image: '../../assets/images/temps/handbag-example-1.jpg'},
          {label: 'Back side', key: 'backSide', image: '../../assets/images/temps/handbag-example-2.jpg'},
          {label: 'Inside brand tag', key: 'InsideBrandTag', image: '../../assets/images/temps/handbag-example-3.jpg'},
          {label: 'Bottom view', key: 'BottomView', image: '../../assets/images/temps/handbag-example-4.jpg'},
          {label: 'Inside view', key: 'InsideView', image: '../../assets/images/temps/handbag-example-5.jpg'},
        ]
      }
    };
  }


  realStructure() {
    return (dataStructure as any).default;
  }

  materialsList() {
    return [
      'Canvas',
      'Cotton',
      'Denim',
      'Exotic Leather',
      'Fur',
      'Leather',
      'Linen',
      'Metal',
      'Pailettes',
      'Patent Leather',
      'Plastic',
      'Polyester',
      'Pony Style Calfskin',
      'Silk',
      'Straw',
      'Suede',
      'Synthetic',
      'Tweed',
      'Velvet',
      'Wool'
    ];
  }

  colorsList() {
    return [
      'Beige',
      'Black',
      'Blue',
      'Bordeaux',
      'Camel',
      'Charcoal',
      'Cream',
      'Gold',
      'Green',
      'Grey',
      'Khaki',
      'Marine Blue',
      'Metal',
      'Multicolor',
      'Orange',
      'Purple',
      'Red',
      'Rose',
      'Silver',
      'Turquoise',
      'White',
      'Yellow'
    ];
  }

  countryList() {
    return [
      {name: 'Afghanistan', alpha2: 'AF', alpha3: 'AFG', numeric: '004'},
      {name: 'Åland Islands', alpha2: 'AX', alpha3: 'ALA', numeric: '248'},
      {name: 'Albania', alpha2: 'AL', alpha3: 'ALB', numeric: '008'},
      {name: 'Algeria', alpha2: 'DZ', alpha3: 'DZA', numeric: '012'},
      {name: 'American Samoa', alpha2: 'AS', alpha3: 'ASM', numeric: '016'},
      {name: 'Andorra', alpha2: 'AD', alpha3: 'AND', numeric: '020'},
      {name: 'Angola', alpha2: 'AO', alpha3: 'AGO', numeric: '024'},
      {name: 'Anguilla', alpha2: 'AI', alpha3: 'AIA', numeric: '660'},
      {name: 'Antarctica', alpha2: 'AQ', alpha3: 'ATA', numeric: '010'},
      {name: 'Antigua and Barbuda', alpha2: 'AG', alpha3: 'ATG', numeric: '028'},
      {name: 'Argentina', alpha2: 'AR', alpha3: 'ARG', numeric: '032'},
      {name: 'Armenia', alpha2: 'AM', alpha3: 'ARM', numeric: '051'},
      {name: 'Aruba', alpha2: 'AW', alpha3: 'ABW', numeric: '533'},
      {name: 'Australia', alpha2: 'AU', alpha3: 'AUS', numeric: '036'},
      {name: 'Austria', alpha2: 'AT', alpha3: 'AUT', numeric: '040'},
      {name: 'Azerbaijan', alpha2: 'AZ', alpha3: 'AZE', numeric: '031'},
      {name: 'Bahamas (the)', alpha2: 'BS', alpha3: 'BHS', numeric: '044'},
      {name: 'Bahrain', alpha2: 'BH', alpha3: 'BHR', numeric: '048'},
      {name: 'Bangladesh', alpha2: 'BD', alpha3: 'BGD', numeric: '050'},
      {name: 'Barbados', alpha2: 'BB', alpha3: 'BRB', numeric: '052'},
      {name: 'Belarus', alpha2: 'BY', alpha3: 'BLR', numeric: '112'},
      {name: 'Belgium', alpha2: 'BE', alpha3: 'BEL', numeric: '056'},
      {name: 'Belize', alpha2: 'BZ', alpha3: 'BLZ', numeric: '084'},
      {name: 'Benin', alpha2: 'BJ', alpha3: 'BEN', numeric: '204'},
      {name: 'Bermuda', alpha2: 'BM', alpha3: 'BMU', numeric: '060'},
      {name: 'Bhutan', alpha2: 'BT', alpha3: 'BTN', numeric: '064'},
      {name: 'Bolivia (Plurinational State of)', alpha2: 'BO', alpha3: 'BOL', numeric: '068'},
      {name: 'Bonaire, Sint Eustatius and Saba', alpha2: 'BQ', alpha3: 'BES', numeric: '535'},
      {name: 'Bosnia and Herzegovina', alpha2: 'BA', alpha3: 'BIH', numeric: '070'},
      {name: 'Botswana', alpha2: 'BW', alpha3: 'BWA', numeric: '072'},
      {name: 'Bouvet Island', alpha2: 'BV', alpha3: 'BVT', numeric: '074'},
      {name: 'Brazil', alpha2: 'BR', alpha3: 'BRA', numeric: '076'},
      {name: 'British Indian Ocean Territory (the)', alpha2: 'IO', alpha3: 'IOT', numeric: '086'},
      {name: 'Brunei Darussalam', alpha2: 'BN', alpha3: 'BRN', numeric: '096'},
      {name: 'Bulgaria', alpha2: 'BG', alpha3: 'BGR', numeric: '100'},
      {name: 'Burkina Faso', alpha2: 'BF', alpha3: 'BFA', numeric: '854'},
      {name: 'Burundi', alpha2: 'BI', alpha3: 'BDI', numeric: '108'},
      {name: 'Cabo Verde', alpha2: 'CV', alpha3: 'CPV', numeric: '132'},
      {name: 'Cambodia', alpha2: 'KH', alpha3: 'KHM', numeric: '116'},
      {name: 'Cameroon', alpha2: 'CM', alpha3: 'CMR', numeric: '120'},
      {name: 'Canada', alpha2: 'CA', alpha3: 'CAN', numeric: '124'},
      {name: 'Cayman Islands (the)', alpha2: 'KY', alpha3: 'CYM', numeric: '136'},
      {name: 'Central African Republic (the)', alpha2: 'CF', alpha3: 'CAF', numeric: '140'},
      {name: 'Chad', alpha2: 'TD', alpha3: 'TCD', numeric: '148'},
      {name: 'Chile', alpha2: 'CL', alpha3: 'CHL', numeric: '152'},
      {name: 'China', alpha2: 'CN', alpha3: 'CHN', numeric: '156'},
      {name: 'Christmas Island', alpha2: 'CX', alpha3: 'CXR', numeric: '162'},
      {name: 'Cocos (Keeling) Islands (the)', alpha2: 'CC', alpha3: 'CCK', numeric: '166'},
      {name: 'Colombia', alpha2: 'CO', alpha3: 'COL', numeric: '170'},
      {name: 'Comoros (the)', alpha2: 'KM', alpha3: 'COM', numeric: '174'},
      {name: 'Congo (the)', alpha2: 'CG', alpha3: 'COG', numeric: '178'},
      {name: 'Congo (the Democratic Republic of the)', alpha2: 'CD', alpha3: 'COD', numeric: '180'},
      {name: 'Cook Islands (the)', alpha2: 'CK', alpha3: 'COK', numeric: '184'},
      {name: 'Costa Rica', alpha2: 'CR', alpha3: 'CRI', numeric: '188'},
      {name: 'Côte d\'Ivoire', alpha2: 'CI', alpha3: 'CIV', numeric: '384'},
      {name: 'Croatia', alpha2: 'HR', alpha3: 'HRV', numeric: '191'},
      {name: 'Cuba', alpha2: 'CU', alpha3: 'CUB', numeric: '192'},
      {name: 'Curaçao', alpha2: 'CW', alpha3: 'CUW', numeric: '531'},
      {name: 'Cyprus', alpha2: 'CY', alpha3: 'CYP', numeric: '196'},
      {name: 'Czech Republic (the)', alpha2: 'CZ', alpha3: 'CZE', numeric: '203'},
      {name: 'Denmark', alpha2: 'DK', alpha3: 'DNK', numeric: '208'},
      {name: 'Djibouti', alpha2: 'DJ', alpha3: 'DJI', numeric: '262'},
      {name: 'Dominica', alpha2: 'DM', alpha3: 'DMA', numeric: '212'},
      {name: 'Dominican Republic (the)', alpha2: 'DO', alpha3: 'DOM', numeric: '214'},
      {name: 'Ecuador', alpha2: 'EC', alpha3: 'ECU', numeric: '218'},
      {name: 'Egypt', alpha2: 'EG', alpha3: 'EGY', numeric: '818'},
      {name: 'El Salvador', alpha2: 'SV', alpha3: 'SLV', numeric: '222'},
      {name: 'Equatorial Guinea', alpha2: 'GQ', alpha3: 'GNQ', numeric: '226'},
      {name: 'Eritrea', alpha2: 'ER', alpha3: 'ERI', numeric: '232'},
      {name: 'Estonia', alpha2: 'EE', alpha3: 'EST', numeric: '233'},
      {name: 'Ethiopia', alpha2: 'ET', alpha3: 'ETH', numeric: '231'},
      {name: 'Falkland Islands (the) [Malvinas]', alpha2: 'FK', alpha3: 'FLK', numeric: '238'},
      {name: 'Faroe Islands (the)', alpha2: 'FO', alpha3: 'FRO', numeric: '234'},
      {name: 'Fiji', alpha2: 'FJ', alpha3: 'FJI', numeric: '242'},
      {name: 'Finland', alpha2: 'FI', alpha3: 'FIN', numeric: '246'},
      {name: 'France', alpha2: 'FR', alpha3: 'FRA', numeric: '250'},
      {name: 'French Guiana', alpha2: 'GF', alpha3: 'GUF', numeric: '254'},
      {name: 'French Polynesia', alpha2: 'PF', alpha3: 'PYF', numeric: '258'},
      {name: 'French Southern Territories (the)', alpha2: 'TF', alpha3: 'ATF', numeric: '260'},
      {name: 'Gabon', alpha2: 'GA', alpha3: 'GAB', numeric: '266'},
      {name: 'Gambia (the)', alpha2: 'GM', alpha3: 'GMB', numeric: '270'},
      {name: 'Georgia', alpha2: 'GE', alpha3: 'GEO', numeric: '268'},
      {name: 'Germany', alpha2: 'DE', alpha3: 'DEU', numeric: '276'},
      {name: 'Ghana', alpha2: 'GH', alpha3: 'GHA', numeric: '288'},
      {name: 'Gibraltar', alpha2: 'GI', alpha3: 'GIB', numeric: '292'},
      {name: 'Greece', alpha2: 'GR', alpha3: 'GRC', numeric: '300'},
      {name: 'Greenland', alpha2: 'GL', alpha3: 'GRL', numeric: '304'},
      {name: 'Grenada', alpha2: 'GD', alpha3: 'GRD', numeric: '308'},
      {name: 'Guadeloupe', alpha2: 'GP', alpha3: 'GLP', numeric: '312'},
      {name: 'Guam', alpha2: 'GU', alpha3: 'GUM', numeric: '316'},
      {name: 'Guatemala', alpha2: 'GT', alpha3: 'GTM', numeric: '320'},
      {name: 'Guernsey', alpha2: 'GG', alpha3: 'GGY', numeric: '831'},
      {name: 'Guinea', alpha2: 'GN', alpha3: 'GIN', numeric: '324'},
      {name: 'Guinea-Bissau', alpha2: 'GW', alpha3: 'GNB', numeric: '624'},
      {name: 'Guyana', alpha2: 'GY', alpha3: 'GUY', numeric: '328'},
      {name: 'Haiti', alpha2: 'HT', alpha3: 'HTI', numeric: '332'},
      {name: 'Heard Island and McDonald Islands', alpha2: 'HM', alpha3: 'HMD', numeric: '334'},
      {name: 'Holy See (the)', alpha2: 'VA', alpha3: 'VAT', numeric: '336'},
      {name: 'Honduras', alpha2: 'HN', alpha3: 'HND', numeric: '340'},
      {name: 'Hong Kong', alpha2: 'HK', alpha3: 'HKG', numeric: '344'},
      {name: 'Hungary', alpha2: 'HU', alpha3: 'HUN', numeric: '348'},
      {name: 'Iceland', alpha2: 'IS', alpha3: 'ISL', numeric: '352'},
      {name: 'India', alpha2: 'IN', alpha3: 'IND', numeric: '356'},
      {name: 'Indonesia', alpha2: 'ID', alpha3: 'IDN', numeric: '360'},
      {name: 'Iran (Islamic Republic of)', alpha2: 'IR', alpha3: 'IRN', numeric: '364'},
      {name: 'Iraq', alpha2: 'IQ', alpha3: 'IRQ', numeric: '368'},
      {name: 'Ireland', alpha2: 'IE', alpha3: 'IRL', numeric: '372'},
      {name: 'Isle of Man', alpha2: 'IM', alpha3: 'IMN', numeric: '833'},
      {name: 'Israel', alpha2: 'IL', alpha3: 'ISR', numeric: '376'},
      {name: 'Italy', alpha2: 'IT', alpha3: 'ITA', numeric: '380'},
      {name: 'Jamaica', alpha2: 'JM', alpha3: 'JAM', numeric: '388'},
      {name: 'Japan', alpha2: 'JP', alpha3: 'JPN', numeric: '392'},
      {name: 'Jersey', alpha2: 'JE', alpha3: 'JEY', numeric: '832'},
      {name: 'Jordan', alpha2: 'JO', alpha3: 'JOR', numeric: '400'},
      {name: 'Kazakhstan', alpha2: 'KZ', alpha3: 'KAZ', numeric: '398'},
      {name: 'Kenya', alpha2: 'KE', alpha3: 'KEN', numeric: '404'},
      {name: 'Kiribati', alpha2: 'KI', alpha3: 'KIR', numeric: '296'},
      {name: 'Korea (the Democratic People\'s Republic of)', alpha2: 'KP', alpha3: 'PRK', numeric: '408'},
      {name: 'Korea (the Republic of)', alpha2: 'KR', alpha3: 'KOR', numeric: '410'},
      {name: 'Kuwait', alpha2: 'KW', alpha3: 'KWT', numeric: '414'},
      {name: 'Kyrgyzstan', alpha2: 'KG', alpha3: 'KGZ', numeric: '417'},
      {name: 'Lao People\'s Democratic Republic (the)', alpha2: 'LA', alpha3: 'LAO', numeric: '418'},
      {name: 'Latvia', alpha2: 'LV', alpha3: 'LVA', numeric: '428'},
      {name: 'Lebanon', alpha2: 'LB', alpha3: 'LBN', numeric: '422'},
      {name: 'Lesotho', alpha2: 'LS', alpha3: 'LSO', numeric: '426'},
      {name: 'Liberia', alpha2: 'LR', alpha3: 'LBR', numeric: '430'},
      {name: 'Libya', alpha2: 'LY', alpha3: 'LBY', numeric: '434'},
      {name: 'Liechtenstein', alpha2: 'LI', alpha3: 'LIE', numeric: '438'},
      {name: 'Lithuania', alpha2: 'LT', alpha3: 'LTU', numeric: '440'},
      {name: 'Luxembourg', alpha2: 'LU', alpha3: 'LUX', numeric: '442'},
      {name: 'Macao', alpha2: 'MO', alpha3: 'MAC', numeric: '446'},
      {name: 'Macedonia (the former Yugoslav Republic of)', alpha2: 'MK', alpha3: 'MKD', numeric: '807'},
      {name: 'Madagascar', alpha2: 'MG', alpha3: 'MDG', numeric: '450'},
      {name: 'Malawi', alpha2: 'MW', alpha3: 'MWI', numeric: '454'},
      {name: 'Malaysia', alpha2: 'MY', alpha3: 'MYS', numeric: '458'},
      {name: 'Maldives', alpha2: 'MV', alpha3: 'MDV', numeric: '462'},
      {name: 'Mali', alpha2: 'ML', alpha3: 'MLI', numeric: '466'},
      {name: 'Malta', alpha2: 'MT', alpha3: 'MLT', numeric: '470'},
      {name: 'Marshall Islands (the)', alpha2: 'MH', alpha3: 'MHL', numeric: '584'},
      {name: 'Martinique', alpha2: 'MQ', alpha3: 'MTQ', numeric: '474'},
      {name: 'Mauritania', alpha2: 'MR', alpha3: 'MRT', numeric: '478'},
      {name: 'Mauritius', alpha2: 'MU', alpha3: 'MUS', numeric: '480'},
      {name: 'Mayotte', alpha2: 'YT', alpha3: 'MYT', numeric: '175'},
      {name: 'Mexico', alpha2: 'MX', alpha3: 'MEX', numeric: '484'},
      {name: 'Micronesia (Federated States of)', alpha2: 'FM', alpha3: 'FSM', numeric: '583'},
      {name: 'Moldova (the Republic of)', alpha2: 'MD', alpha3: 'MDA', numeric: '498'},
      {name: 'Monaco', alpha2: 'MC', alpha3: 'MCO', numeric: '492'},
      {name: 'Mongolia', alpha2: 'MN', alpha3: 'MNG', numeric: '496'},
      {name: 'Montenegro', alpha2: 'ME', alpha3: 'MNE', numeric: '499'},
      {name: 'Montserrat', alpha2: 'MS', alpha3: 'MSR', numeric: '500'},
      {name: 'Morocco', alpha2: 'MA', alpha3: 'MAR', numeric: '504'},
      {name: 'Mozambique', alpha2: 'MZ', alpha3: 'MOZ', numeric: '508'},
      {name: 'Myanmar', alpha2: 'MM', alpha3: 'MMR', numeric: '104'},
      {name: 'Namibia', alpha2: 'NA', alpha3: 'NAM', numeric: '516'},
      {name: 'Nauru', alpha2: 'NR', alpha3: 'NRU', numeric: '520'},
      {name: 'Nepal', alpha2: 'NP', alpha3: 'NPL', numeric: '524'},
      {name: 'Netherlands (the)', alpha2: 'NL', alpha3: 'NLD', numeric: '528'},
      {name: 'New Caledonia', alpha2: 'NC', alpha3: 'NCL', numeric: '540'},
      {name: 'New Zealand', alpha2: 'NZ', alpha3: 'NZL', numeric: '554'},
      {name: 'Nicaragua', alpha2: 'NI', alpha3: 'NIC', numeric: '558'},
      {name: 'Niger (the)', alpha2: 'NE', alpha3: 'NER', numeric: '562'},
      {name: 'Nigeria', alpha2: 'NG', alpha3: 'NGA', numeric: '566'},
      {name: 'Niue', alpha2: 'NU', alpha3: 'NIU', numeric: '570'},
      {name: 'Norfolk Island', alpha2: 'NF', alpha3: 'NFK', numeric: '574'},
      {name: 'Northern Mariana Islands (the)', alpha2: 'MP', alpha3: 'MNP', numeric: '580'},
      {name: 'Norway', alpha2: 'NO', alpha3: 'NOR', numeric: '578'},
      {name: 'Oman', alpha2: 'OM', alpha3: 'OMN', numeric: '512'},
      {name: 'Pakistan', alpha2: 'PK', alpha3: 'PAK', numeric: '586'},
      {name: 'Palau', alpha2: 'PW', alpha3: 'PLW', numeric: '585'},
      {name: 'Palestine, State of', alpha2: 'PS', alpha3: 'PSE', numeric: '275'},
      {name: 'Panama', alpha2: 'PA', alpha3: 'PAN', numeric: '591'},
      {name: 'Papua New Guinea', alpha2: 'PG', alpha3: 'PNG', numeric: '598'},
      {name: 'Paraguay', alpha2: 'PY', alpha3: 'PRY', numeric: '600'},
      {name: 'Peru', alpha2: 'PE', alpha3: 'PER', numeric: '604'},
      {name: 'Philippines (the)', alpha2: 'PH', alpha3: 'PHL', numeric: '608'},
      {name: 'Pitcairn', alpha2: 'PN', alpha3: 'PCN', numeric: '612'},
      {name: 'Poland', alpha2: 'PL', alpha3: 'POL', numeric: '616'},
      {name: 'Portugal', alpha2: 'PT', alpha3: 'PRT', numeric: '620'},
      {name: 'Puerto Rico', alpha2: 'PR', alpha3: 'PRI', numeric: '630'},
      {name: 'Qatar', alpha2: 'QA', alpha3: 'QAT", "numeric":"634'},
      {name: 'Réunion', alpha2: 'RE', alpha3: 'REU', numeric: '638'},
      {name: 'Romania', alpha2: 'RO', alpha3: 'ROU', numeric: '642'},
      {name: 'Russian Federation (the)', alpha2: 'RU', alpha3: 'RUS', numeric: '643'},
      {name: 'Rwanda', alpha2: 'RW', alpha3: 'RWA', numeric: '646'},
      {name: 'Saint Barthélemy', alpha2: 'BL', alpha3: 'BLM', numeric: '652'},
      {name: 'Saint Helena, Ascension and Tristan da Cunha', alpha2: 'SH', alpha3: 'SHN', numeric: '654'},
      {name: 'Saint Kitts and Nevis', alpha2: 'KN', alpha3: 'KNA', numeric: '659'},
      {name: 'Saint Lucia', alpha2: 'LC', alpha3: 'LCA', numeric: '662'},
      {name: 'Saint Martin (French part)', alpha2: 'MF', alpha3: 'MAF', numeric: '663'},
      {name: 'Saint Pierre and Miquelon', alpha2: 'PM', alpha3: 'SPM', numeric: '666'},
      {name: 'Saint Vincent and the Grenadines', alpha2: 'VC', alpha3: 'VCT', numeric: '670'},
      {name: 'Samoa', alpha2: 'WS', alpha3: 'WSM', numeric: '882'},
      {name: 'San Marino', alpha2: 'SM', alpha3: 'SMR', numeric: '674'},
      {name: 'Sao Tome and Principe', alpha2: 'ST', alpha3: 'STP', numeric: '678'},
      {name: 'Saudi Arabia', alpha2: 'SA', alpha3: 'SAU', numeric: '682'},
      {name: 'Senegal', alpha2: 'SN', alpha3: 'SEN', numeric: '686'},
      {name: 'Serbia', alpha2: 'RS', alpha3: 'SRB', numeric: '688'},
      {name: 'Seychelles', alpha2: 'SC', alpha3: 'SYC', numeric: '690'},
      {name: 'Sierra Leone', alpha2: 'SL', alpha3: 'SLE', numeric: '694'},
      {name: 'Singapore', alpha2: 'SG', alpha3: 'SGP', numeric: '702'},
      {name: 'Sint Maarten (Dutch part)', alpha2: 'SX', alpha3: 'SXM', numeric: '534'},
      {name: 'Slovakia', alpha2: 'SK', alpha3: 'SVK', numeric: '703'},
      {name: 'Slovenia', alpha2: 'SI', alpha3: 'SVN', numeric: '705'},
      {name: 'Solomon Islands', alpha2: 'SB', alpha3: 'SLB', numeric: '090'},
      {name: 'Somalia', alpha2: 'SO', alpha3: 'SOM', numeric: '706'},
      {name: 'South Africa', alpha2: 'ZA', alpha3: 'ZAF', numeric: '710'},
      {name: 'South Georgia and the South Sandwich Islands', alpha2: 'GS', alpha3: 'SGS', numeric: '239'},
      {name: 'South Sudan ', alpha2: 'SS', alpha3: 'SSD', numeric: '728'},
      {name: 'Spain', alpha2: 'ES', alpha3: 'ESP', numeric: '724'},
      {name: 'Sri Lanka', alpha2: 'LK', alpha3: 'LKA', numeric: '144'},
      {name: 'Sudan (the)', alpha2: 'SD', alpha3: 'SDN', numeric: '729'},
      {name: 'Suriname', alpha2: 'SR', alpha3: 'SUR', numeric: '740'},
      {name: 'Svalbard and Jan Mayen', alpha2: 'SJ', alpha3: 'SJM', numeric: '744'},
      {name: 'Swaziland', alpha2: 'SZ', alpha3: 'SWZ', numeric: '748'},
      {name: 'Sweden', alpha2: 'SE', alpha3: 'SWE', numeric: '752'},
      {name: 'Switzerland', alpha2: 'CH', alpha3: 'CHE', numeric: '756'},
      {name: 'Syrian Arab Republic', alpha2: 'SY', alpha3: 'SYR', numeric: '760'},
      {name: 'Taiwan (Province of China)', alpha2: 'TW', alpha3: 'TWN', numeric: '158'},
      {name: 'Tajikistan', alpha2: 'TJ', alpha3: 'TJK', numeric: '762'},
      {name: 'Tanzania, United Republic of', alpha2: 'TZ', alpha3: 'TZA', numeric: '834'},
      {name: 'Thailand', alpha2: 'TH', alpha3: 'THA', numeric: '764'},
      {name: 'Timor-Leste', alpha2: 'TL', alpha3: 'TLS', numeric: '626'},
      {name: 'Togo', alpha2: 'TG', alpha3: 'TGO', numeric: '768'},
      {name: 'Tokelau', alpha2: 'TK', alpha3: 'TKL', numeric: '772'},
      {name: 'Tonga', alpha2: 'TO', alpha3: 'TON', numeric: '776'},
      {name: 'Trinidad and Tobago', alpha2: 'TT', alpha3: 'TTO', numeric: '780'},
      {name: 'Tunisia', alpha2: 'TN', alpha3: 'TUN', numeric: '788'},
      {name: 'Turkey', alpha2: 'TR', alpha3: 'TUR', numeric: '792'},
      {name: 'Turkmenistan', alpha2: 'TM', alpha3: 'TKM', numeric: '795'},
      {name: 'Turks and Caicos Islands (the)', alpha2: 'TC', alpha3: 'TCA', numeric: '796'},
      {name: 'Tuvalu', alpha2: 'TV', alpha3: 'TUV', numeric: '798'},
      {name: 'Uganda', alpha2: 'UG', alpha3: 'UGA', numeric: '800'},
      {name: 'Ukraine', alpha2: 'UA', alpha3: 'UKR', numeric: '804'},
      {name: 'United Arab Emirates (the)', alpha2: 'AE', alpha3: 'ARE', numeric: '784'},
      {name: 'United Kingdom of Great Britain and Northern Ireland (the)', alpha2: 'GB', alpha3: 'GBR', numeric: '826'},
      {name: 'United States Minor Outlying Islands (the)', alpha2: 'UM', alpha3: 'UMI", "numeric":"581'},
      {name: 'United States of America (the)', alpha2: 'US', alpha3: 'USA', numeric: '840'},
      {name: 'Uruguay', alpha2: 'UY', alpha3: 'URY', numeric: '858'},
      {name: 'Uzbekistan', alpha2: 'UZ', alpha3: 'UZB', numeric: '860'},
      {name: 'Vanuatu', alpha2: 'VU', alpha3: 'VUT', numeric: '548'},
      {name: 'Venezuela (Bolivarian Republic of)', alpha2: 'VE', alpha3: 'VEN', numeric: '862'},
      {name: 'Vietnam', alpha2: 'VN', alpha3: 'VNM', numeric: '704'},
      {name: 'Virgin Islands (British)', alpha2: 'VG', alpha3: 'VGB', numeric: '092'},
      {name: 'Virgin Islands (U.S.)', alpha2: 'VI', alpha3: 'VIR', numeric: '850'},
      {name: 'Wallis and Futuna', alpha2: 'WF', alpha3: 'WLF', numeric: '876'},
      {name: 'Western Sahara', alpha2: 'EH', alpha3: 'ESH', numeric: '732'},
      {name: 'Yemen', alpha2: 'YE', alpha3: 'YEM', numeric: '887'},
      {name: 'Zambia', alpha2: 'ZM', alpha3: 'ZMB', numeric: '894'},
      {name: 'Zimbabwe', alpha2: 'ZW', alpha3: 'ZWE', numeric: '716'}
    ];
  }

  currenciesList() {
    return [
      {code: 'AED', name: 'United Arab Emirates Dirham', countries: ['AE']},
      {code: 'AUD', name: 'Australian Dollar', countries: ['AU', 'CX', 'CC', 'HM', 'KI', 'NR', 'NF', 'TV']},
      {code: 'BRL', name: 'Brazilian Real', countries: ['BR']},
      {code: 'CAD', name: 'Canadian Dollar', countries: ['CA']},
      {code: 'CHF', name: 'Swiss Franc', countries: ['LI', 'CH']},
      {code: 'CLP', name: 'Chilean Peso', countries: ['CL']},
      {code: 'CZK', name: 'Czech Koruna', countries: ['CZ']},
      {code: 'DKK', name: 'Danish Krone', countries: ['DK', 'FO', 'GL']},
      {code: 'EGP', name: 'Egyptian Pound', countries: ['EG']},
      {
        code: 'EUR',
        name: 'Euro',
        countries: [
          'AX', 'AD', 'AT', 'BE', 'CY', 'EE', 'FI', 'FR', 'GF', 'TF', 'DE', 'GR', 'GP', 'IE', 'IT',
          'LU', 'LT', 'MT', 'MQ', 'YT', 'MC', 'ME', 'NL', 'PT', 'RE', 'BL', 'MF', 'PM', 'RU', 'SM', 'SK', 'SI', 'ES', 'VA'
        ]
      },
      {code: 'FJD', name: 'Fijian Dollar', countries: ['FJ']},
      {code: 'GBP', name: 'British Pound', countries: ['GG', 'IM', 'JE', 'GB']},
      {code: 'GEL', name: 'Georgian Lari', countries: ['GE']},
      {code: 'GTQ', name: 'Guatemalan Quetzal', countries: ['GT']},
      {code: 'HKD', name: 'Hong Kong Dollar', countries: ['HK']},
      {code: 'IDR', name: 'Indonesian Rupiah', countries: ['ID']},
      {code: 'ILS', name: 'Israeli New Shekel', countries: ['IL']},
      {code: 'INR', name: 'Indian Rupee', countries: ['BT', 'IN']},
      {code: 'ISK', name: 'Icelandic Króna', countries: ['IS']},
      {code: 'JPY', name: 'Japanese Yen', countries: ['JP']},
      {code: 'KES', name: 'Kenyan Shilling', countries: ['KE']},
      {code: 'KHR', name: 'Cambodian Riel', countries: ['KH']},
      {code: 'LKR', name: 'Sri Lankan Rupee', countries: ['LK']},
      {code: 'MAD', name: 'Moroccan Dirham', countries: ['MA', 'EH']},
      {code: 'MUR', name: 'Mauritian Rupee', countries: ['MR']},
      {code: 'MXN', name: 'Mexican Peso', countries: ['MX']},
      {code: 'MMK', name: 'Myanmar Kyat', countries: ['MM']},
      {code: 'NOK', name: 'Norwegian Krone', countries: ['BV', 'NO', 'SJ']},
      {code: 'NPR', name: 'Nepalese Rupee', countries: ['NP']},
      {code: 'NZD', name: 'New Zealand Dollar', countries: ['CK', 'NZ', 'NU', 'PN', 'TK']},
      {code: 'PHP', name: 'Philippine Peso', countries: ['PK']},
      {code: 'PKR', name: 'Pakistani Rupee', countries: ['PH']},
      {code: 'RUB', name: 'Russian Ruble', countries: ['RU']},
      {code: 'SGD', name: 'Singapore Dollar', countries: ['SG']},
      {code: 'SEK', name: 'Swedish Krona', countries: ['SE']},
      {code: 'THB', name: 'Thai Baht', countries: ['TH']},
      {code: 'TZS', name: 'Tanzanian Shilling', countries: ['TZ']},
      {code: 'UGX', name: 'Ugandan Shilling', countries: ['UG']},
      {
        code: 'USD',
        name: 'US Dollar',
        countries: ['AS', 'BQ', 'IO', 'EC', 'SV', 'GU', 'HT', 'MH', 'FM', 'MP', 'PW', 'PA', 'PR', 'TL', 'TC', 'US', 'UM', 'VG', 'VI']
      },
      {code: 'VND', name: 'Vietnamese Dong', countries: ['VN']},
      {code: 'XPF', name: 'CFP Franc', countries: ['PF', 'NC', 'WF']},
      {code: 'ZAR', name: 'South African Rand', countries: ['LS', 'NA', 'ZA']}
    ];
  }

}
