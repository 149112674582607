import { BrowserModule } from '@angular/platform-browser';
import {NgModule, NgZone, PLATFORM_ID} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as $ from 'jquery';
import * as bootstrap from 'bootstrap';
import * as moment from 'moment';

import {LoaderComponent} from './shared/loader/loader.component';
import {LoaderService} from './shared/loader/loader.service';
import {MenuComponent} from './shared/menu/menu.component';
import {FooterComponent} from './shared/footer/footer.component';

import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';


import {CONST_ROUTING} from './app.routing';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';

import {FileUploadComponent} from './shared/file-upload/file-upload.component';
import {FileSizePipe} from './_pipe/file-size/file-size.pipe';
import {DurationToTimePipe, TimeUntilNowPipe, TimeToLabelPipe} from './_pipe/timer/timer.pipe';

import {PaymentComponent} from './shared/payment/payment.component';


import {
  LandingPageComponent,
  ListBusinessComponent,
  PreviewProductComponent,
  ListBusinessCreateComponent,
  ListBusinessDetailsComponent,
  ListBusinessProfileComponent,
  ListBusinessRepresentativeComponent,
  ListBusinessPayoutComponent,
  ListBusinessReviewComponent,
  ListProductCharacteristicsComponent,
  ListProductComponent,
  ListProductPricesComponent,
  ListProductSellerComponent,
  AccountComponent,
  AccountProfileComponent,
  AccountProductsComponent,
  AccountSalesComponent,
  AccountPaymentsComponent,
  ListProductThanksComponent,
  TermsComponent, ListBusinessShippingComponent
} from '../pages';

import {
  AppService,
  AlertService,
  AuthenticationService,
  UserService,
  BusinessService,
  ProductService,
  AuthGuardService,
  NotLoginGuardService,
  PaymentService,
  NgbDateCustomParserFormatter,
  SendinblueService,
  MarketplacePublicFireStorageService,
  MarketplacePrivateFireStorageService,
  MarketplacePublicFireStorageFactory, MarketplacePrivateFireStorageFactory, EmailValidationGuardService, IsUserLoginService
} from './_services';

import {AlertComponent, DropZoneDirective} from './_directives';
import {ListProductDetailsComponent} from '../pages';
import {ListProductMediasComponent} from '../pages';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {Ng2TelInputModule} from './_directives/ng2-tel-input/ng2-tel-input.module';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    LoaderComponent,
    LandingPageComponent,
    AccountComponent,
    AccountProfileComponent,
    AccountProductsComponent,
    AccountSalesComponent,
    AccountPaymentsComponent,
    ListProductComponent,
    ListProductDetailsComponent,
    ListProductMediasComponent,
    ListProductCharacteristicsComponent,
    ListProductPricesComponent,
    ListProductSellerComponent,
    ListProductThanksComponent,
    ListBusinessComponent,
    ListBusinessCreateComponent,
    ListBusinessDetailsComponent,
    ListBusinessProfileComponent,
    ListBusinessRepresentativeComponent,
    ListBusinessPayoutComponent,
    ListBusinessReviewComponent,
    ListBusinessShippingComponent,
    PreviewProductComponent,
    AlertComponent,
    FileUploadComponent,
    FileSizePipe,
    DurationToTimePipe,
    TimeUntilNowPipe,
    TimeToLabelPipe,
    PaymentComponent,
    DropZoneDirective,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    CONST_ROUTING,
    AngularFireModule.initializeApp(environment.firebase), // Main Angular fire module
    AngularFirestoreModule,  // Firebase database module
    AngularFireAuthModule,
    AngularFireStorageModule,
    NgbModule,
    BrowserAnimationsModule,
    Ng2TelInputModule
  ],
  providers: [
    AppService,
    IsUserLoginService,
    PaymentService,
    AlertService,
    AuthenticationService,
    UserService,
    BusinessService,
    ProductService,
    LoaderService,
    AuthGuardService,
    NotLoginGuardService,
    EmailValidationGuardService,
    SendinblueService,
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
    { provide: MarketplacePublicFireStorageService, deps: [PLATFORM_ID, NgZone], useFactory: MarketplacePublicFireStorageFactory },
    { provide: MarketplacePrivateFireStorageService, deps: [PLATFORM_ID, NgZone], useFactory: MarketplacePrivateFireStorageFactory },
  ],
  exports: [
    AppComponent,
    LoaderComponent,
    FileUploadComponent,
    FileSizePipe,
    DurationToTimePipe,
    TimeUntilNowPipe,
    TimeToLabelPipe,
    PaymentComponent,
    DropZoneDirective
  ],
  bootstrap: [AppComponent],
  entryComponents: [AlertComponent]
})
export class AppModule {}
