<section class="bg-gray-light" *ngIf="business && business.id">
  <div class="container pt-5 py-md-5">
    <form class="" (ngSubmit)="update()" #updateBusinessForm="ngForm">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <h2 class="mt-3 mb-5 text-center">Your business details</h2>
          <div class="form-group">
            <label class="col-form-label" for="businessName">BUSINESS NAME<span class="required">*</span></label>
            <input type="text" class="form-control form-control-lg" id="businessName" placeholder="Enter your business name" [(ngModel)]="business.name" (change)="onChange()" name="businessName" required>
          </div>
          <div class="form-group">
            <label class="col-form-label" for="taxId">BUSINESS REGISTRATION NUMBER</label>
            <input type="text" class="form-control form-control-lg" id="taxId" placeholder="Enter your registration number" [(ngModel)]="business.taxId" (change)="onChange()" name="taxId">
          </div>
          <div class="form-group">
            <label class="col-form-label" for="streetAddress">STREET<span class="required">*</span></label>
            <input type="text" class="form-control form-control-lg" id="streetAddress" placeholder="Enter your street address" [(ngModel)]="business.address.streetAddress" (change)="onChange()" name="streetAddress" required>
          </div>
          <div class="form-group">
            <label class="col-form-label" for="city">CITY<span class="required">*</span></label>
            <input type="text" class="form-control form-control-lg" id="city" placeholder="Enter your city" [(ngModel)]="business.address.city" (change)="onChange()" name="city" required>
          </div>
          <div class="form-group">
            <label class="col-form-label" for="postcode">POSTCODE<span class="required">*</span></label>
            <input type="number" class="form-control form-control-lg" id="postcode" placeholder="Enter your postcode" [(ngModel)]="business.address.postcode" (change)="onChange()" name="postcode" required>
          </div>
          <div class="form-group">
            <label class="col-form-label" for="state">STATE<span class="required">*</span></label>
            <input type="text" class="form-control form-control-lg" id="state" placeholder="Enter your state" [(ngModel)]="business.address.state" (change)="onChange()" name="state" required>
          </div>
          <div class="form-group">
            <label class="col-form-label" for="country">COUNTRY*<span class="required">*</span></label>
            <select class="form-control form-control-lg" id="country" [(ngModel)]="selectedCounty" (ngModelChange)="selectCountry($event)" name="country" required [disabled]="disableCountry" [ngClass]="{'is-invalid': !selectedCounty.alpha2 }">
              <option *ngFor="let item of countries" [ngValue]="item" >{{item.name}}{{item.alpha2? ' (' + item.alpha2 + ')' : ''}}</option>
            </select>
            <p class="">*Once you have selected your country, you can no longer change this</p>
          </div>
          <div class="form-group" *ngIf="business">
            <label class="col-form-label" for="phone">PHONE NUMBER</label>
            <input type="text" class="form-control form-control-lg" ng2TelInput (ng2TelOutput)="onPhoneChanges($event)" (hasError)="onPhoneError($event)" name="phone" id="phone" [value]="business.phone || null" >
            <div class="alert alert-danger mt-1" role="alert" *ngIf="!isPhoneNumberValid">This phone number is not valid!</div>
          </div>
          <div class="form-group">
            <label class="col-form-label" for="email">EMAIL ADDRESS</label>
            <input type="text" [email]="true" class="form-control form-control-lg" id="email" placeholder="Enter your business email" [(ngModel)]="business.email" (change)="onChange()" name="email">
          </div>
          <div class="form-group">
            <label class="col-form-label" for="email">BUSINESS WEBSITE</label>
            <input type="text" class="form-control form-control-lg" id="website" placeholder="Enter your business website" [(ngModel)]="business.website" (change)="onChange()" name="website">
          </div>
          <div class="text-center">
            <ng-template #alertMessage></ng-template>
          </div>

          <div class="text-right mt-5">
            <button type="submit" class="btn btn-primary" [disabled]="!updateBusinessForm.form.valid">COMPLETE STEP 1</button>
          </div>

        </div>
      </div>
    </form>
  </div>
</section>
