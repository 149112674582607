import {Routes, RouterModule} from '@angular/router';
import {AuthGuardService, EmailValidationGuardService} from './_services';
import {
  LandingPageComponent,
  ListBusinessComponent,
  PreviewProductComponent,
  ListProductComponent, AccountComponent, TermsComponent,
} from '../pages';

const MAINMENU_ROUTES: Routes = [
  // full : makes sure the path is absolute path
  {path: '', component: LandingPageComponent, canActivate: [EmailValidationGuardService]},

  {path: 'list-business', component: ListBusinessComponent, canActivate: [AuthGuardService]},
  {path: 'list-business/:step', component: ListBusinessComponent, canActivate: [AuthGuardService]},

  {path: 'list-product/:step/:id', component: ListProductComponent, canActivate: [AuthGuardService]},
  {path: 'list-product/:id', component: ListProductComponent, canActivate: [AuthGuardService]},
  {path: 'list-product', component: ListProductComponent, canActivate: [AuthGuardService]},

  {path: 'terms-of-service', component: TermsComponent},
  {path: 'privacy-policy', component: TermsComponent},
  {path: 'account/:tab/:status', component: AccountComponent, canActivate: [AuthGuardService]},
  {path: 'account/:tab', component: AccountComponent, canActivate: [AuthGuardService]},
  {path: 'account', component: AccountComponent, canActivate: [AuthGuardService]},

  {path: 'product/preview/:slug1', component: PreviewProductComponent},



  {path: '**', redirectTo: '/'}
];
export const CONST_ROUTING = RouterModule.forRoot(MAINMENU_ROUTES);
