import {
  Component,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver, OnInit, EventEmitter, Input, Output
} from '@angular/core';

import {AlertService, BusinessService} from '../../../app/_services';
import {BusinessPayout, Business, Country, User} from '../../../app/_models';
import {AlertComponent} from '../../../app/_directives';
import {LoaderService} from '../../../app/shared/loader/loader.service';

@Component({
  selector: 'app-list-business-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],

})
export class ListBusinessDetailsComponent implements OnInit {
  selectedCounty: Country;
  disableCountry = false;
  isLoading = false;
  needToUpdate = false;
  isPhoneNumberValid = true;
  @ViewChild('alertMessage', { read: ViewContainerRef }) alertMessage: ViewContainerRef;
  @Input('countries') countries: Country[] = [];
  @Input('business') business: Business = {};
  @Input('user') user: User = {};
  @Output() stepReturn: EventEmitter<{ step: string, business: Business, customAccount: BusinessPayout}>
    = new EventEmitter<{ step: string, business: Business, customAccount: BusinessPayout }>();

  constructor(private loaderService: LoaderService,
              private businessService: BusinessService,
              private cfr: ComponentFactoryResolver,
              private alertService: AlertService) {
  }

  ngOnInit() {
    if (!this.business.address || !this.business.address.countryCode) {
      this.selectedCounty = this.countries[0];
    } else {
      this.selectedCounty = this.countries.find(x => x.alpha2 === this.business.address.countryCode);
    }
  }

  onPhoneChanges(phoneNumber: string): void {
    this.business.phone = phoneNumber;
    this.needToUpdate = true;
  }
  onPhoneError(isSuccess: boolean): void {
    this.isPhoneNumberValid = isSuccess;
  }

  selectCountry(country) {
    this.selectedCounty = country;
    this.business.address.country = country.name;
    this.business.address.countryCode = country.alpha2;
    this.needToUpdate = true;
  }

  update() {
    if (!this.needToUpdate) {
      return this.stepReturn.emit({step: 'profile', business : null, customAccount: null});
    }
    this.isLoading = true;
    this.loaderService.show();
    this.businessService.update(this.business, this.user)
      .then(() => {
        this.isLoading = false;
        this.loaderService.hide();
        return this.stepReturn.emit({step: 'profile', business : this.business, customAccount: null});
      })
      .catch((e) => {
        this.showAlert('alertMessage');
        this.alertService.success(e.message);
        this.isLoading = false;
        this.loaderService.hide();
      });
  }

  onChange() {
    this.needToUpdate = true;
  }

  showAlert(target) {
    this[target].clear();
    const factory = this.cfr.resolveComponentFactory(AlertComponent);
    const ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }
}
