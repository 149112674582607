import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class NotLoginGuardService implements CanActivate {

  constructor(public auth: AuthenticationService, public router: Router) {
  }

  canActivate(): any {
    return new Promise((resolve) => {
      return this.auth.isLogin().then((user: any) => {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (user && user.id && currentUser && currentUser.id === user.id) {
          return this.router.navigate(['/account/dashboard']).then(() => {
            resolve(false);
          });
        } else {
          resolve(true);
        }
      });
    });
  }
}
