import {
  AfterViewInit,
  Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef
} from '@angular/core';

import {User} from '../../app/_models';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService, AuthenticationService, IsUserLoginService} from '../../app/_services';
import {LoaderService} from '../../app/shared/loader/loader.service';
import {AlertComponent} from '../../app/_directives';
import {Subscription} from 'rxjs';
import { NgbCarousel} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {
  user: User;
  validationEmail: {
    success: boolean,
    actionCode: string,
    message: string,
    actionSuccess: boolean,
    newPassword: string,
    mode: string
  };
  showLoginBtn = false;

  isLoading = false;
  @ViewChild('alertMessage', { read: ViewContainerRef, static: true }) alertMessage: ViewContainerRef;
  @ViewChild('alertResetPasswordMessage', { read: ViewContainerRef }) alertResetPasswordMessage: ViewContainerRef;
  subscription: Subscription;

  images = ['../../assets/images/temps/landing_page_hero_1.jpg',
    '../../assets/images/temps/landing_page_hero_2.jpg', '../../assets/images/temps/landing_page_hero_3.jpg'];
  @ViewChild('carousel', { read: NgbCarousel }) carousel: NgbCarousel;

  sliderActive = 'ngb-slide-0';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: AuthenticationService,
              private alertService: AlertService,
              private cfr: ComponentFactoryResolver,
              private loaderService: LoaderService,
              private isUserLoginService: IsUserLoginService) {
    this.user = JSON.parse(localStorage.getItem('currentUser')) || {id: null};
    this.subscription = this.isUserLoginService.getUser().subscribe(user => { this.user = user; });
  }

  switchSlider(id) {
    this.carousel.select(id);
    this.sliderActive = id;
  }

  ngOnInit() {
    const localData = localStorage.getItem('EmailValidationResult');
    const EmailValidationResult: any = localData && localData !== 'undefined' ? JSON.parse(localData) : null;
    if (EmailValidationResult) {
      this.checkValidationEmail(EmailValidationResult);
      localStorage.removeItem('EmailValidationResult');
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  resetPassword() {
    this.loaderService.show();
    this.isLoading = true;
    this.authService.handleResetPassword(this.validationEmail.actionCode, this.validationEmail.newPassword)
      .then((successMessage: string) => {
        this.validationEmail.actionSuccess = true;
        this.showLoginBtn = true;
        this.showAlert('alertResetPasswordMessage');
        return this.alertService.success(successMessage, true);
      })
      .catch((errorMessage: string) => {
        this.validationEmail.actionSuccess = false;
        this.showAlert('alertResetPasswordMessage');
        return this.alertService.error(errorMessage, true);
      })
      .finally(() => {
        this.loaderService.hide();
        this.isLoading = false;
      });
  }

  checkValidationEmail(res) {
    if (res.success && res.mode) {
      this.showLoginBtn = true;
      this.validationEmail = res;
      if (res.mode === 'showResetPasswordModal') {
        $('#resetPasswordModal').modal('show');
      }
    } else if (res.success) {
      this.showAlert('alertMessage');
      this.alertService.success(res.message, true);
    } else {
      this.showAlert('alertMessage');
      this.alertService.error(res.message, true);
    }
  }


  showAlert(target) {
    this[target].clear();
    const factory = this.cfr.resolveComponentFactory(AlertComponent);
    const ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }
}
