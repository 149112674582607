<div class="img-preview d-flex" *ngIf="completeFileUrl && template != 'input-group' && imagePosition =='top' && !hidePreview" [style.min-height]="viewHeight ? (viewHeight)+'px' : null" [style.max-height]="viewHeight ? (viewHeight+30)+'px' : null">
  <img  *ngIf="mediaType!=='video'" [src]="completeFileUrl" alt="your uploaded image" [class]="imageClass + ' img-fluid m-auto d-block'" [style.max-height]="viewHeight ? (viewHeight)+'px' : null">
  <video controls *ngIf="mediaType==='video'" [class]="imageClass + ' img-fluid m-auto d-block'" [style.max-height]="viewHeight ? (viewHeight)+'px' : null">
    <source [src]="completeFileUrl" type="video/mp4">
    Your browser does not support the video tag.
  </video>
  <button type="button" class="btn btn-primary" (click)="delete(completeFileUrl)"><i class="fas fa-trash-alt"></i></button>
</div>
<div appDropZone
     (hovered)="toggleHover($event)"
     (dropped)="startUpload($event)"
     [class.hovering]="isHovering"
     [style.height]="viewHeight ? viewHeight+'px' : null"
     *ngIf="template == 'drop-zone' && ((!hidePreview && !completeFileUrl) || hidePreview)">

  <div class="file">
    <label class="file-label">
      <input class="file-input" type="file" (change)="startUpload($event.target.files)" hidden>
      <span class="file-cta btn btn-info m-btn m-btn--air m-btn--custom">
            <span class="file-label">
              UPLOAD IMAGE
            </span>
          </span>
    </label>
  </div>
  <p class="mt-4 mb-0">Or drag it in</p>
</div>
<div class="custom-file" *ngIf="template == 'input-group'" [ngClass]="{'is-invalid': inputClass=='is-invalid' && !completeFileUrl}">
  <img class="custom-file-image" alt="image preview" [src]="completeFileUrl" *ngIf="completeFileUrl && !hidePreview" height="36px" width="auto"/>

  <input type="file" class="custom-file-input" id="id-file" name="id-file" accept=".jpeg,.jpg,.png"
         (change)="startUpload($event.target.files)">
  <label class="custom-file-label text-truncate" for="id-file">{{ completeFileUrl || 'Select a file' }}</label>
</div>
<div *ngIf="!completeFileUrl">
  <div class="file-upload-progress" *ngIf="percentage | async as pct">
    <progress class="progress is-info" [value]="pct" max="100"></progress>
    {{ pct | number }}%
  </div>
  <div *ngIf="snapshot | async as snap">
    {{ snap.bytesTransferred | fileSize : false }} of {{ snap.totalBytes | fileSize : false }}
  </div>
</div>
<div class="alert alert-danger" *ngIf="errorMsg">
  {{errorMsg}}
</div>
<div class="img-preview d-flex" *ngIf="completeFileUrl && template != 'input-group' && imagePosition !='top' && !hidePreview" [style.min-height]="viewHeight ? (viewHeight)+'px' : null" [style.max-height]="viewHeight ? (viewHeight+30)+'px' : null">
  <img *ngIf="mediaType!=='video'" [src]="completeFileUrl" alt="your uploaded image" [class]="imageClass + ' img-fluid m-auto d-block'" [style.max-height]="viewHeight ? (viewHeight)+'px' : null">
  <video controls *ngIf="mediaType==='video'" [class]="imageClass + ' img-fluid m-auto d-block'" [style.max-height]="viewHeight ? (viewHeight)+'px' : null">
    <source [src]="completeFileUrl" type="video/mp4">
    Your browser does not support the video tag.
  </video>
  <button type="button" class="btn btn-primary" (click)="delete(completeFileUrl)"><i class="fas fa-trash-alt"></i></button>
</div>
