import {Component, ComponentFactoryResolver, OnDestroy, ViewChild, ViewContainerRef} from '@angular/core';
import { User} from '../../_models';
import {NavigationEnd, Router} from '@angular/router';
import {LoaderService} from '../loader/loader.service';
import {AlertService, IsUserLoginService, SendinblueService} from '../../_services';
import {AlertComponent} from '../../_directives';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})


export class FooterComponent implements OnDestroy {
  currentUser: User = {id: null};
  hideFooter = false;
  isLoading = false;
  userEmail: string;
  @ViewChild('alertMessage', { read: ViewContainerRef, static: true }) alertMessage: ViewContainerRef;
  subscription: Subscription;
  constructor(private router: Router,
              private loaderService: LoaderService,
              private cfr: ComponentFactoryResolver,
              private alertService: AlertService,
              private emailSIBService: SendinblueService,
              private isUserLoginService: IsUserLoginService) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });

    const localData = localStorage.getItem('currentUser');
    this.currentUser = localData && localData !== 'undefined' ? JSON.parse(localData) : {id: null};
    this.userEmail = this.currentUser.email;
    this.subscription = this.isUserLoginService.getUser().subscribe(user => {
      this.currentUser = user;
      this.userEmail = user.email;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  subscribe() {
    this.isLoading = true;
    this.loaderService.show();
    this.emailSIBService.addUserTolist('early-bird', this.userEmail).then((res: any) => {
      if (res.code === 'already-in-list' || res.code === 'update-contact' || res.code === 'create-contact') {
        this.showAlert('alertMessage');
        this.alertService.success(res.message);
      } else {
        this.showAlert('alertMessage');
        this.alertService.error(res.message || 'Error...');
      }
      this.loaderService.hide();
      this.isLoading = false;
    });
  }

  showAlert(target) {
    this[target].clear();
    const factory = this.cfr.resolveComponentFactory(AlertComponent);
    const ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

}
