import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {AuthenticationService, IsUserLoginService} from './authentication.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router, private isUserLoginService: IsUserLoginService) {}
  private checkUser(): Promise<any> {
    return new Promise((resolve) => {
      this.auth.isLogin()
        .then((user: any): any => {
          if (user && user.id) {
            if (!user.emailVerified) {
              this.isUserLoginService.onLogout();
              localStorage.removeItem('currentUser');
              localStorage.removeItem('currentBusiness');
              resolve(false);
              return false;
            }
            let localData = localStorage.getItem('currentUser');
            const currentUser = localData && localData !== 'undefined' ? JSON.parse(localData) : {};

            localData = localStorage.getItem('currentBusiness');
            const currentBusiness = localData && localData !== 'undefined' ? JSON.parse(localData) : {};
            if (currentUser.id && currentUser.businessId && currentBusiness.id) {
              this.isUserLoginService.onLogin(currentUser);
              resolve(true);
            } else {
              return this.auth.pullUserData(user.id).then((res: any) => {
                if (res) {
                  this.isUserLoginService.onLogin(res.user);
                  localStorage.setItem('currentUser', JSON.stringify(res.user));
                  localStorage.setItem('currentBusiness', JSON.stringify(res.business));
                }
                resolve(true);
              });
            }
          } else {
            return this.auth.logout().then(() => {
              resolve(false);
            });
          }
        });
    });
  }
  canActivate(): any {
    return new Promise((resolve) => {
      return this.checkUser()
        .then((res: any) => {
          if (!res) {
            return this.router.navigate(['/']).then(() => {
              resolve(false);
            });
          } else {
             resolve(true);
          }
        });
    });
  }
}
