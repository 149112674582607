<header class="d-flex position-relative">
  <ngb-carousel #carousel class="position-absolute" *ngIf="images" [activeId]="'ngb-slide-0'" [showNavigationArrows]="false" [interval]="0" [showNavigationIndicators]="false">
    <ng-template  ngbSlide *ngFor="let image of images">
      <div [style.background-image]="'url(' + image + ')'" class="image-slider"></div>
    </ng-template>
  </ngb-carousel>
  <div class="container d-flex position-absolute" style="top: 0;bottom: 0;left: 0;right: 0;">
    <div class="text-center pb-5 m-auto pb-xs-150">
      <h1 class="mb-5" *ngIf="validationEmail && validationEmail.mode === 'verifyEmailSuccess'">
        Welcome to Luxify
      </h1>
      <h1 class="mb-5" *ngIf="!validationEmail || validationEmail.mode !== 'verifyEmailSuccess'">
        Luxify,<br/>global marketplace for luxury goods sellers
      </h1>
      <div class="row justify-content-center" *ngIf="user && user.id">
        <div class="col-md-6 col-lg-4 mb-3">
          <a  [routerLink]="['/list-product']" class="btn btn-primary text-uppercase  btn-block" >List a new product</a>
        </div>
        <div class="col-md-6 col-lg-4 mb-3">
          <a  [routerLink]="['/list-product']" [queryParams]="{type: 'api'}" class="btn btn-primary text-uppercase btn-block" *ngIf="user && user.id">connect my inventory</a>
        </div>
      </div>

      <div class="row justify-content-center" *ngIf="!user || !user.id">
        <div class="col-md-6 col-lg-4 mb-3">
          <button type="button" class="btn btn-primary text-uppercase btn-block" data-toggle="modal" data-target="#SignUpModal" >CREATE AN ACCOUNT</button>
        </div>
        <div class="col-md-6 col-lg-4 mb-3">
          <button type="button" class="btn btn-primary text-uppercase btn-block" data-toggle="modal" data-target="#SignInModal" >LOG IN</button>
        </div>
      </div>

      <ng-template #alertMessage></ng-template>
    </div>
  </div>
  <div class="container-fluid mt-auto">

    <div class="row row-footer pt-5">
      <div class="col-6 col-md-3 text-left align-self-end">
        <img src="../../assets/images/icons/authenticity_icon.svg" style="max-width: 40px" class="img-fluid mr-auto d-block mt-4" alt="Distributors and Resellers">
        <p class="small white-color mt-4">AUTHENTICITY GUARANTEED WITH BLOCKCHAIN TECHNOLOGY</p>
        <img src="../../assets/images/icons/trustlux.png" class="img-fluid mr-auto d-block mt-3" alt="Distributors and Resellers">
      </div>
      <!--<div class="col-6 text-center align-self-start">
        <img src="../../assets/images/icons/play_icon.png" class="img-fluid mx-auto d-block mt-3" alt="Distributors and Resellers">
      </div>-->
      <div class="col-6 col-md-3 offset-md-6 text-right align-self-end ">
        <ul class="list-unstyled text-uppercase mb-0 custom-slider-dots mr-3">
          <li [ngClass]="{'active': sliderActive == 'ngb-slide-0'}" (click)="switchSlider('ngb-slide-0')">Designer Handbags</li>
          <li [ngClass]="{'active': sliderActive == 'ngb-slide-1'}" (click)="switchSlider('ngb-slide-1')">Luxury Watches</li>
          <li [ngClass]="{'active': sliderActive == 'ngb-slide-2'}" (click)="switchSlider('ngb-slide-2')">Jewelry</li>
        </ul>
      </div>
    </div>
    <div class="text-center pb-4">
      <img src="../../assets/images/icons/arrow-down.svg" alt="next"/>
    </div>
  </div>
</header>

<section class="py-6 how-it-works-section text-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <h2>Why sell on Luxify</h2>
        <p class="my-5">Luxify is a unique marketplace for buying and selling luxury goods from the top luxury brands, authenticated with blockchain technology.</p>
        <div class="row">
          <div class="col-sm-6 col-md-3 mb-5 position-relative">
            <img src="../../assets/images/icons/visability_icon.svg" class="img-fluid mx-auto d-block mt-4" alt="Distributors and Resellers">
            <h5 class="text-uppercase mt-3 mb-0 font-weight-bold">Increase<br/>visibility</h5>
          </div>
          <div class="col-sm-6 col-md-3 mb-5 position-relative">
            <img src="../../assets/images/icons/authenticity_icon.svg" class="img-fluid mx-auto d-block mt-4" alt="Distributors and Resellers">
            <h5 class="text-uppercase mt-3 mb-0 font-weight-bold">Authenticity<br/>guarantee</h5>
          </div>
          <div class="col-sm-6 col-md-3 mb-5 position-relative">
            <img src="../../assets/images/icons/delivery_icon.svg" class="img-fluid mx-auto d-block mt-4" alt="Distributors and Resellers">
            <h5 class="text-uppercase mt-3 mb-0 font-weight-bold">Fast & free<br/>delivery</h5>
          </div>
          <div class="col-sm-6 col-md-3 mb-5 position-relative">
            <img src="../../assets/images/icons/search_dollar_icon.svg" class="img-fluid mx-auto d-block mt-4" alt="Distributors and Resellers">
            <h5 class="text-uppercase mt-3 mb-0 font-weight-bold">Price<br/>monitoring</h5>
          </div>
          <div class="col-sm-6 col-md-3 mb-5 position-relative">
            <img src="../../assets/images/icons/marketing_icon.svg" class="img-fluid mx-auto d-block mt-4" alt="Distributors and Resellers">
            <h5 class="text-uppercase mt-3 mb-0 font-weight-bold">marketing<br/>included</h5>
          </div>
          <div class="col-sm-6 col-md-3 mb-5 position-relative">
            <img src="../../assets/images/icons/growstock_icon.svg" class="img-fluid mx-auto d-block mt-4" alt="Distributors and Resellers">
            <h5 class="text-uppercase mt-3 mb-0 font-weight-bold">INVENTORY<br/>MANAGEMENT</h5>
          </div>
          <div class="col-sm-6 col-md-3 mb-5 position-relative">
            <img src="../../assets/images/icons/newaudience_icon.svg" class="img-fluid mx-auto d-block mt-4" alt="Distributors and Resellers">
            <h5 class="text-uppercase mt-3 mb-0 font-weight-bold">Reach new<br/>audiences</h5>
          </div>
          <div class="col-sm-6 col-md-3 mb-5 position-relative">
            <img src="../../assets/images/icons/sales_guaranteed_icon.svg" class="img-fluid mx-auto d-block mt-4" alt="Distributors and Resellers">
            <h5 class="text-uppercase mt-3 mb-0 font-weight-bold">Sales<br/>guarantee</h5>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>


<section class="py-6 selling-section">
  <div class="container text-center">
    <h2>Selling on Luxify is simple</h2>
    <div class="sub-container">
      <div class="row row-step-1 my-5 my-md-8">
        <div class="col-md-6 offset-xl-1 col-xl-4  col-lg-5 text-center">
          <p class="step-number">1</p>
          <p class="text-uppercase my-5 mb-0 font-weight-bold">LIST YOUR luxury product(s)</p>
          <p class="small">
            Choose between our 3 categories:<br/><br/>
            Luxury Handbags<br/>
            Luxury Watches<br/>
            Top Jewelry<br/><br/>
            Then list your item.
          </p>
          <div>
            <a  [routerLink]="['/list-product']" class="btn btn-primary text-uppercase px-5" *ngIf="user && user.id">START SELLING</a>
            <button type="button" class="btn btn-primary text-uppercase px-5 mb-3"  *ngIf="showLoginBtn && (!user || !user.id) " data-toggle="modal" data-target="#SignInModal" >LOG IN</button>
            <button type="button" class="btn btn-primary text-uppercase px-5 mb-3"  *ngIf="!showLoginBtn && (!user || !user.id) " data-toggle="modal" data-target="#SignUpModal" >CREATE AN ACCOUNT</button>
          </div>
        </div>
        <div class="mt-4 mt-md-0 col-md-6  offset-lg-0 col-lg-5 align-self-center">
          <img src="../../assets/images/temps/landing_page_1.jpg" class="img-fluid mx-auto d-block" alt="Distributors and Resellers">
        </div>
      </div>
      <div class="row row-step-2 my-5 my-md-8">
        <div class="mb-4 my-md-0 col-md-6 offset-lg-2  col-lg-5 align-self-center">
          <img src="../../assets/images/temps/landing_page_2.jpg" class="img-fluid mx-auto d-block" alt="Distributors and Resellers">
        </div>
        <div class="col-md-6 col-lg-5 offset-xl-1 col-xl-4 text-center">
          <p class="step-number">2</p>
          <p class="text-uppercase my-5 mb-0 font-weight-bold">RECEIVE A DEVICE TO AUTHENTICATE YOUR ITEM</p>
          <p class="small">
            Luxify Marketplace is unique and allows its sellers to authenticate their luxury items through technology services. Payments and deliveries/shipments are fully automated.
          </p>
          <div>
            <a  [routerLink]="['/list-product']" class="btn btn-primary text-uppercase px-5" *ngIf="user && user.id">START SELLING</a>
            <button type="button" class="btn btn-primary text-uppercase px-5 mb-3"  *ngIf="showLoginBtn && (!user || !user.id) " data-toggle="modal" data-target="#SignInModal" >LOG IN</button>
            <button type="button" class="btn btn-primary text-uppercase px-5 mb-3"  *ngIf="!showLoginBtn && (!user || !user.id) " data-toggle="modal" data-target="#SignUpModal" >CREATE AN ACCOUNT</button>
          </div>
        </div>

      </div>
      <div class="row row-step-3 my-5 my-md-8">
        <div class="col-md-6 offset-xl-1 col-xl-4  col-lg-5 text-center">
          <p class="step-number">3</p>

          <p class="text-uppercase my-5 mb-0 font-weight-bold">SEND & RECEIVE PAYMENT</p>
          <p class="small">
            Once we have a buyer for your product, we will pay you directly and automatically into your bank account.
            <br/><br/>
            Ship the product as soon as possible.
            <br/><br/>
            Once the customer has received its product, your 'pending' payment will be finalised.
          </p>
          <div>
            <a [routerLink]="['/list-product']" class="btn btn-primary text-uppercase px-5 d-none d-md-inline-block" *ngIf="user && user.id">START SELLING</a>
            <button type="button" class="btn btn-primary text-uppercase px-5 d-none d-md-inline-block"  *ngIf="showLoginBtn && (!user || !user.id) " data-toggle="modal" data-target="#SignInModal" >LOG IN</button>
            <button type="button" class="btn btn-primary text-uppercase px-5 d-none d-md-inline-block"  *ngIf="!showLoginBtn && (!user || !user.id) " data-toggle="modal" data-target="#SignUpModal" >CREATE AN ACCOUNT</button>


            <a [routerLink]="['/list-product']" class="btn btn-secondary text-uppercase px-5 d-inline-block d-md-none" *ngIf="user && user.id">START SELLING</a>
            <button type="button" class="btn btn-secondarybtn-secondary text-uppercase px-5 d-inline-block d-md-none"  *ngIf="showLoginBtn && (!user || !user.id) " data-toggle="modal" data-target="#SignInModal" >LOG IN</button>
            <button type="button" class="btn btn-secondary text-uppercase px-5 d-inline-block d-md-none"  *ngIf="!showLoginBtn && (!user || !user.id) " data-toggle="modal" data-target="#SignUpModal" >CREATE AN ACCOUNT</button>

          </div>
        </div>
        <div class="mt-4 mt-md-0 col-md-6  offset-lg-0 col-lg-5 align-self-center">
          <img src="../../assets/images/temps/landing_page_3.jpg" class="img-fluid mx-auto d-block" alt="Distributors and Resellers">
        </div>
      </div>
    </div>
  </div>
</section>



<div class="modal fade" id="resetPasswordModal" tabindex="-1" role="dialog" aria-labelledby="resetPasswordModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header pb-0">
        <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body pt-0" *ngIf="validationEmail">
        <h3 class="text-capitalize mb-4">{{validationEmail.actionSuccess ? 'Reset your password' : 'Password changed'}}</h3>
        <p>{{validationEmail.message}}</p>
        <ng-template #alertResetPasswordMessage></ng-template>
        <form (ngSubmit)="resetPassword()" #resetPasswordForm="ngForm" *ngIf="!validationEmail.actionSuccess">
          <div class="form-group">
            <input type="password" class="form-control" [(ngModel)]="validationEmail.newPassword" name="newPassword" id="newPassword"
                   placeholder="New password" required>
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-secondary btn-block" [disabled]="!resetPasswordForm.form.valid || isLoading">SAVE</button>
          </div>
        </form>
        <div *ngIf="validationEmail.actionSuccess" class="text-center mt-3">
          <button type="button" class="btn btn-secondary px-5" data-dismiss="modal" aria-label="Close">
            NEXT
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
