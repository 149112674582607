import {
  Component, Input, Output, EventEmitter, OnInit
} from '@angular/core';

import {ProductService, SendinblueService} from '../../../app/_services';
import {Product, Variant, Business, User} from '../../../app/_models';
import {LoaderService} from '../../../app/shared/loader/loader.service';

@Component({
  selector: 'app-list-product-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],

})
export class ListProductDetailsComponent implements OnInit {
  modalSelectedItem: any = {};

  needToUpdate = false;
  isLoading = false;
  modalType: string;
  modalLabel: string;
  modalLabelPlural: string;
  modalListFilter: any[] = [];
  suggest: any = {};

  @Input('years') years: number[] = [];
  @Input('product') product: Product = {};
  @Input('business') business: Business = {};
  @Input('variant') variant: Variant = {};
  @Input('colors-list') colorsList: any[] = [];
  @Input('materials-list') materialsList: any[] = [];
  @Input('data-structure') dataStructure: any[] = [];
  @Output() stepReturn: EventEmitter<{ step: string, product: Product }> = new EventEmitter<{ step: string, product: Product }>();
  categoriesList: any[] = [];
  subCategoriesList: any[] = [];
  brandsList: any[] = [];
  modelsList: any[] = [];
  constructor(private loaderService: LoaderService,
              private sendinblueService: SendinblueService,
              private productService: ProductService) {
  }

  ngOnInit() {
    this.categoriesList = Object.keys(this.dataStructure);
    if (this.product.category) {
      this.subCategoriesList = Object.keys(this.dataStructure[this.product.category]);
    } else if (this.product.subCategory) {
      this.brandsList = Object.keys(this.dataStructure[this.product.category][this.product.subCategory]);
    } else if (this.product.brand) {
      this.modelsList = Object.keys(this.dataStructure[this.product.category][this.product.subCategory][this.product.brand]);
    }
  }

  getList(type) {
    switch (type) {
      case 'sub-category':
        this.subCategoriesList = Object.keys(this.dataStructure[this.product.category]);
        this.brandsList = [];
        this.modelsList = [];
        break;
      case 'brand':
        this.brandsList = Object.keys(this.dataStructure[this.product.category][this.product.subCategory]);
        this.modelsList = [];
        break;
      case 'model':
        this.modelsList = this.dataStructure[this.product.category][this.product.subCategory][this.product.brand];
        break;
    }
  }

  updateProductDetails() {
    if (!this.needToUpdate) {
      return this.stepReturn.emit({step: 'medias', product : null});
    }
    this.isLoading = true;
    this.loaderService.show();
    this.product.variants = [this.variant];
    if (this.product.id) {
      this.productService.update(this.product)
        .then(() => {
          this.isLoading = false;
          this.loaderService.hide();
          return this.stepReturn.emit({step: 'medias', product : this.product});
        });
    } else {
      this.product.businessId = this.business.id;
      this.product.businessName = this.business.name;
      this.productService.create(this.product)
        .then(() => {
          this.isLoading = false;
          this.loaderService.hide();
          return this.stepReturn.emit({step: 'medias', product : this.product});
        });
    }
  }

  onSearchChange(event: any) {
    const searchValue = event.target.value;
    if (this.modalType === 'brand') {
      this.modalListFilter = this.brandsList.filter(s => s.includes(searchValue));
    } else if (this.modalType === 'sub-category') {
      this.modalListFilter = this.subCategoriesList.filter(s => s.includes(searchValue));
    } else if (this.modalType === 'model') {
      this.modalListFilter = this.modelsList.filter(s => s.model.includes(searchValue));
    } else if (this.modalType === 'material') {
      this.modalListFilter = this.materialsList.filter(s => s.includes(searchValue));
    } else if (this.modalType === 'color') {
      this.modalListFilter = this.colorsList.filter(s => s.includes(searchValue));
    }
  }

  selectOnModal(item: any) {
    this.modalSelectedItem = item;
    if (this.modalType === 'brand') {
      this.product.brand = item;
      this.product.model = null;
      $('#selectFromListModal').modal('hide');
    } else if (this.modalType === 'sub-category') {
      this.product.subCategory = item;
      this.product.brand = null;
      this.product.model = null;
      $('#selectFromListModal').modal('hide');
    } else if (this.modalType === 'model') {
      this.product.model = item.model;
    } else if (this.modalType === 'material') {
      this.variant.material = item;
      $('#selectFromListModal').modal('hide');
    } else if (this.modalType === 'color') {
      this.variant.color = item;
      $('#selectFromListModal').modal('hide');
    }
    this.needToUpdate = true;
  }

  suggestOnModal() {
    this.suggest = {type: this.modalLabel};
    $('#selectFromListModal').modal('hide');
    $('#selectFromListPreviewModal').modal('hide');
    $('#suggestionModal').modal('show');
  }
  sendNewSuggestion() {
    this.isLoading = true;
    this.loaderService.show();
    this.sendinblueService.sendNewSuggestion(this.product, this.suggest)
      .then(() => {
        this.isLoading = false;
        this.loaderService.hide();
        $('#suggestionModal').modal('hide');
      })
      .catch((error) => {
        this.loaderService.hide();
        this.isLoading = false;
        this.suggest.error = 'Unable to send new suggestion';
      });
  }

  openModal(type: string) {
    if (type === 'brand' && this.product.subCategory) {
      this.getList('brand');
      $('#selectFromListModal').modal('show');
      this.modalType = type;
      this.modalLabel = 'Brand';
      this.modalLabelPlural = 'Brands';
      this.modalListFilter = this.brandsList;
      const indexOf = this.modalListFilter.indexOf(this.product.brand);
      if (this.product.brand && indexOf >= 0) { this.modalSelectedItem = this.modalListFilter[indexOf]; }
    } else if (type === 'sub-category' && this.product.category) {
      this.getList('sub-category');
      $('#selectFromListModal').modal('show');
      this.modalType = type;
      this.modalLabel = 'Sub category';
      this.modalLabelPlural = 'Sub categories';
      this.modalListFilter = this.subCategoriesList;
      const indexOf = this.modalListFilter.indexOf(this.product.subCategory);
      if (this.product.subCategory && indexOf >= 0) { this.modalSelectedItem = this.modalListFilter[indexOf]; }
    } else if (type === 'model' && this.product.brand) {
      this.getList('model');
      this.modalType = type;
      this.modalLabel = 'Model';
      this.modalLabelPlural = 'Models';
      this.modalListFilter = this.modelsList;
      const indexOf = this.modalListFilter.findIndex(x => x.model === this.product.model);
      if (
        this.product.model && indexOf >= 0) { this.modalSelectedItem = this.modalListFilter[indexOf];
      } else {
        this.modalSelectedItem = this.modalListFilter[0];
      }
      $('#selectFromListPreviewModal').modal('show');
    } else if (type === 'material') {
      $('#selectFromListModal').modal('show');
      this.modalType = type;
      this.modalLabel = 'Material';
      this.modalLabelPlural = 'Materials';
      this.modalListFilter = this.materialsList;
      const indexOf = this.modalListFilter.indexOf(this.variant.material);
      if (this.variant.material && indexOf >= 0) { this.modalSelectedItem = this.modalListFilter[indexOf]; }
    } else if (type === 'color') {
      $('#selectFromListModal').modal('show');
      this.modalType = type;
      this.modalLabel = 'Color';
      this.modalLabelPlural = 'Colors';
      this.modalListFilter = this.colorsList;
      const indexOf = this.modalListFilter.indexOf(this.variant.color);
      if (this.variant.color && indexOf >= 0) { this.modalSelectedItem = this.modalListFilter[indexOf]; }
    }
  }

  onChangeCategory() {
    this.needToUpdate = true;
    this.getList('sub-category');
    this.product.subCategory = null;
    this.product.brand = null;
    this.product.model = null;
  }
  onChange() {
    this.needToUpdate = true;
  }
}
