import {
  Component,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  ViewContainerRef,
  ComponentFactoryResolver,
  EventEmitter,
  Output
} from '@angular/core';
import * as myGlobals from '../../../globals';
import {PaymentService, AlertService} from '../../_services';

import {Product} from '../../_models';
import {LoaderService} from '../loader/loader.service';
import {AlertComponent} from '../../_directives';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})

export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() quantity: number;
  @Input() product: Product;
  @Input() paymentType: string;
  @Output() paymentResult: EventEmitter<any> = new EventEmitter<any>();


  amount: number = null;
  amountWithFee: number = null;

  elements: any = null;

  @ViewChild('cardInfo', { static: true }) cardInfo: ElementRef;
  userCardDetails: any = {};
  card: any;
  cardHandler = this.onChange.bind(this);
  currency = 'eur';

  error: string;

  fileUploadFolder: string;

  @ViewChild('alertPayment', { read: ViewContainerRef, static: true }) alertPayment: ViewContainerRef;
  isLoading = false;


  constructor(private paymentService: PaymentService,
              private cd: ChangeDetectorRef,
              private loaderService: LoaderService,
              private cfr: ComponentFactoryResolver,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.amountWithFee = 0;
    if (this.quantity) {
      this.amount = this.quantity * this.product.variants[0].price;
      this.amountWithFee = this.amount; // this.getAmountWithChargeFee(this.amount);
    }
  }

  ngAfterViewInit() {
    this.elements = this.paymentService.stripe.elements();
    this.card = this.elements.create('card', {hidePostalCode: true});
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange(event) {
    if (event.error) {
      this.error = event.error.message;
    } else {
      this.error = null;
    }
    // Display bank name corresponding to IBAN, if available.
    this.cd.detectChanges();
  }

  // async onSubmitPayment(form: NgForm) {}
  async onSubmitPayment() {
    this.loaderService.show();
    this.isLoading = true;
    const {token, error} = await this.paymentService.stripe.createToken(this.card, this.userCardDetails);
    if (error) {
      this.error = error.message;
      console.error('Something is wrong:', error);
      this.loaderService.hide();
    } else {
      return this.paymentService
        .processPayment(token, this.amount, this.quantity, this.product.id )
        .then((res: any) => {
          if (res.success) {
            this.paymentResult.emit(res);
            this.showAlert('alertPayment');
            this.alertService.success(res.message);
          } else {
            this.showAlert('alertPayment');
            this.alertService.error(res.message);
          }
          this.loaderService.hide();
          this.isLoading = false;
        });
    }
  }

  showAlert(target) {
    this[target].clear();
    const factory = this.cfr.resolveComponentFactory(AlertComponent);
    const ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }
}

