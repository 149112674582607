import {Component, HostListener, OnDestroy} from '@angular/core';
import {User} from '../../_models';
import {NavigationEnd, Router} from '@angular/router';
import {AuthenticationService, IsUserLoginService} from '../../_services';
import {Location} from '@angular/common';
import {LoaderService} from '../loader/loader.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnDestroy {

  user: User = {};
  isLoading = false;
  isHidden = false;
  isTransparent = true;
  showHeaderMessage = false;
  subscription: Subscription;

  constructor(private router: Router,
              private location: Location,
              private authService: AuthenticationService,
              private loaderService: LoaderService,
              private isUserLoginService: IsUserLoginService) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (evt.url.indexOf('/terms-of-service') === 0 || evt.url.indexOf('/privacy-policy') === 0) {
        this.isTransparent = false;
        this.showHeaderMessage = false;
      } else if (
        evt.url.indexOf('/account') !== 0
        && this.router.url.indexOf('/list-product') !== 0) {
        const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.isTransparent = offset < 30;
        this.showHeaderMessage = false;
      } else {
        this.isTransparent = false;
        this.showHeaderMessage = true;
      }
      if (evt.url.indexOf('/product/preview/') !== 0) {
        this.isHidden = false;
      } else {
        this.isHidden = true;
      }
    });
    const localData = localStorage.getItem('currentUser');
    this.user = localData && localData !== 'undefined' ? JSON.parse(localData) : {id: null};
    this.subscription = this.isUserLoginService.getUser().subscribe(user => {this.user = user;});
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  logout() {
    this.loaderService.show();
    this.isLoading = true;
    this.authService.logout()
      .then(
        () => {
          return this.router.navigate(['/']);
        }, error => {console.error(error)})
      .then(() => {
        this.loaderService.hide();
        this.isLoading = false;
      });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.router.url.indexOf('/terms-of-service') !== 0
      && this.router.url.indexOf('/privacy-policy') !== 0
      && this.router.url.indexOf('/account') !== 0
      && this.router.url.indexOf('/list-product') !== 0) {
      const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.isTransparent = offset < 30;
    } else {
      this.isTransparent = false;
    }
  }

}
