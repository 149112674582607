import {
  Component
} from '@angular/core';

import {BusinessService, PaymentService, UserService} from '../../app/_services';
import {Business, BusinessPayout, User} from '../../app/_models';
import {LoaderService} from '../../app/shared/loader/loader.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],

})
export class AccountComponent {
  user: User;
  business: Business = {};
  bankDetails: BusinessPayout = {};
  show = {
    profile: false,
    products: false,
    sales: false,
    payments: false,
    help: false,
  };
  loadedData = false;

  accountLink: {
    isVerified?: boolean,
    url?: string,
    disabled_reason?: string
  } = {};
  updateAccountReturn: string;

  constructor(private loaderService: LoaderService,
              private router: Router,
              private route: ActivatedRoute,
              private paymentService: PaymentService,
              private businessService: BusinessService,
              private userService: UserService) {
    const localData = localStorage.getItem('currentUser');
    const localUser = localData && localData !== 'undefined' ? JSON.parse(localData) : {};
    if (!localUser.id) {
      this.router.navigate(['/']).then();
      return;
    } else {
      this.loaderService.show();
      this.getUser(localUser.id)
        .then((businessId) => {
          return this.getBusiness(businessId);
        })
        .then((businessId: string) => {
          return this.getPayoutDetails(businessId);
        })
        .then((stripeCustomID: string) => {
          return this.getAccountLink(stripeCustomID);
        })
        .finally(() => {
          this.loadedData = true;
          this.route.params.subscribe(params => {
            this.updateAccountReturn = params.status;
            if (params.tab) {
              this.switchView(params.tab);
              this.loaderService.hide();
            } else {
              this.switchView('profile');
              this.loaderService.hide();
            }
          });
        })
        .catch(() => {
          this.router.navigate(['/404']).then();
        });
    }
  }

  getUser(userId) {
    return new Promise((resolve, reject) => {
      this.userService.get(userId)
        .subscribe((user: User) => {
          this.user = user;
          resolve(user.businessId);
        }, () => {
          reject(false);
        });
    });
  }
  getBusiness(businessId) {
    return new Promise((resolve, reject) => {
      if (businessId) {
        this.businessService.get(businessId)
          .subscribe((business: Business) => {
            this.business = business;
            resolve(business.id);
          }, () => {
            reject(false);
          });
      } else {
        resolve(null);
      }
    });
  }

  getPayoutDetails(businessId) {
    return new Promise((resolve, reject) => {
      this.businessService.getPayoutDetails(businessId)
        .subscribe((bankDetails: BusinessPayout) => {
          this.bankDetails = bankDetails;
          const stripeCustomID = bankDetails ? bankDetails.stripeCustomID : null;
          resolve(stripeCustomID);
        });
    });
  }

  async getAccountLink(stripeCustomID: string) {
    const accountLink = await this.paymentService.getAccountLink(stripeCustomID);
    if (accountLink.success && accountLink.data) {
      this.accountLink = accountLink.data;
    }
    return true;
  }

  switchView(step) {
    this.show = {
      profile: false,
      products: false,
      sales: false,
      payments: false,
      help: false,
    };
    this.show[step] = true;
  }
}
