<section class="bg-gray-light" *ngIf="customAccount">
  <div class="container pt-5 py-md-5">
    <form class="" (ngSubmit)="updatePaymentDetails()" #paymentForm="ngForm">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <h2 class="mt-3 mb-5 text-center">Get paid</h2>
          <p class="text-center ">For Luxify to pay you automatically after each sale, please provide your bank account
            details.
          </p>
          <p class="primary-color text-center mb-5 font-italic">
            You may choose to skip this for now and provide this information when its convinient for you, from your account settings.
          </p>
          <p class="small bottom-20">Luxify Direct Payment™ can process
            <br class="d-block d-sm-none">
            <br class="d-block d-sm-none">
            <img class="pl-3 payment-type-icon" src="../../../assets/images/icons/visa.png" alt="visa">
            <img class="pl-3 payment-type-icon" src="../../../assets/images/icons/master-card.png" alt="master card">
            <img class="pl-3 payment-type-icon" src="../../../assets/images/icons/maestro.png" alt="maestro">
            <img class="pl-3 payment-type-icon" src="../../../assets/images/icons/american-express.png" alt="american express">
            <img class="pl-3 payment-type-icon" src="../../../assets/images/icons/stripe.png" alt="stripe">
          </p>

          <div class="alert alert-warning my-3" role="alert" *ngIf="!canSubmitCustomAccount">
            Your business profile is not fully completed. We need your bank details, please complete all required fields plus : <br/>
            <span *ngIf="business.businessType === 'company' && !business.taxId">
              - <button type="button" (click)="goBackTo('details')" class="edit-step btn btn-link p-0">Your business registration number</button><br/>
            </span>
              <span *ngIf="business.businessType === 'company' && !business.website">
                - <button type="button" (click)="goBackTo('details')" class="edit-step btn btn-link p-0">Your business website</button><br/>
            </span>
              <span *ngIf="!business.representativeIdentityProof">
                - <button type="button" (click)="goBackTo('representative')" class="edit-step btn btn-link p-0">Your government-issued ID</button><br/>
            </span>
          </div>

          <div *ngIf="customAccount.address">
            <div class="form-group">
              <label class="col-form-label text-uppercase" for="country">Bank Country</label>
              <input type="text" class="form-control form-control-lg" [value]="customAccount.address.country" id="country" readonly>
              <p class="small">*Your bank needs to be in the same country as your business</p>
            </div>
            <div class="form-group">
              <label class="col-form-label text-uppercase" for="currency">Currency</label>
              <select class="form-control form-control-lg" [(ngModel)]="selectedCurrency"
                      (ngModelChange)="selectCurrency($event)" name="currency" id="currency" [required]="needToUpdate">
                <option *ngFor="let item of currencies" [ngValue]="item">{{item.name}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="col-form-label" for="bankAccountName">ACCOUNT OWNER NAME</label>
              <input type="text" class="form-control form-control-lg" id="bankAccountName"
                     placeholder="Enter your account owner name" [(ngModel)]="customAccount.bankAccountName"
                     (change)="onChange()" name="bankAccountName" [required]="needToUpdate">
            </div>
            <div class="form-group">
              <label class="col-form-label" for="bankAccountNumber">ACCOUNT NUMBER</label>
              <input type="text" class="form-control form-control-lg" id="bankAccountNumber"
                     placeholder="{{customAccount.last4 ? customAccount.last4 : 'Enter your account number'}}"
                     [(ngModel)]="customAccount.bankAccountNumber" (change)="onChange()" name="bankAccountNumber" [required]="needToUpdate">
            </div>
            <div class="form-group">
              <label class="col-form-label" for="bankNumber">ROUTING NUMBER</label>
              <input type="text" class="form-control form-control-lg" id="bankNumber"
                     placeholder="Enter your routing number" [(ngModel)]="customAccount.bankNumber"
                     (change)="onChange()" name="bankNumber" [required]="needToUpdate">
              <p class="small text-right mb-0">*Example: 110000</p>
            </div>
            <div class="form-group">
              <label class="col-form-label" for="bankName">BANK NAME</label>
              <input type="text" class="form-control form-control-lg" id="bankName" placeholder="Enter your bank name"
                     [(ngModel)]="customAccount.bankName" (change)="onChange()" name="bankName" [required]="needToUpdate">
            </div>
          </div>
          <div class="text-center">
            <ng-template #alertMessage></ng-template>
          </div>
          <p class="mb-4 mt-6">By clicking, you agree to <a [routerLink]="['/terms-of-service']">our terms</a> and the <a
            href="https://stripe.com/connect-account/legal" target="_blank">Stripe Connected Account Agreement</a>.</p>
          <div class="row">
            <div class="col-6 text-left">
              <button type="button" (click)="goBackTo('shipping')" class="btn btn-outline-primary">GO BACK TO STEP 4</button>
            </div>
            <div class="col-6 text-right">
              <button type="submit" class="btn btn-primary" [disabled]="!paymentForm.form.valid">GO TO SUMMARY</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<!--
<section class="bg-gray-light">
  <div class="container pb-5 py-sm-5">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="bg-gray p-4 p-sm-5 mb-4">
          <p class="small m-0">
            For this online service we charge a <b>flat 5% processing fee</b> which include full access to Luxify
            platform at no cost, live availabilities and instant booking capabilities,
            local and international payment fees paid (eg. Paypal charge 3% just for an online payment) and a coming soon
            insurance cover.
            This is only for online payments through your brand.<br>
            If you entered a booking offline or paid in <b>cash it's a 0% processing fee</b>.<br>
            If the booking comes from our apps or Travel Partner Network™, then the commission agreed is applied (by
            default 15% on our Marketplace if you don't have a third party booking system integrated).
          </p>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="bg-gray p-4 p-sm-5">
          <h4 class="mt-0 mb-3 ">What is Luxify Direct Payment™?</h4>
          <p class="">
            One of our mission is to support the Tours &amp; Activities industry. This is why we work closely with global
            banking partners and created our own payment solution to support tour operators and activity providers.
            We process booking in 182 countries and accept 135 currencies. Direct and automated payouts are already
            available in 26 countries (you can found more information
            <u><a class="link " href="https://help.cosette.com.au/en/" target="_blank">here</a></u>)
          </p>
          <p class="">
            Within Luxify Marketplace, we developed a payment innovation to allow our suppliers to be paid directly and
            automatically when a booking is made. At Luxify we don't believe in holding payments like big Online Travel
            Agents. We always make sure we split the payment from the customer - when they book - and we send the money to
            your bank account.
          </p>
          <p class="">
            Cancellation and refund are also automated within the process and follow your business terms. The goal is to
            make it easy and seamless for everyone.
          </p>
          <p class="m-0">
            <u><a class="link " href="https://help.cosette.com.au/en/" target="_blank">Help/FAQ for more details</a></u>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
-->
