<section *ngIf="business && business.id">
  <div class="container pt-5 py-md-5">
    <form class="" (ngSubmit)="completeBusiness()" #completeBusinessForm="ngForm">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <h2 class="mt-3 mb-5 text-center">Review and confirmation</h2>
          <p class="text-center">
            Please review and confirm the information below is correct.
          </p>
          <hr/>
          <div class="position-relative">
            <h6 class="font-weight-bold">YOUR BUSINESS DETAILS</h6>
            <p>Business name: <span>{{business.name || 'N/A'}}</span></p>
            <p>Registration number: <span>{{business.taxId || 'N/A'}}</span></p>
            <p>Address: <span>{{business.address.streetAddress || 'N/A'}}</span></p>
            <p>City: <span>{{business.address.city || 'N/A'}}</span></p>
            <p>Postcode: <span>{{business.address.postcode || 'N/A'}}</span></p>
            <p>Country: <span>{{business.address.country || 'N/A'}}</span></p>
            <p>Phone number: <span>{{business.phone || 'N/A'}}</span></p>
            <p>Email address: <span>{{business.email || 'N/A'}}</span></p>
            <button type="button" (click)="goBackTo('details')" class="edit-step btn btn-link p-0">EDIT THIS STEP</button>
            <hr/>
          </div>
          <div class="position-relative">
            <h6 class="font-weight-bold">YOUR BUSINESS PROFILE</h6>
            <p>Profile description:</p>
            <p class="px-3"><span>{{business.description || 'N/A'}}</span></p>
            <p>Facebook page: <span>{{business.facebookPage || 'N/A'}}</span></p>
            <p>Instagram page: <span>{{business.instagramPage || 'N/A'}}</span></p>
            <p>Linkedin page: <span>{{business.linkedinPage || 'N/A'}}</span></p>
            <button type="button" (click)="goBackTo('profile')" class="edit-step btn btn-link p-0">EDIT THIS STEP</button>
            <hr/>
          </div>
          <div class="position-relative">
            <h6 class="font-weight-bold">YOUR BUSINESS REPRESENTATIVE DETAILS</h6>
            <p>First name: <span>{{business.representativeFirstName || 'N/A'}}</span></p>
            <p>Last name: <span>{{business.representativeLastName || 'N/A'}}</span></p>
            <p>Date of birth: <span>{{business.representativeDateOfBirth  | timeToLabel :'DD/MM/YYYY'}}</span>
            </p>
            <p>Phone number: <span>{{business.representativePhone || 'N/A' }}</span></p>
            <p>Email address: <span>{{business.representativeEmail || 'N/A' }}</span></p>
            <button type="button" (click)="goBackTo('representative')" class="edit-step btn btn-link p-0">EDIT THIS STEP</button>
            <hr/>
          </div>
          <div class="position-relative">
            <h6 class="font-weight-bold">YOUR BANK DETAILS</h6>
            <p>Currency: <span class="text-uppercase">{{customAccount.currency || 'N/A'}}</span></p>
            <p>Account owner name: <span>{{customAccount.bankAccountName || 'N/A'}}</span></p>
            <p>Account number: <span>{{customAccount.last4 || 'N/A'}}</span></p>
            <p>Routing number: <span>{{customAccount.bankNumber || 'N/A'}}</span></p>
            <p>Bank name: <span>{{customAccount.bankName || 'N/A'}}</span></p>
            <button type="button" (click)="goBackTo('bankDetails')" class="edit-step btn btn-link p-0">EDIT THIS STEP</button>
            <hr/>
          </div>
          <h6 class="font-weight-bold">HOW DID YOU HEAR ABOUT US?</h6>
          <div class="form-group">
            <label for="hearAboutUs" hidden>HOW DID YOU HEAR ABOUT US?</label>
            <input type="text" class="form-control form-control-lg" id="hearAboutUs" [(ngModel)]="business.hearAboutUs"
                   (change)="onChange()" name="hearAboutUs">
          </div>
          <div class="form-check mt-5">
            <input class="form-check-input" type="checkbox" name="hasAgreeToTerms"
                   [(ngModel)]="business.hasAgreeToTerms" (change)="onChange()" id="hasAgreeToTerms" required>
            <label class="form-check-label" for="hasAgreeToTerms">
              I agree to Luxify’s <a [routerLink]="['/terms-of-service']">Terms & Conditions</a>
            </label>
          </div>
          <div class="text-center">
            <ng-template #alertMessage></ng-template>
          </div>
          <div class="row mt-4">
            <div class="col-6 text-left">
              <button type="button" (click)="goBackTo('bankDetails')" class="btn btn-outline-primary">GO BACK TO STEP 5</button>
            </div>
            <div class="col-6 text-right">
              <button type="submit" class="btn btn-primary" [disabled]="!completeBusinessForm.form.valid">CONFIRM
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
