import {
  Component, OnInit,
} from '@angular/core';

import {AppService, BusinessService, ProductService} from '../../app/_services';
import {Business, Product, User, Variant} from '../../app/_models';
import {LoaderService} from '../../app/shared/loader/loader.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-list-product',
  templateUrl: './list-product.component.html',
  styleUrls: ['./list-product.component.scss'],

})
export class ListProductComponent {
  currentBusiness: Business = {};
  currentProduct: Product = {
    importType: 'manually',
    isShippingFromBusiness: true
  };
  defaultVariant: Variant = {};

  isValid = {
    details: false,
    medias: false,
    characteristics: false,
    prices: false,
    seller: false,
  };
  show = {
    details: true,
    medias: false,
    characteristics: false,
    prices: false,
    seller: false,
    thanks: false,
  };

  listingProductDatas: any = this.appService.getListingProductDatas();
  realProductDatas: any = this.appService.realStructure();
  photosList: any[] = [];
  countries: any[] = this.appService.countryList();
  currencies: any[] = this.appService.currenciesList();
  materialsList: any[] = this.appService.materialsList();
  colorsList: any[] = this.appService.colorsList();
  years: number[] = [];
  fileUploadFolder: string;
  firstLoad = true;

  constructor(private loaderService: LoaderService,
              private router: Router,
              private route: ActivatedRoute,
              private appService: AppService,
              private businessService: BusinessService,
              private productService: ProductService) {

    const localData = localStorage.getItem('currentUser');
    const currentUser: User = localData && localData !== 'undefined' ? JSON.parse(localData) : {};
    if (!currentUser || !currentUser.id || !currentUser.businessId) {
      this.router.navigate(['list-business']).then();
    } else {
      this.getBusiness(currentUser.businessId);
      const dt = new Date();
      for (let i: number = dt.getFullYear(); i > 1900; i--) {
        this.years.push(i);
      }
    }
  }

  getBusiness(businessId) {
    this.loaderService.show();
    this.businessService.get(businessId)
      .subscribe((business: Business) => {
        this.currentBusiness = business;
        if (!this.currentBusiness || !this.currentBusiness.id || !this.currentBusiness.businessType) {
          this.router.navigate(['list-business']).then();
          this.loaderService.hide();
        } else {
          this.getProduct();
        }
      }, () => {
        this.router.navigate(['/']).then();
        this.loaderService.hide();
      });
  }

  getProduct() {
    this.fileUploadFolder = `images/products/${this.currentBusiness.id}`;
    this.route.params.subscribe(params => {
      if (params.id && !params.step && Object.keys(this.show).includes(params.id)) {
        this.loaderService.hide();
        return this.router.navigate(['list-product']);
      } else if (params.id && !Object.keys(this.show).includes(params.id)) {
        this.fileUploadFolder = `images/products/${this.currentBusiness.id}/${params.id}`;
        this.productService.get(params.id).subscribe((res: Product) => {
          this.currentProduct = res;
          if (res.category && this.listingProductDatas[res.category]) {
            this.photosList = this.listingProductDatas[res.category].photos;
          }
          if (this.firstLoad) {
            this.checkProductObject();
            this.firstLoad = false;
            if (params.step) {
              this.switchView(params.step);
            } else {
              this.switchView('details');
            }
          }
          this.loaderService.hide();
        }, () => {
          this.router.navigate(['404']).then();
          this.loaderService.hide();
        });
      } else {
        this.checkProductObject();
        this.loaderService.hide();
      }
    });
  }

  checkProductObject() {
    // test Object structure
    this.defaultVariant = this.currentProduct.variants && this.currentProduct.variants.length > 0 ? this.currentProduct.variants[0] : {};
    if (!this.currentProduct.images) {
      this.currentProduct.images = [];
    }
    if (!this.defaultVariant.length_unit) {
      this.defaultVariant.length_unit = null;
    }
    if (!this.defaultVariant.height_unit) {
      this.defaultVariant.height_unit = null;
    }
    if (!this.defaultVariant.width_unit) {
      this.defaultVariant.width_unit = null;
    }
  }

  switchView(step) {
    this.show = {
      details: false,
      medias: false,
      characteristics: false,
      prices: false,
      seller: false,
      thanks: false
    };
    this.show[step] = true;

    // test steps validity
    this.isValid.details = !!(this.currentProduct.brand && this.currentProduct.subCategory && this.currentProduct.model
      && this.defaultVariant.material && this.defaultVariant.color);
    this.isValid.medias = true;
    this.photosList.forEach((value, index) => {
      if (!this.currentProduct.images[index]) {
        this.currentProduct.images[index] = {};
        this.isValid.medias = false;
      } else if (!this.currentProduct.images[index].src) {
        this.isValid.medias = false;
      }
    });
    this.isValid.characteristics = !!(this.currentProduct.bodyHtml && this.defaultVariant.serialNumber);
    this.isValid.prices = !!(this.defaultVariant.condition && this.defaultVariant.price && this.currentProduct.currency);
  }

  stepReturn(result: { step: string, product: Product }) {
    if (result.step === 'medias' && result.product.category) {
        this.photosList = this.listingProductDatas[result.product.category].photos;
    }
    if (result.product) {
      this.currentProduct = result.product;
      this.defaultVariant = result.product.variants[0];
    }
    if (result.step) {
      this.switchView(result.step);
    }
    window.scrollTo(0, 0);
  }
}
