import {
  Component
} from '@angular/core';

import {ProductService} from '../../app/_services';
import {Image, Product, User, Variant} from '../../app/_models';
import {LoaderService} from '../../app/shared/loader/loader.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-preview-product',
  templateUrl: './preview-product.component.html',
  styleUrls: ['./preview-product.component.scss'],

})
export class PreviewProductComponent {
  user: User;
  isLoading = true;
  showModal = true;
  currentProduct: Product = {};
  defaultVariant: Variant = {};
  breadcrumb = {
    type: 'type',
    brand: 'brand',
    title: 'title'
  };
  selectedImage: Image;
  quantity =  1;
  // images = [1, 2, 3, 4, 5 , 6 , 7, 8].map(() => `https://picsum.photos/900/500?random&t=${Math.random()}`);
  imagesGroups: Image[][] = [];
  constructor(private route: ActivatedRoute,
              private productService: ProductService,
              private loaderService: LoaderService, private router: Router) {
    this.loaderService.show();
    this.user = JSON.parse(localStorage.getItem('currentUser')) || {id: null};
    this.route.params.subscribe(params => {
      this.productService.get(params.slug1).subscribe((res: Product) => {
        if (res.variants && res.variants.length > 0) {
          this.defaultVariant = res.variants[0];
        }
        this.breadcrumb = {
          type : res.category.split(/(?=[A-Z])/).join(' '),
          brand : res.brand,
          title : res.model
        };
        this.selectedImage = res.image;
        const maxSize = res.images.length;
        const size = Math.floor(maxSize / 3) + ((maxSize % 3 > 0) ? 1 : 0);
        for (let i = 0; i < size; i++) {
          const start = i * 3;
          let end = start + 3;
          if (end > maxSize) {
            end = maxSize;
          }
          this.imagesGroups.push(res.images.slice(start, end));
        }
        this.currentProduct = res;
        this.isLoading = false;
        this.loaderService.hide();
      });
    });
  }
  qtyMinus() {
    this.showModal = false;
    this.quantity -= (this.quantity > 1) ? 1 : 0;
  }
  qtyPlus() {
    this.showModal = false;
    this.quantity += (this.quantity < this.defaultVariant.inventory_quantity) ? 1 : 0;
  }
  onQtyChange() {
    this.showModal = false;
    if (this.quantity < 1) {
      this.quantity = 1;
    } else if (this.quantity > this.defaultVariant.inventory_quantity) {
      this.quantity = this.defaultVariant.inventory_quantity;
    }
  }
  onShowModal() {
    this.showModal = true;
  }
  selectImage(image) {
    this.selectedImage = image;
  }

  getPaymentResult(result) {
    if (result.success) {
      $('#paymentModal').modal('hide');
      this.router.navigate(['/account/payments']).then();
    }
  }
}
