<nav class="navbar navbar-expand-md fixed-top navbar-dark" [ngClass]="{'d-none' : isHidden , 'bg-dark':!isTransparent, 'bg-transparent' : isTransparent}">
  <span *ngIf="showHeaderMessage" class="header-message mx-auto position-absolute text-center d-none d-md-block primary-color">
    The Marketplace For Authentic Pre-Owned Luxury
  </span>
  <a class="navbar-brand" [routerLink]="['/']"><img height="40px" alt="Luxify" [src]="!user.id ? 'assets/images/logo/luxify-logo.svg' : 'assets/images/logo/luxify-logo-gold.svg'"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">

    <ul class="navbar-nav ml-auto text-uppercase text-center">
      <!--<li class="nav-item active">THE LUXIFY STORE <span class="primary-color">(coming soon)</span></li>-->
      <li class="nav-item d-md-none" routerLinkActive="active" *ngIf="!user || !user.id"><a class="nav-link" data-toggle="modal" data-target="#SignUpModal">Connexion</a></li>

      <li class="nav-item d-md-none" routerLinkActive="active" *ngIf="user.id"><a class="nav-link" [routerLink]="['/account']">My Profile</a></li>
      <li class="nav-item d-md-none" routerLinkActive="active" *ngIf="user.id"><a class="nav-link" [routerLink]="['/account/products']">My Product Listing</a></li>
      <li class="nav-item d-md-none" routerLinkActive="active" *ngIf="user.id"><a class="nav-link" [routerLink]="['/account/sales']">My Sales</a></li>
      <li class="nav-item d-md-none" routerLinkActive="active" *ngIf="user.id"><a class="nav-link" [routerLink]="['/account/payments']">My Payments</a></li>
      <li class="nav-item d-md-none" routerLinkActive="active" *ngIf="user.id"><a class="nav-link" [routerLink]="['/account/help']">Help Centre</a></li>
      <li class="nav-item d-md-none" routerLinkActive="active" *ngIf="user.id"><button class="btn btn-link nav-link btn-block text-uppercase" (click)="logout()">Logout</button></li>

      <li class="nav-item dropdown d-none d-md-block" *ngIf="user.id">
        <a class="" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <!--<span *ngIf="!user.profileImage" class="custom-avatar">{{user.firstName[0]}}</span>
          <span *ngIf="user.profileImage" [style.background-image]="'url(' + user.profileImage + ')'" class="custom-avatar avatar-img"></span>
          --><div class="custom-avatar">
            <span class="initials">{{user.firstName[0]}}</span>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-right text-capitalize text-right" aria-labelledby="userDropdown">
          <h6 class="dropdown-header">MY ACCOUNT</h6>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/account']">My Profile</a>
          <a class="dropdown-item" [routerLink]="['/account/products']">My Product Listing</a>
          <a class="dropdown-item" [routerLink]="['/account/sales']">My Sales</a>
          <a class="dropdown-item" [routerLink]="['/account/payments']">My Payments</a>
          <a class="dropdown-item" [routerLink]="['/account/help']">Help Centre</a>
          <span class="dropdown-item text-capitalize" (click)="logout()">Logout</span>
        </div>
      </li>

    </ul>
  </div>
</nav>
