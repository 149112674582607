import {
  Component, Input, Output, EventEmitter
} from '@angular/core';

import {ProductService} from '../../../app/_services';
import {Product, Variant} from '../../../app/_models';
import {LoaderService} from '../../../app/shared/loader/loader.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-list-product-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss'],

})
export class ListProductPricesComponent {
  @Input('product') product: Product = {};
  @Input('variant') variant: Variant = {};
  @Input('currencies') currencies: any[] = [];
  @Output() stepReturn: EventEmitter<{ step: string, product: Product }> = new EventEmitter<{ step: string, product: Product }>();

  needToUpdate = false;
  isLoading = false;

  constructor(private loaderService: LoaderService,
              private productService: ProductService) {
  }

  goBack() {
    return this.stepReturn.emit({step: 'characteristics', product : null});
  }
  getPriceFroSeller() {
    return environment.getAmountWithChargeFee(this.variant.price || 0);
  }

  onChange() {
    this.needToUpdate = true;
  }

  updateProduct() {
    if (!this.needToUpdate) {
      return this.stepReturn.emit({step: 'seller', product : null});
    }
    this.isLoading = true;
    this.loaderService.show();
    this.product.variants = [this.variant];
    this.productService.update(this.product)
      .then(() => {
        this.isLoading = false;
        this.loaderService.hide();
        return this.stepReturn.emit({step: 'seller', product : this.product});
      });
  }
}
