<header class="py-5">
  <div class="container">
    <h1>{{showTerms ? 'Terms of service' : 'Privacy policy'}}</h1>
  </div>
</header>
<section class="bg-light">
  <div class="container" *ngIf="showTerms">
    <h2><b>Terms &amp; Conditions</b></h2>
    <p>Luxify.com and its related sites, services, applications, and tools (each and collectively, the “Site”) are owned
      and operated by Luxify Pty Ltd (“Luxify,” “Us” or “We”). These terms and conditions (“Terms”) govern your
      (“Buyer”) use of the Site and your conduct on the Site.</p>
    <h3><b>1. Acceptance of Terms and Privacy Policy; Modification</b><b><br/></b></h3>
    <ol></ol>
    <p>Luxify provides you with access to and use of the Site subject to your compliance with these Terms and the Site’s
      privacy policy, available here: www.luxify.com/about/privacy-policy/. Your use of the Site constitutes your
      express agreement to these Terms and our privacy policy. If you do not agree to these Terms or our privacy policy,
      you may not access or use the Site.</p>
    <p>We may modify these Terms from time to time without notice to you. The provisions contained herein supersede all
      prior notices or statements regarding our Terms with respect to this Site. We encourage you to check the Site
      frequently to see the current Terms in effect and any changes that may have been made. By using the Site following
      any modifications to the Terms you agree to be bound by the modifications.</p>
    <p>Any inquiries concerning these Terms should be directed to us at the address below.</p>
    <h3><b>2. Consent to Processing; International Use</b></h3>
    <ol start="2"></ol>
    <p>By providing any personal information to the Site, all users, including without limitation users in the European
      Union, fully understand and unambiguously consent to the collection and processing of such information in the
      state of New South Wales, Australia. For further information data, please see our privacy policy.</p>
    <p>We control and operate the Site from our offices in New South Wales, Australia, and all information is processed
      within the state of New South Wales, Australia or at the location of our service providers. We do not represent
      that materials on the Site are appropriate or available for use in locations outside the state of New South Wales,
      Australia. Persons who choose to access the Site from other locations do so on their own initiative, and are
      responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
    <p>Buyers agree to comply with all applicable laws, rules and regulations in connection with their use of the Site.
      The Site may be used only for lawful purposes and in a lawful manner. Without limiting the generality of the
      foregoing, you agree to comply with all applicable laws regarding the transmission of data exported from the state
      of New South Wales, Australia or the country in which you reside as well as the restrictions on import or export
      of Items from the Seller’s country to your country.</p>
    <h3><b>3. Operation of the Site and the Role of Luxify</b></h3>
    <ol start="3"></ol>
    <p>The Site provides an online marketplace for appropriately qualified sellers (“Seller”) to offer to sell goods
      (“Items”) to Buyers. The sale is directly between the Buyer and the Seller.</p>
    <p>The role of Luxify is expressly limited to making the Site available and maintaining the Site. Luxify is an
      intermediary and not an agent or fiduciary for either the Buyer or the Seller for any purpose. Luxify is not
      responsible for the actual sale of Items and does not control the information provided by Sellers or Buyers nor
      their acts or omissions. Luxify is independent from the Buyer and Seller and no partnership, joint venture,
      employee-employer or franchiser-franchisee relationship is intended or created by the operation of this Site by
      Luxify.</p>
    <p>The provisions of the Commercial Agents Directive (86/653/EC) as implemented in any European jurisdiction (for
      example, in the UK via the Commercial Agents Regulations 1993) are expressly excluded from these Terms and our
      agreement. By using the Site, Buyers select and purchase goods marketed by Sellers, solely of their own
      initiative, placing orders via the Site. You acknowledge that Luxify.com has no control over the ultimate price
      Items are sold at or any sales terms ultimately entered into between you and a Seller.</p>
    <p>We are not responsible for the delivery of or transfer of legal ownership of Items from a Seller to you. We are
      not responsible for the delivery of payment from you to the Seller unless we explicitly agree to be. We have sole
      control over the look, feel, content, operations and evolution of the Site, and may modify the Site and any
      content in our sole discretion.</p>
    <p>Luxify may, but is not obligated to, provide intermediary services between the Buyer and Seller in connection
      with customer service or dispute resolution matters. In the event Luxify elects in its sole discretion to provide
      intermediary services, then the decision of Luxify is final and binding on all parties and cannot be appealed,
      challenged or reversed.</p>
    <h3><b>4. Relationship of the Parties</b></h3>
    <ol start="4"></ol>
    <p>The Seller is responsible for accurately describing the Items it is offering to sell and for delivering the Items
      to the Buyer in accordance with the arrangements made between Buyer and Seller, including transfer of title and
      payment of sales tax or VAT or import/export duty to the appropriate authority.</p>
    <p>The Buyer is responsible for determining the value, condition and authenticity of the Items being purchased, to
      pay the purchase price to the Seller including any sales tax, VAT or import/export duties, and to arrange for
      shipping of the Items purchased.</p>
    <p>The agreements between the Buyer and the Seller shall not be governed by the U. N. Convention on Contracts for
      the International Sale of Goods, the application of which is expressly excluded.</p>
    <h3><b>5. Eligibility to Use the Site</b></h3>
    <ol start="5"></ol>
    <p>You must be 18 years of age or older to use this Site. This Site is not directed at children under the age of 13
      and does not knowingly collect information from such children. The Site is designed for persons with experience
      with, and who are accustomed to, buying Items based on photographs and the Buyer represents having such
      experience.</p>
    <h3><b>6. Site Use Termination</b></h3>
    <ol start="6"></ol>
    <p>Luxify expressly reserves the right to terminate the use of, or to refuse to permit the use of, the Site by any
      person or entity, at the sole discretion of Luxify, for any reason or no reason at all, and without prior
      notice.</p>
    <h3><b>7. Registration and Account Access</b></h3>
    <ol start="7"></ol>
    <p>Registration may be required in order to use the Site.</p>
    <p>Registrants are required to provide certain information and to select a password to be used to create and access
      their accounts. This password and other registration details should be kept safe and not shared with anyone.
      Registrants may voluntarily provide additional information in the registration process to personalize their
      accounts. Registrants may access their accounts to view their profile information as well as transaction
      information by clicking the icon on the home page of the Site after logging in.</p>
    <p>Buyers may cancel their registration and account at any time. For your security, requests to terminate accounts
      MUST originate from the registered email account at Luxify addressed to support@Luxify.com. Under no circumstances
      will a cancellation request received via the phone or otherwise be accepted.</p>
    <b>8. Item description, pricing and availability; Site unavailability</b><br/>
    <ol start="8"></ol>
    <p>Sellers are responsible for the accuracy in describing and pricing the Items on the Site. Luxify relies on the
      Sellers for such information and is not responsible in any way for the description or pricing of Items on the Site
      provided by the Seller.</p>
    <p>However, due to the nature of the internet, occasional glitches, service interruptions or mistakes may cause
      unintended inaccuracies to appear on the Site. Luxify has the right to correct inaccuracies or mistakes that come
      to its attention and to void any purchases of Items that display an inaccurate price or description.</p>
    <p>You acknowledge that temporary interruptions in the availability of the Site may occur from time to time as
      normal events.</p>
    <p>Also, we may decide to cease making available the Site or any portion of the Site at any time and for any reason.
      Under no circumstances will Luxify or the Sellers be held liable for any damages due to such interruptions or lack
      of availability.</p>
    <p>Because some of the Items displayed on the Site are unique and are offered by Sellers that usually have retail
      opportunities for the sale of the Item independent from Luxify, all Items displayed on the Site are offered for
      sale strictly subject to availability.</p>
    <p>The Buyer is aware that unless otherwise stated, the Items are not new nor in perfect condition and may require
      touch-up or repairs prior to use and that the available information about these Items may be limited. The Site is
      designed to provide the Buyer access to Items as the Sellers present them. Accordingly, Luxify does not verify any
      information provided by the Seller (or its representative selling an Item) and Luxify makes no representations or
      warranties with respect to the Seller, the Item or the information related to the Item.</p>
    <h3><b>9. The Sale Process</b></h3>
    <ol start="9"></ol>
    <p>Purchases through Site and by telephone. When a Buyer identifies an Item for purchase, the Buyer may make an
      offer to purchase the Item directly through the Site’s checkout page or by contacting Luxify through any method
      listed on the Site. At that time the Buyer shall submit valid payment information to Luxify for payment of the
      Total Purchase Price. The “Total Purchase Price” is the price agreed to on the Site between Buyer and Seller and
      includes (i) the agreed price of the Item (the “Purchase Price”); (ii) if applicable, shipping fees; and (iii)
      sales tax, use tax, VAT, Internet sales tax and/or any other taxes or levies that the Seller is required to
      collect from the Buyer under applicable law at the time of sale. By making an offer to purchase, the Buyer
      irrevocably agrees to pay the Total Purchase Price and the Seller agrees to sell the Item for the Total Purchase
      Price once a Sale Confirmation is issued to the Buyer. Once made, an offer may not be canceled or revoked by the
      Buyer unless the offer was made at a time when the Total Purchase Price was yet to be confirmed e.g. because the
      shipping fees were unknown at the time of placing an offer (and subject to any statutory rights a Buyer may
      have).</p>
    <p>Negotiable Prices; Counter-offers by Seller. If the Seller posting an Item has indicated that the price is
      negotiable, the Buyer may make an offer through the Luxify Site to the Seller to purchase the Item at a price
      selected by the Buyer or opt to contact the Seller directly using a telephone number (operated by Luxify)
      displayed on the Site. Such calls may be recorded by Luxify to log transactions and for training and quality
      assurance purposes. If a Seller or Buyer does not agree to this when notified of this fact at the beginning of a
      call, the call should be terminated.</p>
    <p>Once made, an offer may not be canceled or revoked by the Buyer unless the offer was made at a time when the
      Total Purchase Price was yet to be confirmed e.g. because the shipping fees were unknown at the time of placing an
      offer (and subject to any statutory rights a Buyer may have). The Buyer or the Seller or Luxify may place a time
      limit on the effectiveness of the offer and each acknowledges that the offer will lapse at the end of the offer
      period. By making an offer, the Buyer irrevocably agrees to pay the Total Purchase Price if his/her offer is
      accepted by the Seller. The Seller may decline or accept the Buyer’s offer or make a counter-offer to the Buyer.
      By making a counter-offer, the Seller, unless otherwise noted in the counter-offer, agrees to sell the Item to the
      Buyer at the counter-offered price and represents that the Item is available for immediate sale as long as the
      Buyer agrees to the counter-offer within 24hrs or such time set by the Seller. If the Buyer agrees to pay the
      counter-offered price Buyer shall acknowledge that acceptance by confirming the purchase on the Buyer’s “offer
      status section” page of Buyer’s Luxify account or by confirming this over the telephone.</p>
    <p>In certain circumstances, Buyer will be required to post a “reserve” to support its offer. This reserve shall be
      authorized to Luxify by credit card at the time the initial offer is made. In the event the Buyer and Seller are
      unable to agree upon the sale price or other terms of the transaction then Luxify will cause the reserve to be
      credited back to Buyer’s credit card account. In the event Buyer and Seller consummate the sale transaction, the
      amount of the reserve, up to the Total Purchase Price, will be charged and Luxify will remit the funds to
      Seller.</p>
    <p>For custom made orders, Buyers will be required to post a non-refundable “deposit”. This deposit shall be paid by
      credit card or other acceptable payment methods upon order confirmation. This deposit amount will be held by the
      Seller and shall be applied against the final purchase price.</p>
    <p>Sales Confirmation. An offer or counteroffer is deemed “accepted” by the Seller and the sale is confirmed between
      the Buyer and the Seller when a confirmation (“Sale Confirmation”) is posted to the Buyer’s account in the “offer
      status section” that (a) the Buyer’s offer or subsequent counteroffer to purchase the Item has been accepted and
      confirmed by the Seller, or (b) the Buyer’s acceptance of the Seller’s counteroffer has been acknowledged,
      accepted and confirmed by the Buyer. Notwithstanding anything to the contrary contained herein, in the event(s) of
      an error by Seller as to availability of the Item or an error by the Seller or Luxify as to acceptance of the
      Buyer’s offer or counter-offer, or any other error on Seller’s or Luxify’ part with respect to a Sale Confirmation
      or the operation of the Site, the Seller and/or Luxify reserve the right in their sole and absolute discretion to
      rescind that Sale Confirmation without penalty to any party.</p>
    <p>Once a Seller and Buyer agree on the terms of the sale of an Item, a binding contract between the Buyer and the
      Seller with respect to the sale and purchase of that Item is created.</p>
    <h3><b>10. Offers to Buy or Sell Outside of Luxify Site</b></h3>
    <ol start="10"></ol>
    <p>Luxify does not allow the Buyers or Sellers to use Luxify to contact each other to make offers to buy or sell
      items outside of the Site. Also, Buyers and Sellers are not permitted to use information obtained from the Site to
      contact each other about buying or selling outside of the Site.</p>
    <p>Sellers are liable for all fees due to Luxify arising out of all sales made using some or all of the Site’s
      services, even if sales terms are finalized or payment is made outside of the Site.</p>
    <p>In particular, Sellers are liable for final value fees due to Luxify if they offer or reference their contact
      information, or ask for a buyer’s contact information, in the context of buying or selling outside of the Site,
      even if the item does not sell and the Site was used to communicate with the Buyer.</p>
    <p>Please make sure to follow these guidelines. If a Buyer breaches these guidelines, Luxify may limit, restrict, or
      suspend the Buyer from using Site features. The Buyer may also forfeit special account status and any discounts.
      The Buyer may be subject to the application of fees and recovery of Luxify expenses in policy monitoring and
      enforcement.</p>
    <h3><b>11. Payment for Products Purchased</b></h3>
    <ol start="11"></ol>
    <p>Immediately upon receipt of the Sale Confirmation, the Buyer shall remit an amount equal to 100% of the Total
      Purchase Price. In the event Buyer has previously posted a reserve with Luxify for the Item, then the amount of
      the reserve shall be credited against the Total Purchase Price and Buyer shall remit the remaining balance of the
      Total Purchase Price.</p>
    <p>The Buyer hereby irrevocably authorizes Luxify upon Sale Confirmation to charge the Buyer’s credit card or other
      payment methods for an amount equal to the Total Purchase Price.</p>
    <p>If for any reason after receipt of the Sale Confirmation, Buyer cancels a payment made by credit card or any
      other means or the Buyer otherwise fails to make any payment(s) with respect to the Total Purchase Price, Buyer
      shall remain liable to the Seller for the full Total Purchase Price and any costs related to the sale of the Item
      including but not limited to taxes, storage and handling fees if applicable and any costs incurred by Luxify
      associated with collecting any amount due to the Seller including but not limited to legal fees and costs related
      to currency fluctuations.</p>
    <p>Without limitation to the preceding sentence and in addition to any other remedies at law or equity, in the event
      of Buyer default, Luxify reserves the right, at its election, to retain any and all payments paid by Buyer prior
      to Buyer default with respect to the Item and to cancel the sale of the Item without any further obligation to
      Buyer. The Buyer acknowledges and agrees that damages to Luxify and/or the Seller in the event of Buyer Default
      will be difficult or impossible to prove and that the paid amount is reasonable compensation to Luxify and/or the
      Seller for damages suffered and constitutes liquidated damages and not a penalty.</p>
    <h3><b>12. Right of Cancellation</b></h3>
    <ol start="12"></ol>
    <p>Where the EU Consumer Rights Directive (2011/83/EU) as implemented into national law in the various EU
      jurisdictions applies to a Buyer, unless one of the exceptions below applies, such a Buyer has the right to cancel
      its order without giving reason within 14 days from the day on which that Buyer or a third party indicated by the
      Buyer receives an Item. The Buyer must inform the Seller of its decision to cancel the order in writing within
      this period. The Seller must reimburse all payments received from this Buyer/Luxify for the Items purchased and
      the Buyer will incur no fee as a result of such reimbursement. However, reimbursement may be withheld until the
      Seller has received the Items back from the Buyer. The Buyer must send back the Items following the instructions
      of the Seller. The Buyer will bear the cost of returning the Items to the Seller. The Buyer may lose this right if
      the value of the Items returned diminishes due to the handling of the Items. The right of cancellation does not
      apply to: (a) the supply of Items made to the Buyer’s specifications; (b) the supply of Items which may
      deteriorate or expire rapidly; (c) the delivery of Items which are not suitable for return due to health
      protection or hygienic reasons if unsealed by the Buyer after delivery, or which are, after delivery, mixed with
      other items; (d) the delivery of sealed video or audio recordings or of sealed software if unsealed by the Buyer
      after delivery; and (e) the supply of digital content if the Buyer accepted when it placed the order that delivery
      could be started and that the Buyer could not cancel once delivery had started.</p>
    <h3><b>13. Sales are “As-Is”</b></h3>
    <ol start="13"></ol>
    <p>All Items displayed on the Site are sold “AS IS”. Neither the Seller nor Luxify makes any guarantee, warranty or
      representation, expressed or implied, to any Buyer with respect to any Item, including without limitation, its
      condition, merchantability, fitness for a particular purpose, quality, rarity, importance, provenance, designer or
      creator, exhibitions, literature, historical relevance or otherwise. No statement anywhere, whether oral or
      written, shall be deemed any such guarantee, warranty or representation. Luxify makes no representation or
      warranty as to whether the Buyer acquires any reproduction rights or other intellectual property rights in any
      Item.</p>
    <h3><b>14. Outside of Australia Buying and Selling; Translation</b></h3>
    <ol start="14"></ol>
    <p>Many of Luxify Services are accessible to Sellers and Buyers outside of Australia. Luxify may offer certain
      programs, tools, and site experiences of particular interest to those Sellers and Buyers, such as estimated local
      currency conversion and international shipping calculation tools. Sellers and Buyers are responsible for complying
      with all laws and regulations applicable to the international sale, purchase, and shipment of items.</p>
    <p>Buyers authorize Luxify to use automated tools to translate the Buyers’ Site content and Buyer-to-Seller
      communications, in whole or in part, into local languages where such translation solutions are available. Luxify
      may provide Buyers with tools which will enable the Buyers to translate content upon request. The accuracy and
      availability of any translation are not guaranteed and Luxify is not liable in any way to the Buyer or the Seller
      for any loss suffered where the automated tools are used.</p>
    <h3><b>15. Expert Review</b></h3>
    <ol start="15"></ol>
    <p>If the Buyer is purchasing an Item based partially or entirely on its stated provenance, designer or creator, the
      Buyer may at its option and at its own cost arrange with the Seller to have Buyer’s selected expert review the
      Item prior to purchase. Selection of the expert is the sole responsibility of the Buyer. Any arrangements for
      inspection shall be made between the Buyer and Seller. Buyer expressly acknowledges that the Buyer’s use and/or
      reliance on any expert is at the Buyer’s own risk and cost. Luxify only recommend additional services such as
      Trustlux or Entrupy. </p>
    <h3><b>16. Taxes and Import/Export Duties</b></h3>
    <ol start="16"></ol>
    <p>The Buyer is entirely responsible for paying all sales and use taxes, VAT, export and/or import taxes and duties
      and all transactional taxes or levies related to the purchase of each Item purchased (collectively, “Taxes”). The
      Buyer shall pay the Seller such Taxes as the Seller is required to collect, but failure of the Seller to collect
      the Taxes will not relieve the Buyer’s obligation. It is the Buyer’s responsibility to establish and/or document
      any applicable exemption from Taxes. The Buyer must determine, pay, collect, remit and report to the appropriate
      taxing authority the correct amount of all export and/or import taxes or duties payable upon export of the Item
      from its country of origin and import into the state of New South Wales, Australia or any other country.</p>
    <h3><b>17. Import/Export Restrictions</b></h3>
    <ol start="17"></ol>
    <p>Some of the Items sold on the Site may require cultural, customs and endangered species permits for export from
      the country where they are located and import into the Buyer’s country. Items may also be subject to a right of
      the country from which they are exported to purchase the Items from the Buyer, sometimes called a “right of
      preemption”. Neither the Seller nor Luxify makes any representation, gives any warranty or shall have any
      liability to the Buyer in respect of the requirement for, or the availability, or issuance of valid export or
      import permits or the existence or exercise of preemption rights to purchase by governmental or regulatory
      authorities anywhere.</p>
    <p>If the listing of any Item on the Site discloses that the Item requires a cultural export permit or the Seller,
      or Luxify discloses such requirement after confirmation of the sale but before the shipper picks up the Item, or
      the Buyer first learns that an export permit is required after the Item has been picked up by the shipper, and the
      Seller is unable to obtain the export permit within thirty (30) days after the later of confirmation of the sale
      or disclosure of the requirement for the export permit, the Buyer may rescind the sale of such Item by giving
      written notice to Luxify and the Seller within seven (7) business days after expiration of the applicable thirty
      (30) day period. In no event shall the Buyer be entitled to rescind a sale more than ninety (90) days after the
      date of the sale to the Buyer.</p>
    <p>If the sale is rescinded, the Buyer shall cause the affected Item to be returned to the Seller at the Buyer’s
      cost unless otherwise agreed. Upon such return, Luxify and/or the Seller shall return to the Buyer the Total
      Purchase Price. The benefit of this right of rescission is not assignable and belongs solely to the Buyer.</p>
    <h3><b>18. Transfer of Title/Risk of Loss</b></h3>
    <ol start="18"></ol>
    <p>The Seller of each Item sold on the Site represents that the Seller will convey clear title of each Item to the
      Buyer upon receipt by the Seller or his/her agent of their Total Purchase Price for the Item. In the event the
      Seller has requested that Luxify collect and remit payment of the Total Purchase Price, then the Seller will
      convey clear title to the Item upon receipt of the Sales Confirmation by the Buyer and receipt by Luxify of the
      Total Purchase Price.</p>
    <p>In the case of Buyer-arranged shipping, risk of loss and title for such Items pass to Buyer upon Seller’s
      delivery of the Item to the carrier selected by the Buyer for shipment or when the Buyer picks up the Item from
      the Seller. In the case of Seller arranging shipping, risk of loss and title for such Items pass to Buyer upon
      Buyer’s receipt of the Item. The Seller represents that the Seller is the sole owner of each Item the Seller is
      offering for sale on the Site or that the Seller is duly authorized by the owner of the Item to sell the Item and
      that the Seller will transfer ownership of the Item to the Buyer free from any claims by third parties.</p>
    <h3><b>19. Shipping</b></h3>
    <ol start="19"></ol>
    <p>Buyer-arranged shipping. Buyer is in every instance free to arrange Buyer’s own shipping and may elect to use any
      shipping company of its choice. The crating/shipping/insurance companies designated by the Buyer are the Buyer’s
      agents and the Buyer will bear all applicable costs and pay such costs directly to the agents. Buyer may also ask
      Luxify to arrange for shipping of Items on Buyer’s behalf which may be subject to pre-advertised shipping
      prices.</p>
    <p>Seller-arranged shipping. Buyer may elect to have the Seller arrange for crating, packaging, shipping and freight
      insurance. Upon Buyer’s request Seller shall provide shipping information including the name of the freight
      carrier, the complete cost of shipment from Seller to Buyer’s designated receiving address, the cost of any duty
      or other charges to be paid by Buyer, the cost of freight insurance and the name of such insurance provider, as
      well as any costs or fees to be charged by Seller for crating or packaging the Items for shipment. Buyer and
      Seller shall mutually agree on shipment terms and Buyer shall prepay all shipment costs for Seller-arranged
      shipping.</p>
    <h3><b>20. Refusal of Transaction</b></h3>
    <ol start="20"></ol>
    <p>Luxify reserves the right to withdraw any Item from the Site or to amend any content on the Site at any time in
      its sole discretion. Luxify may refuse service to anyone at any time in its sole discretion. Luxify will not be
      liable to any Buyer or any other third party because it has withdrawn any Item from the Site, amended any of the
      content or denied access to the Site.</p>
    <h3><b>21. Copyright Infringement. Notice and Take Down Procedures.</b></h3>
    <ol start="21"></ol>
    <p>We respond to notices of alleged copyright infringement as required by the state of New South Wales, Australia
      Digital Millennium Copyright Act. If you believe that your work has been copied in a way that constitutes
      copyright infringement, please provide our copyright agent the following written information: (i) an electronic or
      physical signature of the person authorized to act on behalf of the owner of the copyright interest; (ii) a
      description of the copyrighted work that you claim has been infringed upon; (iii) a description of where the
      material that you claim is infringing is located on the Site; (iv) your address, telephone number, and e-mail
      address; (v) a statement by you that you have a good-faith belief that the disputed use is not authorized by the
      copyright owner, its agent, or the law; and (vi) a statement by you, made under penalty of perjury, that the above
      information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright
      owner’s behalf. Our copyright agent for notice of claims of copyright infringement on the Site can be reached as
      follows:</p>
    <p>Copyright Agent</p>
    <p>140 George Street, The Rocks, 2000 NSW, Sydney, Australia</p>
    <p>If you become aware that material appears on this site in violation of a copyright please notify us by email at
      copyright@luxify.com.</p>
    <h3><b>22. Modification to Site; Monitoring</b></h3>
    <ol start="22"></ol>
    <p>We reserve the right, for any reason, in our sole discretion and without notice to you, to terminate, change,
      suspend or discontinue any aspect of the Site, including, but not limited to, information, products, data, text,
      music, sound, photographs, graphics, video, messages or other materials (“Content”), features and/or hours of
      availability, and we will not be liable to you or to any third party for doing so. We may also impose rules for
      and limits on use of the Site or restrict your access to part, or all, of the Site without notice or penalty. We
      have the right to change these rules and/or limitations at any time, in our sole discretion.</p>
    <p>Site Monitoring. Luxify reserves the right, but accepts no obligation, to monitor any activity and content on the
      Site. Luxify may investigate any reported violations of applicable law, rule or regulation applicable to Buyers or
      transactions on the Site and take action that it deems appropriate, including but not limited to issuing warnings,
      suspending or terminating service, denying access or removing any content from the Site. Luxify may also
      investigate the use of a credit card by a Buyer and take such action as Luxify deems appropriate, including but
      not limited to canceling any offer placed by such Buyer.</p>
    <h3><b>23. Prohibited Site Use; Security Rules and User Submitted Content</b></h3>
    <ol start="23"></ol>
    <p>Prohibited Conduct. The Site may be used only for lawful purposes by individuals using authorized services of
      Luxify. You are responsible for your own communications, including the upload, transmission and posting of
      information, and are responsible for the consequences of their posting on or through the Site. Luxify specifically
      prohibits any use of the Site, and requires all users to agree not to use the Site, for any of the following:</p>
    <p>(i). Posting any information or using a payment mechanism which is incomplete, false, inaccurate or not your
      own;</p>
    <p>(ii) Impersonating another person;</p>
    <p>(iii) Constituting or encouraging conduct that would constitute a criminal offense, giving rise to civil
      liability or otherwise violate any city, state, national or international law or regulation or which fails to
      comply with accepted Internet protocol;</p>
    <p>(iv) Posting material that is copyrighted or otherwise owned by a third party unless you are the copyright owner
      or have the permission of the owner to post it;</p>
    <p>(v) Posting material that reveals trade secrets, unless you own them or have the permission of the owner;</p>
    <p>(vi) Posting material that infringes on any other intellectual property, privacy or publicity right of
      another;</p>
    <p>(vii) Transmitting or transferring (by any means) information or software derived from the site to foreign
      countries or certain foreign nations in violation of US export control laws; or</p>
    <p>(viii) Attempting to interfere in any way with the Site’s or Luxify’s networks or network security, or attempting
      to use the Site’s service to gain unauthorized access to any other computer system.</p>
    <p>Security Rules. Violations of system or network security may result in civil or criminal liability. We will
      investigate occurrences and may involve, and cooperate with, law enforcement authorities in prosecuting the user
      or users who are involved in such violations. You are prohibited from violating or attempting to violate the
      security of the Site, including, without limitation, the following:</p>
    <p>(i) Accessing data not intended for you or logging into a server or account which you are not authorized to
      access;</p>
    <p>(ii) Attempting to probe, scan or test the vulnerability of a system or network or to breach security or
      authentication measures without proper authorization;</p>
    <p>(iii) Attempting to interfere with service to any user, host or network, including, without limitation, via means
      of submitting a virus, worm, Trojan Horse or other harmful code to the Site, overloading, “flooding”,
      “mailbombing” or “crashing”; or sending unsolicited e-mail, including promotions and/or advertising of products or
      services, or</p>
    <p>(iv) Forging any TCP/IP packet header or any part of the header information in any e-mail or newsgroup
      posting.</p>
    <p>User Submitted Content. You are responsible for any User Content you post to the site. By “User Content” we mean
      any content you post to the site, which may include reviews, comments, image uploading, captions, participating in
      forums, and other such features that allow you to add content to the site. We are not responsible for the
      personally identifiable or other information you choose to submit as User Content and we reserve the right to
      remove any User Content generated by any user at our sole discretion. You understand that once you post User
      Content, your content becomes public. We are not responsible for keeping any User Content confidential so if you
      do not want anyone to read or see that content, do not submit or post it to the Site.</p>
    <p>If we allow you to upload User Content, you may not:</p>
    <p>(i) provide User Content that you do not have the right to submit, unless you have the owner’s permission; this
      includes material covered by someone else’s copyright, patent, trade secret, privacy, publicity, or any other
      proprietary right;</p>
    <p>(ii) forge headers or manipulate other identifiers in order to disguise the origin of any User Content you
      provide;</p>
    <p>(iii) provide any User Content that contains lies, falsehoods or misrepresentations that could damage us or
      anyone else;</p>
    <p>(iv) provide User Content that is illegal, obscene, defamatory, libelous, threatening, pornographic, harassing,
      hateful, racially or ethnically offensive, or encourage conduct that would be considered a criminal offense, give
      rise to civil liability, violate any law, or is otherwise inappropriate;</p>
    <p>(v) impersonate anyone else or lie about your affiliation with another person or entity in your User Content;</p>
    <p>(vi) use meta tags or any other “hidden text” utilizing any of our or our suppliers’ product names or trademarks
      in your User Content; or</p>
    <p>(vii) provide User Content which disparage us or our vendors, partners, Seller, representatives and
      affiliates.</p>
    <p>Except as otherwise specifically provided, if you post content or submit material to the Site, you grant us a
      non-exclusive, royalty-free, perpetual, irrevocable, and fully sub-licensable right to use, reproduce, modify,
      adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world
      in any media. You represent and warrant that you own or otherwise control all of the rights to the content that
      you post; that the content is accurate; that use of the content you supply does not violate these Terms or any law
      or regulation; and the content will not cause injury to any person or entity. We have the right but not the
      obligation to monitor and edit or remove any activity or content. User Content comes from a variety of sources. We
      do not endorse, or support any views, opinions, recommendations, or advice that may be in User Content, nor do we
      vouch for its accuracy or its reliability, usefulness, safety or intellectual property rights of any User Content.
      We take no responsibility and assume no liability for any User Content posted by you or any third party.</p>
    <h3><b>24. Proprietary Rights</b></h3>
    <ol start="24"></ol>
    <p>As between you and Luxify, (or other company whose marks appear on the Site), Luxify (or the respective company)
      is the owner and/or authorized user of any trademark, registered trademark and/or service mark appearing on the
      Site, and is the copyright owner or licensee of the Content and/or information on the Site, unless otherwise
      indicated.</p>
    <p>Except as otherwise provided herein, use of the Site does not grant you a license to any Content, features or
      materials you may access on the Site and you may not modify, rent, lease, loan, sell, distribute or create
      derivative works of such Content, features or materials, in whole or in part. Any commercial use of the Site is
      strictly prohibited, except as allowed herein or otherwise approved by us. You may not download or save a copy of
      any of the Content or screens for any purpose except as otherwise provided by Luxify. If you make use of the Site,
      other that as provided herein, in doing so you may violate copyright and other laws of the state of New South
      Wales, Australia, other countries, as well as applicable state laws and may be subject to liability for such
      unauthorized use. We do not grant any license or other authorization to any user of our trademarks, registered
      trademarks, service marks, other copyrightable material or any other intellectual property by including them on
      the Site.</p>
    <p>The information on the Site including, without limitation, all site design, text, graphics, interfaces, and the
      selection and arrangements is protected by law including copyright law.</p>
    <p>Product names, logos, designs, titles, graphics, words or phrases may be protected under law as the trademarks,
      service marks or trade names of Luxify Pty Ltd or other entities. Such trademarks, service marks and trade names
      may be registered in the state of New South Wales, Australia and internationally.</p>
    <p>Without our prior written permission, you agree not to display or use our trademarks, service marks, trade names,
      other copyrightable material or any other intellectual property in any manner.</p>
    <p>Images created or produced or modified by Luxify. All images created or produced or modified by Luxify are the
      sole property of Luxify. Luxify may use any such image to promote the Site or for any other purpose at any time
      without restriction.</p>
    <h3><b>25. Links to Third Party Sites</b></h3>
    <ol start="25"></ol>
    <p>You may be able to link to third party websites (“Linked Sites”) from the Site. Linked Sites are not, however,
      reviewed, controlled or examined by us in any way and we are not responsible for the content, availability,
      advertising, products, information or use of user information or other materials of any such Linked Sites, or any
      additional links contained therein. These links do not imply our endorsement of or association with the Linked
      Sites. It is your sole responsibility to comply with the appropriate terms of service of the Linked Sites as well
      as with any other obligation under copyright, secrecy, defamation, decency, privacy, security and export laws
      related to the use of such Linked Sites and any content contained thereon. In no event shall we be liable,
      directly or indirectly, to anyone for any loss or damage arising from or occasioned by the creation or use of the
      Linked Sites or the information or material accessed through these Linked Sites. You should direct any concerns to
      that site’s administrator or Webmaster. We reserve the exclusive right, at its sole discretion, to add, change,
      decline or remove, without notice, any feature or link to any of the Linked Sites from the Site and/or introduce
      different features or links to different users.</p>
    <p>Permission must be granted by us for any type of link to the Site. To seek our permission, you may write to us at
      the address below. We reserve the right, however, to deny any request or rescind any permission granted by us to
      link through such other type of link, and to require termination of any such link to the Site, at our discretion
      at any time.</p>
    <h3><b>26. Indemnity</b></h3>
    <ol start="26"></ol>
    <p>All Site users agree to defend, indemnify and hold Luxify Pty Ltd, its directors, officers, employees, agents,
      vendors, partners, contractors, representatives and affiliates harmless from any and all claims, liabilities,
      damages, costs and expenses, including reasonable attorneys’ fees, in any way arising from, related to or in
      connection with their use of the Site, their purchase or sale if Items, the nature or quality of items, their
      disputes with any Seller in connection with use of the Site, their violation of any law, their violation of these
      Terms or their posting or transmission of any User Content or materials on or through the Site, including, but not
      limited to, any third party claim that any information or materials such Site user provides infringes any third
      party proprietary right. All Site users agree to cooperate as fully as reasonably required in the defense of any
      claim. This indemnification obligation will survive the termination of these Terms and your use of the Site.</p>
    <h3><b>27. Disclaimer of Warranties</b></h3>
    <ol start="27"></ol>
    <p>YOU UNDERSTAND AND AGREE THAT:</p>
    <p>THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS. LUXIFY MAKES NO REPRESENTATION OR WARRANTY OF ANY
      KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE SITE, ANY ITEMS OFFERED FOR SALE OR SOLD ON OR THROUGH THE SITE OR
      ANY SELLER, INCLUDING WITHOUT LIMITATION:</p>
    <p>(a) any representation or warranty that the Site meets the Buyer’s requirements, will always be accessible,
      uninterrupted, timely, secure or operate without error or that defects will be corrected;</p>
    <p>(b) any representation or warranty with respect to title to or delivery of any Item;</p>
    <p>(c) any representation or warranty with respect to intellectual property rights in any Item;</p>
    <p>(d) any representation or warranty that any Item conforms to its description or the colors, texture and detail
      shown on the Buyer’s computer monitor;</p>
    <p>(e) any representation or warranty regarding the character, reputation or business practices of the Seller;</p>
    <p>(f) any representation or warranty concerning the availability, accuracy, completeness, usefulness, or content of
      information; or</p>
    <p>(g) any representation or warranty of title, non-infringement, merchantability or fitness for a particular
      purpose.</p>
    <p>The Buyer must direct all claims regarding any Item to the Seller and must resolve any dispute regarding any Item
      directly with the Seller.</p>
    <p>No advice, results or information, or materials whether oral or written, obtained by you through the Site shall
      create any warranty by Luxify not expressly made herein. If you are dissatisfied with the Site, your sole remedy
      is to discontinue using the Site.</p>
    <p>ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS DONE AT YOUR OWN DISCRETION AND RISK
      AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</p>
    <p>LUXIFY DOES NOT ENDORSE, WARRANT OR GUARANTEE ANY PRODUCTS OR SERVICES OFFERED OR PROVIDED BY OR ON BEHALF OF
      SELLERS ON OR THROUGH THE SITE. Luxify IS NOT A PARTY TO ANY TRANSACTION BETWEEN BUYERS AND SELLERS UNLESS
      REQUESTED AND NOTIFIED TO THE PARTIES.</p>
    <h3><b>28. Release</b></h3>
    <ol start="28"></ol>
    <p>You expressly agree to release Luxify, its affiliates and subsidiaries, and their respective directors, officers,
      employees, agents, shareholders, partners, successors and assigns (the “Released Parties”), and each of the
      foregoing, from any and all manner of action, claim or cause of action or suit, at law or in equity, and from any
      and all losses, damages, costs or expenses, including without limitation court costs and attorneys’ fees, which
      you may have against the Released Parties, or any of them, known or unknown, disclosed or undisclosed, which arise
      out of or relate in any way to a dispute. You further waive your rights under Section 1542 of the California Civil
      Code, and any similar law of any applicable jurisdiction, which states: “A general release does not extend to
      claims which the creditor does not know or suspect to exist in his favor at the time of executing the release,
      which if known by him must have materially affected his settlement with the debtor.” You agree that no joint
      venture, partnership, employment, or other agency relationship exists between you and Luxify as a result of these
      Terms or your use of the Site.</p>
    <h3><b>29. Limitation of Liability</b></h3>
    <ol start="29"></ol>
    <p>IN NO EVENT SHALL LUXIFY PTY LTD, ITS AFFILIATES OR ANY OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES,
      AGENTS, PARTNERS, SUBSIDIARIES, DIVISIONS, SUCCESSORS, SUPPLIERS, DISTRIBUTORS, AFFILIATES VENDORS, CONTRACTORS,
      REPRESENTATIVES OR CONTENT OR SERVICE PROVIDERS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL,
      EXEMPLARY OR PUNITIVE DAMAGES ARISING FROM OR DIRECTLY OR INDIRECTLY RELATED TO THE USE OF, OR THE INABILITY TO
      USE, THE SITE OR THE CONTENT, MATERIALS AND FUNCTION RELATED THERETO, INCLUDING, WITHOUT LIMITATION, LOSS OF
      REVENUE, OR ANTICIPATED PROFITS, OR LOST BUSINESS, DATA OR SALES, OR COST OF SUBSTITUTE SERVICES, EVEN IF LUXIFY
      OR ITS REPRESENTATIVE OR SUCH INDIVIDUAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
    <p>SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY SO SOME OF THE ABOVE LIMITATIONS MAY NOT
      APPLY TO YOU. IN NO EVENT SHALL THE TOTAL LIABILITY OF LUXIFY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION
      (WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE OR OTHERWISE) ARISING FROM THE TERMS OR
      YOUR USE OF THE SITE EXCEED, IN THE AGGREGATE, $100.00. WITHOUT LIMITING THE FOREGOING, IN NO EVENT SHALL LUXIFY
      PTY LTD OR ITS RESPECTIVE OFFICERS DIRECTORS, EMPLOYEES, AGENTS, SUCCESSORS, SUBSIDIARIES, DIVISIONS,
      DISTRIBUTORS, SUPPLIERS, AFFILIATES OR THIRD PARTIES PROVIDING INFORMATION ON THIS SITE HAVE ANY LIABILITY FOR ANY
      DAMAGES OR LOSSES ARISING OUT OF OR OTHERWISE INCURRED IN CONNECTION WITH THE LOSS OF ANY DATA OR INFORMATION
      CONTAINED IN YOUR ACCOUNT OR OTHERWISE STORED BY OR ON BEHALF LUXIFY.</p>
    <h3><b>30. Arbitration; Waiver of Jury Trial and Class Action; Applicable Law/Jurisdiction</b></h3>
    <ol start="30"></ol>
    <p>(A) Binding Arbitration. Any dispute, controversy or claim arising out of or relating to these Terms, or its
      breach, which cannot be resolved between the parties through negotiation within thirty (30) days, shall be
      submitted to the relevant Australian Association for mandatory binding arbitration in front of a single arbitrator
      chosen in accordance with the existing Rules. Discovery shall be permitted, but only to the extent that the
      documents are directly relevant to and needed for fair resolution of one or more of the issues of importance and
      can be located and produced at a cost that is reasonable in the context of all surrounding facts and
      circumstances. When the cost and burden of discovery are disproportionate to the likely importance of the
      requested materials, the arbitrator may deny the requests or require that the requesting party advance the
      reasonable cost of production to the other side.</p>
    <p>(B) Additional Provisions. The arbitrator may not award non-monetary or equitable relief of any sort, nor award
      damages inconsistent with these Terms. All aspects of the arbitration shall be treated as confidential. Neither
      the parties nor the arbitrator may disclose the existence, content or results of the arbitration, except as
      necessary to enforce the results of the arbitration or to comply with legal or regulatory requirements. The
      arbitrator shall render its award in writing and will include the findings of fact and conclusion of law upon
      which the award is based. The result of the arbitration shall bind the parties and judgment on the arbitrators’
      award may be entered in any court having jurisdiction. In addition to any and all other relief to which a Party
      may be entitled, the arbitrator shall award reasonable attorneys’ fees and costs, including reasonable expert
      witness fees and costs, to the prevailing Party (should there be one) in any such arbitration.</p>
    <p>(C) Waiver of Jury Trial; Individual Basis; Equitable Relief. The parties surrender and waive the right to submit
      any dispute to a court or jury, or to appeal to a higher court. The parties agree to arbitration on an individual
      basis. Where enforceable, NEITHER YOU NOR WE SHALL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER
      SELLERS OR PERSONS, OR ARBITRATE ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY
      GENERAL CAPACITY. UNLESS BOTH YOU AND WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE
      PERSON’S OR PARTY’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR
      CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND
      ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S). ANY RELIEF
      AWARDED CANNOT AFFECT OTHER SITE USERS/SELLERS. If any provision of this arbitration agreement is found
      unenforceable, the unenforceable provision shall be severed, and the remaining arbitration terms shall be enforced
      (but in no case shall there be a class arbitration).</p>
    <p>Notwithstanding the foregoing, nothing in these Terms shall prohibit either party from seeking and obtaining from
      a court of competent jurisdiction (without necessity of posting bond) injunctive relief in order to preserve the
      status quo and/or avoid irreparable harm for which monetary damage would be insufficient.</p>
    <p>(D) Where Held; Language; Governing Law. The place of arbitration shall be the borough of Sydney, New South
      Wales, Australia, and the proceedings shall be conducted in the English language. These Terms shall be governed by
      the state of New South Wales, Australia Federal Arbitration Act to the exclusion of any inconsistent state laws
      and the arbitrator shall follow the law and judicial precedents that a Australian Judge sitting in New South Wales
      would apply to the dispute.</p>
    <h3><b>31. Notices</b></h3>
    <ol start="31"></ol>
    <p>Any notices shall be given by postal mail addressed to Luxify Pty Ltd, 140 George Street, The Rocks 2000 NSW,
      Sydney, Australia, to the attention of Legal Department or to the e-mail address provided to Luxify and currently
      on record. Notice to the Buyer shall be deemed to have been given 24 hours after the e-mail was sent, unless
      Luxify is notified that the e-mail address is invalid, in which event Luxify may give notice by postal mail at the
      address provided to Luxify by the Buyer upon registration. Notice given by postal mail shall be deemed to have
      been given three (3) business days after the date of mailing.</p>
    <h3><b>32. General Information</b></h3>
    <ol start="32"></ol>
    <p>If any provision of these Terms is deemed unlawful, void or for any reason unenforceable, such provision(s) will
      be deemed severable from the rest of the Terms and will not affect the validity and enforceability of the rest of
      the Terms. Failure of Luxify to exercise any rights or remedies will not constitute a waiver of any rights or
      remedies available to Luxify under these Terms or at law. These Terms represent the entire agreement between you
      and Luxify and supersede and replace any other agreement between the parties including but not limited to any
      previous Terms as they may have applied between you and Luxify. Paragraph headings are for convenience only and
      not for interpretation of these Terms.</p>
    <b>33. Contact Us</b>
    <ol start="33"></ol>
    <p>To contact us with any questions or concerns in connection with these Terms or the Site please write to us at:
      Luxify Pty Ltd, 140 George Street, The Rocks 2000 NSW, Sydney, Australia or email us at support@Luxify.com.</p>
  </div>
  <div class="container" *ngIf="!showTerms">
    <h2><b>Privacy Policy</b></h2>
    <p>The Luxify.com website and mobile application (collectively the “Site”) are owned and operated by Luxify Pty Ltd.
      This Privacy Policy describes how information, including Personal Information, is collected from you and
      subsequently used, shared, secured and otherwise processed by Luxify Pty Ltd and our affiliated companies
      (“Luxify.com”, “Luxify”, “us” or “we”).</p>
    <p><b>“Personal Information”</b>, is any information that enables us to identify you, directly or indirectly, such
      as your email address, name, shipping and billing address, telephone number, company name, credit card
      information, any form of identification number or one or more factors specific to your physical, physiological,
      mental, economic, cultural or social identity.</p>
    <p>This Privacy Policy is part of our User Agreement, available here – www.luxify.com/about/terms-and-conditions.
      Mobile users can navigate to the Settings icon to access our User Agreement and Privacy Policy.</p>
    <p>By accessing this website or our mobile application and making use of our services you explicitly agree to the
      terms of this Privacy Policy and the User Agreement.</p>
    <p>For the purposes of the EU General Data Protection Regulation 2016/679 (the “GDPR”), the data controller is
      Luxify Pty Ltd, whose registered office is at 140 George Street, The Rocks 2000 NSW, Sydney, Australia.</p>
    <h3><b>1. Effective date and updates to our Privacy Policy.</b></h3>
    <ol></ol>
    <p>This Privacy Policy is effective as of January 1st, 2020. As our services evolve and we perceive the need or
      desirability of collecting or using information collected in other ways, we may from time to time amend this
      Privacy Policy and will provide a new effective date on the revised Privacy Policy. We encourage you to check our
      Site frequently to see the current Privacy Policy and User Agreement in effect and any changes that may have been
      made to them.</p>
    <h3><b>2. Information Luxify collects from you.</b></h3>
    <ol start="2"></ol>
    <h4>a) Information you give us</h4>
    <ol start="2"></ol>
    <p>Communicating with us. In general, you can visit the Site without telling us who you are or revealing any
      information about yourself. However, if you correspond with us through our Contact Us page, by phone, email or
      otherwise, we will retain the Personal Information that you provide. This includes Personal Information you
      provide when participating in discussion boards or social media functions on the Site, and when you report a
      problem with the Site.</p>
    <p>Communicating with other users. Users can contact sellers to negotiate a sale or make a purchase by contacting
      the seller using a telephone number displayed on the Site which is operated by us. When a user connects with a
      seller by telephone that call may be recorded by us to log transactions and for training and quality assurance
      purposes.</p>
    <p>Creating an account. To take advantage of some of our services you are required to register and create an account
      via the Sign Up page on our Site. When you open an individual account at Luxify.com, we ask you to provide
      Personal Information (such as your name, address, phone number, email address, business affiliation and contact
      information). All Luxify.com account holders are required to create a user password that, combined with your login
      email address, allows access to your account. This Personal Information is used to identify you as a Luxify.com
      registered user.</p>
    <p>Saving an item. Users may save items to their favorites by clicking the heart or folder icon for an item. If you
      have created an account, your saved items are stored in the favorites folders associated with your account. Items
      are saved until you remove them.</p>
    <p>Facebook log-in. As part of our services, we offer you the option of creating an individual account with
      Luxify.com using your Facebook profile. If you select this option, we will automatically collect selected Personal
      Information about you from your Facebook account. The Personal Information we collect depends on your privacy
      settings for sharing such Personal Information, and may include name, profile picture, age range, gender,
      language, country and other public information.</p>
    <p>Creating a seller account. When you open a seller account with Luxify.com, you will also be required to provide
      Personal Information such as information about your company and any reseller or trade association information. You
      will also be asked to provide references which may include Personal Information relating to third parties. Please
      ensure that those third parties have consented to the use of their Personal Information by 1stdib.com for the
      purposes of confirming your identity or the identity of your company.</p>
    <p>Making a purchase. Luxify.com is a marketplace offering a forum for sellers to offer items for sale and for
      buyers to browse, and make purchases. When you make an offer, we will ask you to provide credit card or other
      payment details as well as shipping information for orders.</p>
    <p>Reviews. Registered users may choose to write reviews and have them posted on the Site; others wishing to post a
      review must register and provide their name and contact information including email and postal addresses. Reviews
      will include the first name and last initial and shipping location of the reviewer. Reviews must comply with our
      Terms of Use. Information posted in reviews becomes public information. The use that any third party makes of this
      information is beyond our control. Please use caution before disclosing any information in a review that you do
      not want to become public.</p>
    <h4>b) Information we collect about you</h4>
    <ol></ol>
    <p>With regard to each of your visits to the Site we automatically collect the following information:</p>
    <p>Technical Information. This includes browser type and version (e.g. Internet Explorer, Firefox, Chrome, Safari),
      operating system (e.g. Windows, Macintosh), IP address, device type, time zone setting and Internet domain (e.g.
      AOL, Hotmail).</p>
    <p>Information about your visit. This includes the pages you viewed or searched for, page response times, download
      errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and
      mouse-overs), methods used to browse away from the page, and any phone number used to call our customer service
      number.</p>
    <p>Location information. Luxify asks mobile users whether they will permit us to collect information about their
      location. We collect location information only from mobile users who have agreed to provide that information.</p>
    <p>We do not treat this information as Personal Information, except where the information can identify you when
      associated with other Personal Information we hold or where we are required to do so under applicable law.</p>
    <h3><b>3. Children’s privacy</b></h3>
    <ol start="3"></ol>
    <p>Luxify.com is not designed nor intended to be attractive to use by children under the age of 13. Our Terms of Use
      require that users must be 18 years old or older in order to register on our Site and use our services. If you are
      under the age of 18, please do not submit any information to us.</p>
    <h3><b>4. Luxify.com uses cookies.</b></h3>
    <ol start="4"></ol>
    <p>The Site uses cookies to distinguish you from other users. This helps us to analyze the use of the Site to
      customize and improve the content and the layout of the Site. For detailed information on the cookies we use and
      the purposes for which we use them, see our Cookie Policy https://help.luxify.com/s/article/Cookie-Policy/.</p>
    <h3><b>5. How does Luxify.com use your Personal Information?</b></h3>
    <ol start="5"></ol>
    <p>We use your Personal Information according to the terms of the Privacy Policy and User Agreement in effect at the
      time of our use. We will process your Personal Information where (1) you have provided your consent which can be
      withdrawn at any time, (2) where the processing is necessary for the performance of a contract to which you are a
      party (for example, we will share your Personal Information with sellers with whom you have entered into a
      transaction), (3) where we are required by law, (4) where processing is required to protect your vital interests
      or those of another person, or (5) where the processing is necessary for the purposes of our legitimate commercial
      interests, except where such interest are overridden by your rights and interests.</p>
    <h4>a) We may use the Personal Information you give us for our legitimate commercial interests including, among
      other things:</h4>
    <ol></ol>
    <ul>
      <li>to respond to your inquiries and to provide you with the information, products and services that you request
        from us;
      </li>
      <li>to provide information on the products or services of our registered sellers with whom you are placed in touch
        with in connection with a prospective purchase;
      </li>
      <li>to verify that you qualify as a member of the Site;</li>
      <li>to send you communications about (1) our own products and services, (2) products and services offered by
        sellers or advertisers on our Site and (3) products and services of third parties (those not offered by sellers
        on our Site) that we think you may find of interest, provided you have given your consent;
      </li>
      <li>where the processing is required in order to enforce or apply our User Agreement and other agreements you have
        entered into; and
      </li>
      <li>to contact you as necessary.</li>
    </ul>
    <h4>b) We may use the Personal Information we collect in either an identifiable or an anonymized form (independent
      of any personal identifiers):</h4>
    <ol></ol>
    <ul>
      <li>to administer the Site and for internal operations, including troubleshooting, data analysis, testing,
        research, statistical and survey purposes;
      </li>
      <li>to improve the Site and to customize the content you see on the Site;</li>
      <li>as part of our efforts to keep the Site safe and secure;</li>
      <li>to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant
        advertising to you; and
      </li>
      <li>to do internal research on our Site visitors’ interests and behaviors to better understand and serve our
        members.
      </li>
    </ul>
    <p>We use email as the primary form of communicating transactional and relationship information with registered
      sellers and registered Site users. We may send mobile users notifications through the application but only if the
      user has agreed to receive notifications from us in their preferences. Preferences can be changed via Settings at
      any time. We may also use your street addresses and telephone numbers to provide these communications.</p>
    <p>When users of the Site wish to negotiate a sale with sellers or make a purchase from a seller they do so by
      contacting the seller using a telephone number displayed on the Site which is operated by Us. When a user connects
      with a seller by telephone that call may be recorded by us to log transactions and for training and quality
      purposes. We may use a carefully selected third party to carry out such call recording and transcription services
      on our behalf. If a user or seller does not agree to this when notified of this fact by us at the beginning of a
      call, the call should be terminated. No call recordings are shared with third parties other than as described in
      this Privacy Policy, and we take steps to eliminate Personal Information from the call recording.</p>
    <h3><b>6. With whom does Luxify.com share your Personal Information?</b></h3>
    <ol start="6"></ol>
    <p>Luxify.com does not sell, share, trade or give away Personal Information other than as described in this Privacy
      Policy.</p>
    <p>We reveal your Personal Information to unaffiliated third parties when you request or authorize it, or to help
      complete a transaction for you which you have requested.</p>
    <p>Where you use our Site to enter into a transaction or correspond with another individual or business registered
      as a seller on our Site, we provide your Personal Information to that other party in order to facilitate the
      transaction or the correspondence you have requested. Once your offer is submitted, your Personal Information will
      be delivered by Luxify.com to that seller, and your order will be placed.</p>
    <p>When an Australian user contacts a seller, Luxify may share your search terms, or items you have viewed, saved,
      or purchased with that seller. You can disable sharing this information with sellers by updating the profile
      preferences in your account.</p>
    <p>Third parties who will have access to your Personal Information include (without limitation) sellers selling
      items on the Site that you have contacted or made a purchase from, shipping companies selected by you or the
      dealer, and payment processors completing a transaction at your request.</p>
    <p>Sellers from whom you purchase items may use your Personal Information for their own purposes including marketing
      purposes. Sellers will have their own privacy practices regarding the information they receive. We encourage you
      to read such applicable privacy policies. We are not responsible for the processing of your Personal Information
      by such third parties.</p>
    <p>We may share your Personal Information with other companies or agents affiliated to Luxify.com who have been
      carefully selected and who perform functions on our behalf (e.g., analyzing data, providing marketing assistance,
      providing customer service, processing orders, etc.).</p>
    <p>We will only transfer your Personal Information to trusted third parties who provide sufficient guarantees in
      respect of the technical and organizational security measures governing the processing to be carried out and who
      can demonstrate a commitment to compliance with those measures.</p>
    <p>Where third parties are processing Personal Information on our behalf, they will be required to agree, by
      contractual means or otherwise, to process the Personal Information in accordance with the applicable law. This
      contract will stipulate, amongst other things, that the third party and its representative shall act only on our
      instructions, or as permitted by law.</p>
    <p>These companies may use your information for their own purposes including marketing to you where you have
      consented to receive notifications relating to their products and services. We may also disclose your Personal
      Information to comply with the law, applicable regulations, governmental and quasi-governmental requests, court
      orders or subpoenas, to enforce our User Agreement or other agreements, or to protect our rights, property or
      safety or the rights, property or safety of our users or others (e.g., to a consumer reporting agency for fraud
      protection etc.).</p>
    <p>In the event of a corporate transaction, such as the purchase, sale or other transfer of all or part of our
      services or assets, we may transfer your Personal Information along with those assets or services to a prospective
      transferee.</p>
    <h3><b>7. International transfers</b></h3>
    <ol start="7"></ol>
    <p>The Personal Information we receive may be held on our computers and systems in the European Union and in the
      computers and systems of our offices and data centers in Australia and may be accessed by or given to our staff
      working outside the European Union.</p>
    <p>Your Personal Information may be processed by us in Australia, where laws regarding data protection may be less
      stringent than the laws in your country. By using this Site and by providing any Personal Information to the Site,
      all users, including without limitation users in Canada and the European Union, fully understand and unambiguously
      consent to the collection and processing of such Personal Information in Australia.</p>
    <p>In addition, sellers registered on the Site are located around the world. Due to the nature of our business and
      to enable us to offer goods and services worldwide, your Personal Information may be transferred to and held by
      merchants or sellers in any country in the world. By using this Site and by providing any Personal Information to
      the Site, all users, including without limitation users in Canada and the European Union, fully understand and
      unambiguously consent to the collection and processing of such Personal Information by such third parties.</p>
    <h3><b>8. How you can access and amend your Personal Information.</b></h3>
    <ol start="8"></ol>
    <p>You are required to provide us with accurate and up to date Personal Information. Luxify.com gives you the
      ability to amend your Personal Information at any time by accessing your account on our Site (by clicking on the
      “account details” link on the website homepage or the Account icon on the mobile application), modifying the
      Personal Information on your account page, acknowledging our User Agreement by clicking on the box at the bottom
      of the page and then clicking on submit.</p>
    <p>If you do not want to share search terms, or items you have viewed, saved, or purchased history with sellers you
      contact, you can update your profile preferences on your account page at any time.</p>
    <p>Subject to any legislation which might, from time to time, oblige us to store the Personal Information for a
      certain period of time, we will respect your wishes to correct inaccurate Personal Information. Otherwise, we will
      hold your Personal Information for as long as we believe it will help us to achieve our aims as detailed in this
      Privacy Policy.</p>
    <p>You can ask us whether we are keeping Personal Information about you and you can ask to receive a copy of that
      Personal Information. Before sending you any Personal Information, we will ask you to provide proof of your
      identity. If you are not able to provide proof of your identity, we reserve the right to refuse to send you any
      PersonalInformation. We will respond as quickly as we can to your requests for details of Personal Information we
      hold about you.</p>
    <h3><b>9. Security.</b></h3>
    <ol start="9"></ol>
    <p>As a registered user of the Site, you are responsible for maintaining the confidentiality of your username and
      password. Please keep your user ID and password confidential.</p>
    <p>If you share your computer, we recommend that you log-out and close your browser window after visiting the Site
      in order to protect the confidentiality of your Personal Information, including your credit card information, your
      offer status and buying history.</p>
    <p>You agree to accept responsibility for all activities that occur under your account or password. You agree to
      notify us immediately of any unauthorized use of your account or any other breach of security. We reserve the
      right to refuse service, terminate accounts, or remove or edit content at our sole discretion.</p>
    <p>We use reasonable physical, electronic, and administrative safeguards to help us protect the security, integrity
      and confidentiality of data stored on our system. The Site encrypts your credit card number and other Personal
      Information using secure socket layer (SSL) technology to provide for the secure transmission of the Personal
      Information from your PC to our servers.</p>
    <p>Except as specified in this Privacy Policy, we use commercially reasonable efforts to limit access to your
      Personal Information to the employees, officers, third-party contractors, and strategic partners of Luxify.com who
      need the Personal Information in order to perform their duties.</p>
    <p>SSL is an industry-standard protocol for encryption over the Internet. While no system is 100% secure, we believe
      that the measures we have implemented minimize the risk of a security breach to an appropriate level given the
      types of Personal Information involved. Please note that any Personal Information you provide us by email is
      unencrypted.</p>
    <p>Please note that transmission of Personal Information via the internet is not completely secure and although we
      will endeavor to protect your information, we cannot guarantee the security of your information transmitted to our
      Site; any transmission is therefore at your own risk. Once we have received your information, we will use
      procedures and security features to try to prevent unauthorized access, however.</p>
    <h3><b>10. Opt- out</b></h3>
    <ol start="10"></ol>
    <p>If you do not wish to receive offers or other notices from us in the future, you can “opt-out” by changing your
      preferences in My Account or by contacting us at the address indicated at the end of this policy. We will try to
      complete your request as promptly as possible. You can elect not to receive emails from us either by
      “unsubscribing” to an email you receive or by contacting us as indicated below. Any changes will affect only
      future uses of your information.</p>
    <h3><b>11. Your California privacy rights</b></h3>
    <ol start="11"></ol>
    <p>For California Residents Only. We may disclose your Personal Information to our affiliates or registered sellers
      when the third party receives Personal Information solely as a consequence of a single transaction where, as a
      part of the transaction, Personal Information had to be disclosed in order to effectuate the transaction.</p>
    <h3><b>12. Your European privacy rights</b></h3>
    <ol start="12"></ol>
    <p>For individuals within the EEA only. Under the GDPR, in certain circumstances, you have the right to: (a) request
      access to any Personal Information we hold about you and related information, (b) obtain without undue delay the
      rectification of any inaccurate Personal Information, (c) request that your Personal Information is deleted
      provided the Personal Information is not required by Luxify.com for compliance with a legal obligation under
      European or Member State law or for the establishment, exercise or defense of a legal claim, (d) prevent or
      restrict processing of your Personal Information, except to the extent processing is required for the
      establishment, exercise or defense of legal claims; and (e) request transfer of your Personal Information directly
      to a third party where this is technically feasible.</p>
    <p>In addition, where you believe that Luxify has not complied with its obligation under this Privacy Policy or
      European law, you have the right to make a complaint to an EU Data Protection Authority, such as the UK
      Information Commissioner’s Office.</p>
    <h3><b>13. Links to third-party sites</b></h3>
    <ol start="13"></ol>
    <p>When you use the Site or our service, you may be directed to other websites that are beyond our control. These
      may include merchant sites where you select items for purchase or payment sites where you pay for your
      purchases.</p>
    <p>We may also allow third party sites or applications to link to the Site. We are not responsible for the privacy
      practices of any third parties or the content of linked sites, but we do encourage you to read the applicable
      privacy policies and terms and conditions of such parties or websites.</p>
    <p>This Privacy Policy only applies to our Sites.</p>
    <h3><b>14. Contacting us</b></h3>
    <ol start="14"></ol>
    <p>The Site is owned and operated by Luxify Pty Ltd. If you have any questions or suggestions with res</p>
    <br/>
    <p>pect to this Privacy Policy or if you wish to ask us to correct or show you your Personal Information as provided
      above, you can write to us at Privacy Policy, 140 George Street, The Rocks 2000 NSW, Sydney, Australia or you can
      simply click on Contact Us to send us an email to privacypolicy@luxify.com.</p>
  </div>
</section>

