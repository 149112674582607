import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Product, User} from '../_models/';

import * as moment from 'moment';
import {environment} from '../../environments/environment';

@Injectable()
export class ProductService {
  constructor(private db: AngularFirestore) {
  }

  create(product: Product) {
    return new Promise((resolve, reject) => {
      const productCollection = this.db.collection<Product>('products');
      product.created_at = product.updated_at = moment().utc().format();
      product.certificated = product.validated = product.available = product.published = product.isRemoved = false;
      product.source = 'luxify';
      productCollection.add(environment.removeEmpty(product))
        .then((docRef) => {
          // create a new listing with the same id that the product
          product.id = docRef.id;
          resolve(product);
        })
        .catch(
          (error) => {
            reject(error);
          }
        );
    });
  }

  listForBusiness(businessId: string): Observable<Product[]> {
    return this.db.collection('products', ref => {
      let query: any = ref;
      query = query.where('businessId', '==', businessId);
      query = query.where('isRemoved', '==', false);
      return query;
    }).snapshotChanges().pipe(map((actions: any) => {
      return actions.map((a: any) => {
        const data = a.payload.doc.data() as Product;
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    }));
  }

  update(product: Product) {
    if (!product || !product.id) {
      throw this.handleError<User>(`You don't have the right to update the product id=${product.id}`);
    }
    const data: Product = {};
    /*if (product.title) {
      data.title = product.title;
    }*/
    if (product.bodyHtml) {
      data.bodyHtml = product.bodyHtml;
    }
    if (product.tags) {
      data.tags = product.tags;
    }
    if (product.image) {
      data.image = product.image;
    }
    if (product.images) {
      data.images = product.images;
    }
    if (product.video) {
      data.video = product.video;
    }
    if (product.variants) {
      data.variants = product.variants;
    }
    if (product.year) {
      data.year = product.year;
    }
    if (product.category) {
      data.category = product.category;
    }
    if (product.subCategory) {
      data.subCategory = product.subCategory;
    }
    if (product.brand) {
      data.brand = product.brand;
    }
    if (product.model) {
      data.model = product.model;
    }
    if (product.currency) {
      data.currency = product.currency;
    }
    if (product.isShippingFromBusiness) {
      data.isShippingFromBusiness = product.isShippingFromBusiness;
    }
    if (product.hasAgreeToTerms) {
      data.hasAgreeToTerms = product.hasAgreeToTerms;
    }

    const ProductDoc = this.db.collection('products/').doc(product.id);
    // TODO for a secure reason we details every field that we allow to be updated
    return ProductDoc.update(environment.removeEmpty(data));
  }

  remove(productId: string, reason: string) {
    const data: Product = {
      removeReason: reason,
      isRemoved: true
    };
    const ProductDoc = this.db.collection('products/').doc(productId);
    return ProductDoc.update(data);
  }

  get(id: string): Observable<Product> {
    return this.db.doc('products/' + id)
      .snapshotChanges().pipe(
        map((a: any) => {
          const data = a.payload.data() as Product;
          if (data) {
            data.id = a.payload.id;
          }
          return data;
        }),
        catchError(this.handleError<Product>(`getProduct id=${id}`))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead - if we dont do this we wont see the error in red in the console
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
