<section class="bg-gray-light">
    <div class="container pt-5 py-md-5">
      <hr/>
      <div class="row">
        <div class="col-sm-4 mt-3">
          <div class="img-preview d-flex" *ngIf="product.image">
            <img [src]="product.image.src" alt="your uploaded image"
                 class="img-fluid m-auto d-block">
          </div>
        </div>
        <div class="col-sm-8 mt-3">
          <p class="mb-0 mb-md-3"><b>CATEGORY:</b> {{product.category}}</p>
          <p class="mb-0 mb-md-3"><b>SUB-CATEGORY:</b> {{product.subCategory}}</p>
          <p class="mb-0 mb-md-3"><b>BRAND:</b> {{product.brand}}</p>
          <p class="mb-0 mb-md-3"><b>PRICE:</b> {{variant.price | currency:'AUD':'symbol'}}</p>
          <p class="mb-0 mb-md-3"><b>FOR ME:</b> {{variant.price | currency:'AUD':'symbol'}}</p>
          <p *ngIf="product.published_at">Published ({{product.published_at | timeUntilNow}})</p>
        </div>
      </div>
      <hr/>
    </div>
</section>
