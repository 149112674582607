import {
  Component,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver, Input, Output, EventEmitter
} from '@angular/core';

import {AlertService, BusinessService} from '../../../app/_services';
import {BusinessPayout, Business, User} from '../../../app/_models';
import {AlertComponent} from '../../../app/_directives';
import {LoaderService} from '../../../app/shared/loader/loader.service';

@Component({
  selector: 'app-create-business',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],

})
export class ListBusinessCreateComponent {
  isLoading = false;
  show = {
    selectType: true,
    nextStep: false
  };

  @ViewChild('alertUpdate', { read: ViewContainerRef }) alertUpdate: ViewContainerRef;
  @Input('business') business: Business = {};
  @Input('user') user: User = {};
  constructor(private loaderService: LoaderService, private cfr: ComponentFactoryResolver,
              private alertService: AlertService, private businessService: BusinessService) {
  }
  selectBusinessType(type: string) {
    if (this.business && this.business.id && this.business.businessType) {
      this.show = {selectType: false, nextStep: true};
    } else {
      this.isLoading = true;
      this.loaderService.show();
      // if not business we create one withe the user as owner
      this.business.businessType = type;
      this.businessService.create(this.business, this.user)
        .then(() => {
          this.show = {selectType: false, nextStep: true};
          this.isLoading = false;
          this.loaderService.hide();
        })
        .catch((e) => {
          this.showAlert('alertUpdate');
          this.alertService.success(e.message);
          this.isLoading = false;
          this.loaderService.hide();
        });
    }
  }
  showAlert(target) {
    this[target].clear();
    const factory = this.cfr.resolveComponentFactory(AlertComponent);
    const ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }
}
