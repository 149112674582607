<header class="d-flex">
  <div class="container text-center mt-auto">
    <!-- Content here -->
    <div class="row row-footer align-items-center">
      <div class="col-12 my-5 my-sm-0 ">
        <h1>Welcome to Luxify <br/><span class="primary-color">Marketplace</span></h1>
        <p class="large">Start listing your items below</p>
        <div class="text-center pt-6 pb-3">
          <img src="../../assets/images/icons/arrow-down.svg" alt="next"/>
        </div>
      </div>
    </div>
  </div>
</header>
<section *ngIf="show.selectType && business">
  <div class="container text-center my-5">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <h2>Are you an <span class="primary-color">individual</span> or a <span class="primary-color">company</span>?</h2>
        <p class="mt-5">
          Luxify is a marketplace for both individuals and businesses. By identifying your unique situation, we will optimize your business for maximal growth and benefits.
        </p>

        <ng-template #alertUpdate></ng-template>
        <div class="row justify-content-center align-items-center text-center">
          <div class="col-sm-6 mt-5">
            <div class="col-img is-disable font-weight-light" [ngClass]="{'active' : business.businessType === 'individual'}">
              <span>INDIVIDUAL<br/><span class="small mt-3" style="font-size: 20px;">COMING IN 2020</span></span>
              <img src="../../assets/images/temps/provider_type_1.jpg" class="img-fluid mx-auto d-block" alt="Distributors and Resellers">
            </div>
          </div>
          <div class="col-sm-6 mt-5">
            <div class="col-img  font-weight-light" [ngClass]="{'active' : business.businessType === 'company'}" (click)="selectBusinessType('company')" >
              <span>COMPANY</span>
              <img src="../../assets/images/temps/provider_type_2.jpg" class="img-fluid mx-auto d-block" alt="Distributors and Resellers">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="show.nextStep">
  <div class="container text-center my-5">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <h3>You can complete your <span class="primary-color">business profile</span> or start <span class="primary-color">listing your product</span></h3>
        <p class="mt-4">
          You may choose to skip this for now and complete your business profile when its convenient for you, from your Account Settings.
        </p>

        <div class="row justify-content-center align-items-center text-center">
          <div class="col-sm-6 mt-5">
            <a [routerLink]="['/list-product']" class="btn btn-primary text-uppercase px-5">START LISTING your product</a>
          </div>
          <div class="col-sm-6 mt-5">
            <a [routerLink]="['/list-business/step-1']" class="btn btn-primary text-uppercase px-5">CONTINUE business profile</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
