import {
  Component, Input, Output, EventEmitter
} from '@angular/core';

import {ProductService} from '../../../app/_services';
import {Product, Variant} from '../../../app/_models';
import {LoaderService} from '../../../app/shared/loader/loader.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-list-product-medias',
  templateUrl: './medias.component.html',
  styleUrls: ['./medias.component.scss'],

})
export class ListProductMediasComponent {
  @Input('product') product: Product = {};
  @Input('photos-list') photosList: any[] = [];
  @Input('file-upload-folder') fileUploadFolder: string;
  @Output() stepReturn: EventEmitter<{ step: string, product: Product }> = new EventEmitter<{ step: string, product: Product }>();

  isLoading = false;

  constructor(private loaderService: LoaderService,
              private productService: ProductService) {
  }

  getIdFilePath(filePath: { absUrl: string, path: string }, index, field) {
    const publicUrl = `http://${environment.firebase.storageBucket}.storage.googleapis.com/${filePath.path}`;
    this.isLoading = true;
    this.loaderService.show();
    const data: Product = {
      id: this.product.id
    };
    if (field === 'images') {
      this.product.images[index] = {src: publicUrl};
      data.images = this.product.images;
      if (index === 0) {
        this.product.image = this.product.images[0];
        data.image =  this.product.image;
      }
    } else if (field === 'video') {
      this.product.video = publicUrl;
      data.video = this.product.video;
    }
    this.productService.update(data).then(() => {
      this.stepReturn.emit({step: null, product : this.product});
      this.isLoading = false;
      this.loaderService.hide();
    });
  }

  deleteIdFilePath(index, field) {
    this.isLoading = true;
    this.loaderService.show();
    // product: Product, fieldName: string, data: any
    const data: Product = {
      id: this.product.id
    };
    if (field === 'images') {
      this.product.images[index] = {};
      data.images = this.product.images;
      if (index === 0) {
        this.product.image = {};
        data.image = {};
      }
    } else if (field === 'video') {
      this.product.video = null;
      data.video = null;
    }
    this.productService
      .update(data)
      .then(() => {
        this.isLoading = false;
        this.loaderService.hide();
      });
  }

  isValid() {
    let isPageValid = true;
    this.photosList.forEach((value, index) => {
      if (!this.product.images[index]) {
        this.product.images[index] = {};
        isPageValid = false;
      } else if (!this.product.images[index].src) {
        isPageValid = false;
      }
    });
    return isPageValid;
  }

  goNext() {
    return this.stepReturn.emit({step: 'characteristics', product : null});
  }
  goBack() {
    return this.stepReturn.emit({step: 'details', product : null});
  }
}
