import {Component, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';

import {LoaderService} from './loader.service';
import {LoaderState} from './loader';


@Component({
  selector: 'app-loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['loader.component.scss']
})

export class LoaderComponent implements OnInit {
  show = false;
  private subscription: Subscription;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    const self = this;
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        self.show = state.show;
      });
  }
}
