<section class="bg-gray-light" id="salesSection">
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="text-center my-7" *ngIf="sales.length === 0">
          <p>Hey you don't have any sales listed yet.</p>
          <button class="btn btn-primary d-block mt-3 mx-auto" [routerLink]="['/list-product']">START SELLING</button>
        </div>
        <div class="card" *ngIf="sales.length">
          <div class="card-header" id="headingSalesList">
            <h2 class="mb-0">
              <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#salesList" aria-expanded="true" aria-controls="salesList">
                MY SALES <span class="primary-color">({{sales.length}})</span>
              </button>
            </h2>
          </div>
          <div id="salesList" class="collapse show" aria-labelledby="headingSalesList">
            <div class="card-body" *ngFor="let payment of sales; let i=index">
              <div class="row">
                <div class="col-sm-4 col-md-3 row-eq-height d-flex mt-3">
                  <span *ngIf="!payment.products || !payment.products[0] || !payment.products[0].image" class="custom-avatar m-auto">L</span>
                  <div class="img-preview d-flex" *ngIf="payment.products && payment.products[0] && payment.products[0].image" >
                    <img [src]="payment.products[0].image.src" alt="your uploaded image"
                         class="img-fluid m-auto d-block">
                  </div>
                </div>
                <div class="col-sm-8 col-md-9 row-eq-height mt-3">
                <p class="text-truncate mb-0"><b>REFERENCE:</b> {{payment.id}}</p>
                  <p class="text-truncate mb-0" *ngIf="payment.products"><b>PRODUCT ({{payment.products.length}}):</b> {{payment.products[0].brand}} {{payment.products[0].model}}<span *ngIf="payment.products.length>1">...</span></p>
                  <p class="text-truncate mb-0"><b>DATE:</b> {{payment.charger_at | timeToLabel :'dddd DD/MM/YYYY H:mm'}}</p>
                  <p class="text-truncate mb-0"><b>SELLING PRICE:</b> {{payment.amount | currency:'AUD':'symbol'}}</p>
                  <p class="text-truncate mb-0"><b>MY PROFIT:</b> {{payment.amount | currency:'AUD':'symbol'}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
