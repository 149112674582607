import {
  Component
} from '@angular/core';

import {AppService, BusinessService} from '../../app/_services';
import {BusinessPayout, Business, User} from '../../app/_models';
import {LoaderService} from '../../app/shared/loader/loader.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-list-business',
  templateUrl: './list-business.component.html',
  styleUrls: ['./list-business.component.scss'],

})
export class ListBusinessComponent {
  business: Business = {businessType: 'company', address: {}, profileImage: null,     representativeIdentityProof: null};
  user: User;
  customAccount: BusinessPayout = {address: {country: null}};
  show = {
    create: false,
    details: false,
    profile: false,
    representative: false,
    shipping: false,
    bankDetails: false,
    review: false,
    congratulation: false,
  };

  fileUploadFolder: string;
  firstLoad = true;
  countries: any[] = this.appService.countryList();
  currencies: any[] = this.appService.currenciesList();


  constructor(private loaderService: LoaderService,
              private location: Location,
              private router: Router,
              private route: ActivatedRoute,
              private appService: AppService,
              private businessService: BusinessService) {
    const localData = localStorage.getItem('currentUser');
    this.user = localData && localData !== 'undefined' ? JSON.parse(localData) : {id: null};
    if (!this.user.id) {
      this.router.navigate(['/']).then();
    } else if (!this.user.businessId) {
      this.switchView('create');
    } else {
      this.loaderService.show();
      this.getBusiness(this.user.businessId);
    }
  }

  getBusiness(businessId) {
    this.businessService.get(businessId).subscribe((res: Business) => {

      if (!res || !res.businessType || !res.id) {
        this.loaderService.hide();
        this.switchView('create');
        return 0;
      }
      this.fileUploadFolder = `images/businesses/${res.id}/identity`;
      if (this.firstLoad) {
        if (!res.address) {
          res.address = {};
        }
        this.business = res;
        this.firstLoad = false;
        // this.switchView('details');
        this.getPaymentDetails(this.business);
      } else {
        this.business = res;
      }
    }, () => {
      this.router.navigate(['404']).then();
      this.loaderService.hide();
    });
  }

  getPaymentDetails(business: Business) {
    this.businessService.getPayoutDetails(business.id).subscribe((bankDetails: BusinessPayout) => {
      this.customAccount = bankDetails || {};
      if (!bankDetails || !bankDetails.bankAccountName) {
        this.customAccount.bankAccountName = business.representativeFirstName + ' ' + business.representativeLastName;
      }
      this.route.params.subscribe(params => {
        if (!business.name  || !business.address  || !business.address.countryCode) {
          this.switchView('details');
        } else if (!business.description) {
          this.switchView('profile');
        } else if (!business.representativeDateOfBirth || !business.representativePhone
          || !business.representativeEmail || !business.representativeFirstName || !business.representativeLastName) {
          this.switchView('representative');
        } else if (!this.business.address || !this.business.address.countryCode) {
          this.switchView('details');
        } else {
          switch (params.step) {
            case 'step-1':
              this.switchView('details');
              break;
            case 'step-2':
              this.switchView('profile');
              break;
            case 'step-3':
              this.switchView('representative');
              break;
            case 'step-4':
              this.switchView('shipping');
              break;
            case 'step-5':
              this.switchView('bankDetails');
              break;
            case 'step-6':
              this.switchView('review');
              break;
            default:
              this.switchView('details');
          }
        }
        this.loaderService.hide();
      });
    });
  }

  switchView(step) {
    this.show = {
      details: false,
      profile: false,
      representative: false,
      shipping: false,
      bankDetails: false,
      review: false,
      create: false,
      congratulation: false
    };
    switch (step) {
      case 'details':
        this.location.go('list-business/step-1');
        break;
      case 'profile':
        this.location.go('list-business/step-2');
        break;
      case 'representative':
        this.location.go('list-business/step-3');
        break;
      case 'shipping':
        this.location.go('list-business/step-4');
        break;
        case 'bankDetails':
        this.location.go('list-business/step-5');
        break;
      case 'review':
        this.location.go('list-business/step-6');
        break;
      default:
        this.location.go('list-business');
    }
    this.show[step] = true;
  }

  stepReturn(result: { step: string, business: Business, customAccount: BusinessPayout }) {
    if (result.business) {
      this.business = result.business;
    }
    if (result.customAccount) {
      this.customAccount = result.customAccount;
    }
    if (result.step) {
      this.switchView(result.step);
    }
    window.scrollTo(0, 0);
    this.loaderService.hide();
  }
}
