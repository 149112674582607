import {
  Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';

import {BusinessService} from '../../../app/_services';
import {BusinessPayout, Business, User} from '../../../app/_models';
import {LoaderService} from '../../../app/shared/loader/loader.service';

@Component({
  selector: 'app-list-business-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ListBusinessProfileComponent implements OnInit   {

  @Input('business') business: Business = {};
  @Input('user') user: User = {};
  @Input('fileUploadFolder') fileUploadFolder: string;
  @Output() stepReturn: EventEmitter<{ step: string, business: Business, customAccount: BusinessPayout}>
    = new EventEmitter<{ step: string, business: Business, customAccount: BusinessPayout }>();

  isLoading: boolean;
  needToUpdate = false;

  constructor(private loaderService: LoaderService,
              private businessService: BusinessService) {
  }

  ngOnInit() {
    if (!this.business.profileImage) {
      this.business.profileImage = null;
    }
  }
  goBack() {
    return this.stepReturn.emit({step: 'details', business : null, customAccount: null});
  }

  getIdFilePath(filePath: { absUrl: string, path: string }) {
    this.isLoading = true;
    this.loaderService.show();
    // product: Product, fieldName: string, data: any
    this.business.profileImage = filePath.absUrl;
    this.businessService.updateField(this.business, 'profileImage', this.business.profileImage).then(() => {
      this.isLoading = false;
      this.loaderService.hide();
    });
  }
  deleteIdFilePath() {
    this.isLoading = true;
    this.loaderService.show();
    // product: Product, fieldName: string, data: any
    this.business.profileImage = null;
    this.businessService
      .updateField(this.business, 'profileImage', null)
      .then(() => {
        this.isLoading = false;
        this.loaderService.hide();
      });
  }

  updateBusiness() {
    if (!this.needToUpdate) {
      return this.stepReturn.emit({step: 'representative', business : null, customAccount: null});
    }
    this.isLoading = true;
    this.loaderService.show();
    this.businessService.update(this.business, this.user).then(() => {
      return this.stepReturn.emit({step: 'representative', business : this.business, customAccount: null});
    }).then(() => {
      this.isLoading = false;
      this.loaderService.hide();
    });
  }

  onChange() {
    this.needToUpdate = true;
  }
}
