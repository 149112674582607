
  <div class="bg-gray-light position-absolute bg-page"></div>
  <header class="d-flex">
    <div class="container text-center mt-auto">
      <!-- Content here -->
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-9">
          <div class="my-5" *ngIf="show.details">
            <h1>Product Details</h1>
            <p class="">Start listing your products below.<br/>Once your listing is completed we will verify and confirm it within 48 hours.</p>
          </div>
          <div class="my-5" *ngIf="show.medias">
            <h1>Photos & Videos</h1>
            <p class="primary-color font-weight-bold text-uppercase">{{currentProduct.brand}}</p>
            <p class="font-italic">{{currentProduct.model}}</p>
          </div>
          <div class="my-5" *ngIf="show.characteristics">
            <h1>Characteristics</h1>
            <p class="primary-color font-weight-bold text-uppercase">{{currentProduct.brand}}</p>
            <p class="font-italic">{{currentProduct.model}}</p>
          </div>
          <div class="my-5" *ngIf="show.prices">
            <h1>Condition & Price</h1>
            <p class="primary-color font-weight-bold text-uppercase">{{currentProduct.brand}}</p>
            <p class="font-italic">{{currentProduct.model}}</p>
          </div>
          <div class="my-5" *ngIf="show.seller">
            <h1>Seller Information</h1>
            <p class="primary-color font-weight-bold text-uppercase">{{currentProduct.brand}}</p>
            <p class="font-italic">{{currentProduct.model}}</p>
          </div>
          <div class="my-5" *ngIf="show.thanks">
            <h1>THANK YOU!</h1>
            <p class="">We will now review your listing and get back to you shortly.</p>
            <a [routerLink]="['/list-product/details']" class="btn btn-primary">MAKE A NEW LISTING</a>
          </div>
          <nav class="nav nav-pills flex-column flex-sm-row" *ngIf="!show.thanks">
            <button class="flex-sm-fill text-sm-center nav-link active" [ngClass]="{'active': show.details}" (click)="switchView('details')"><span>1</span>PRODUCT DETAILS</button>
            <button class="flex-sm-fill text-sm-center nav-link" [ngClass]="{'active': show.medias}" (click)="switchView('medias')" [disabled]="!currentProduct.id || !isValid.details"><span>2</span>MEDIAS</button>
            <button class="flex-sm-fill text-sm-center nav-link" [ngClass]="{'active': show.characteristics}" (click)="switchView('characteristics')" [disabled]="!currentProduct.id || !isValid.details || !isValid.medias"><span>3</span>CHARACTERISTICS</button>
            <button class="flex-sm-fill text-sm-center nav-link" [ngClass]="{'active': show.prices}" (click)="switchView('prices')" [disabled]="!currentProduct.id || !isValid.details || !isValid.medias || !isValid.characteristics"><span>4</span>CONDITION & PRICE</button>
            <button class="flex-sm-fill text-sm-center nav-link" [ngClass]="{'active': show.seller}" (click)="switchView('seller')" [disabled]="!currentProduct.id || !isValid.details || !isValid.medias || !isValid.characteristics || !isValid.prices"><span>5</span>SELLER INFO</button>
          </nav>
        </div>
      </div>
    </div>
  </header>

  <app-list-product-details *ngIf="show.details" (stepReturn)="stepReturn($event)" [business]="currentBusiness" [years]="years" [product]="currentProduct" [variant]="defaultVariant"  [colors-list]="colorsList" [materials-list]="materialsList" [data-structure]="realProductDatas"></app-list-product-details>
  <app-list-product-medias  *ngIf="show.medias" (stepReturn)="stepReturn($event)" [product]="currentProduct" [photos-list]="photosList" [file-upload-folder]="fileUploadFolder"></app-list-product-medias>
  <app-list-product-characteristics *ngIf="show.characteristics" (stepReturn)="stepReturn($event)" [years]="years" [product]="currentProduct" [variant]="defaultVariant" [file-upload-folder]="fileUploadFolder"></app-list-product-characteristics>
  <app-list-product-prices *ngIf="show.prices" [variant]="defaultVariant" [product]="currentProduct" (stepReturn)="stepReturn($event)" [currencies]="currencies"></app-list-product-prices>
  <app-list-product-seller *ngIf="show.seller" [product]="currentProduct" [variant]="defaultVariant" (stepReturn)="stepReturn($event)" [countries]="countries"></app-list-product-seller>
  <app-list-product-thanks *ngIf="show.thanks" [product]="currentProduct" [variant]="defaultVariant"></app-list-product-thanks>



