<section class="bg-gray-light">
  <div class="container pt-5 py-md-5">
    <form class="" (ngSubmit)="updateBusiness()" #updateBusinessForm="ngForm">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <h2 class="mt-3 mb-5 text-center">Your business profile</h2>
          <p class="text-center mb-5">
            By creating a public profile for everyone to see you gain trust from customers.<br/>
            This is your chance to showcase your business.
          </p>
          <div class="form-group">
            <label class="col-form-label" for="businessDescription">DESCRIBE YOUR BUSINESS<span class="required">*</span></label>
            <textarea type="text" rows="5" class="form-control form-control-lg" id="businessDescription" placeholder="Enter your business description" [(ngModel)]="business.description" name="businessDescription" (change)="onChange()" required></textarea>
          </div>
          <div class="form-group">
            <label class="col-form-label" for="facebookPage">LINK YOUR FACEBOOK PAGE</label>
            <input type="text" class="form-control form-control-lg" id="facebookPage" placeholder="Enter your Facebook page URL" [(ngModel)]="business.facebookPage" (change)="onChange()" name="facebookPage">
          </div>
          <div class="form-group">
            <label class="col-form-label" for="instagramPage">LINK YOUR INSTAGRAM PROFILE</label>
            <input type="text" class="form-control form-control-lg" id="instagramPage" placeholder="Enter your instagram profile URL" [(ngModel)]="business.instagramPage" (change)="onChange()" name="instagramPage">
          </div>
          <div class="form-group">
            <label class="col-form-label" for="linkedinPage">LINK YOUR LINKEDIN PAGE</label>
            <input type="text" class="form-control form-control-lg" id="linkedinPage" placeholder="Enter your Linkedin profile URL" [(ngModel)]="business.linkedinPage" (change)="onChange()" name="linkedinPage">
          </div>
          <div class="text-center">
            <h2 class="mt-3 my-5 text-center">Your account profile photo</h2>
            <p>
              Upload a photo which will appear as your account profile photo, this can be a photo of yourself or a company logo. We recommend a photo of a person as it helps gain trust from customers.
            </p>
            <p class="primary-color font-italic">
              You may choose to skip this for now and upload a profile photo when its convinient for you, from your Account Settings.
            </p>

            <div class="form-group">
              <app-file-upload *ngIf="business.id && fileUploadFolder" [currentfileurl]="business.profileImage" [folder]="fileUploadFolder" [hide-preview]="false" [view-height]="400"
                           [extensions]="['png','jpg','jpeg']" (fileDeleted)="deleteIdFilePath()" (filepath)="getIdFilePath($event)" id="profileImage" [max-size]="5242880"></app-file-upload>
              <p class="text-left form-text text-muted small">The file must be JPG, JPEG or PNG and less than 5MB.</p>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-6 text-left">
              <button type="button" (click)="goBack()" class="btn btn-outline-primary">GO BACK TO STEP 1</button>
            </div>
            <div class="col-6 text-right">
              <button type="submit" class="btn btn-primary" [disabled]="!updateBusinessForm.form.valid">COMPLETE STEP 2</button>
            </div>
          </div>
        </div>
      </div>

    </form>

  </div>
</section>
