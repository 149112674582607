import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {UserService} from './user.service';
import {auth} from 'firebase/app';
import {User} from '../_models';
import {AngularFirestore} from '@angular/fire/firestore';
import {BusinessService} from './business.service';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';


import { Observable, Subject } from 'rxjs';
import {LoaderService} from '../shared/loader/loader.service';

@Injectable()
export class IsUserLoginService {
  private subject = new Subject<User>();

  onLogin(user: User) {
    this.subject.next(user);
  }

  onLogout() {
    this.subject.next({});
  }

  getUser(): Observable<any> {
    return this.subject.asObservable();
  }
}

@Injectable()
export class AuthenticationService {
  constructor(public afAuth: AngularFireAuth,
              private userService: UserService,
              private businessService: BusinessService,
              private db: AngularFirestore,
              private isUserLoginService: IsUserLoginService) {
  }

  login(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password)
        .then((authUser: any) => {
          if (authUser.user && authUser.user.emailVerified) {
            return this.pullUserData(authUser.user.uid).then((userData: any) => {
              this.isUserLoginService.onLogin(userData.user);
              localStorage.setItem('currentUser', JSON.stringify(userData.user));
              localStorage.setItem('currentBusiness', JSON.stringify(userData.business));
              resolve(userData.user);
            });
          } else {
            reject({
              code: 'auth/email-not-verified',
              message: 'Please, validate your email first.'
            });
          }
        })
        .catch(error => {
          reject(error);
        });

    });
  }


  loginGoogleUser() {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider())
        .then((credential: any) => {
          const userResult = credential.user;
          const user: any = {
            id: userResult.uid,
            email: userResult.email,
            created_at: moment().format()
          };
          if (userResult.displayName) {
            user.firstName = (userResult.displayName.split(' '))[0];
            if ((userResult.displayName.split(' ')).length > 1) {
              user.lastName = (userResult.displayName.split(' '))[1];
            }
          }
          const usersCollection = this.db.collection<User>('users');
          // add new user
          usersCollection.doc(user.id).set(user, {merge: true})
            .then(() => {
              const currentUser = this.afAuth.auth.currentUser;
              return currentUser.updateProfile({displayName: user.firstName, photoURL: null})
                .then(() => {
                  return this.pullUserData(user.id).then((userData: any) => {
                    this.isUserLoginService.onLogin(userData.user);
                    localStorage.setItem('currentUser', JSON.stringify(userData.user));
                    localStorage.setItem('currentBusiness', JSON.stringify(userData.business));
                    resolve(userData.user);
                  });
                });
            })
            .catch(
              (error) => {
                reject(error);
              }
            );
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  logout() {
    return new Promise((resolve: any, reject: any) => {
      // remove user from local storage to log user out
      this.isUserLoginService.onLogout();
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentBusiness');
      this.afAuth.auth.signOut().then((res) => {
        resolve(res);
      }).catch(error => {
        reject(error);
      });
    });
  }

  isLogin() {
    return new Promise((resolve) => {
      this.afAuth.authState.subscribe((authUser) => {
        resolve(authUser !== null ?
          {id: authUser.uid, email: authUser.email, emailVerified: authUser.emailVerified}
          : {id: null, email: null, emailVerified : false});
      });
    });
  }

  pullUserData(userId: string) {
    return new Promise((resolve) => {
      this.userService.get(userId)
        .subscribe((userData) => {
          this.isUserLoginService.onLogin(userData);
          if (userData && userData.businessId) {
            return this.businessService.get(userData.businessId).subscribe((businessData) => {
              resolve({user: userData, business: businessData});
            });
          } else {
            resolve({user: userData});
          }
        });
    });
  }

  sendEmailVerification(email) {
    // this.af.auth.getAuth().auth.sendEmailVerification();
    return new Promise((resolve, reject) => {
      const currentUser = this.afAuth.auth.currentUser;
      if (currentUser.email === email && !currentUser.emailVerified) {
        this.afAuth.auth.currentUser.sendEmailVerification()
          .then((res) => {
            resolve(currentUser);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject({message: (currentUser.emailVerified ? 'Your email has been already validated' : 'Email no validated')});
      }
    });
  }

  handleResetPassword(actionCode, newPassword): Promise<string> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.confirmPasswordReset(actionCode, newPassword)
        .then((resp) => {
          resolve('Your can now sign in with your new password.');
        })
        .catch((error) => {
          reject(error.message || 'Error occurred during confirmation. The code might have expired or the password is too weak.');
        });
    });
  }
}
