import {
  Component, Input
} from '@angular/core';

import {Product, Variant} from '../../../app/_models';

@Component({
  selector: 'app-list-product-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss'],

})
export class ListProductThanksComponent {
  @Input('product') product: Product = {};
  @Input('variant') variant: Variant = {};
  constructor() {}
}
