/*PAGES*/

export * from './landing-page/landing-page.component';

export * from './list-product/list-product.component';
export * from './list-product/details/details.component';
export * from './list-product/medias/medias.component';
export * from './list-product/characteristics/characteristics.component';
export * from './list-product/prices/prices.component';
export * from './list-product/seller/seller.component';
export * from './list-product/thanks/thanks.component';

export * from './list-business/list-business.component';
export * from './list-business/create/create.component';
export * from './list-business/details/details.component';
export * from './list-business/profile/profile.component';
export * from './list-business/representative/representative.component';
export * from './list-business/shipping/shipping.component';
export * from './list-business/bank-details/bank-details.component';
export * from './list-business/review/review.component';

export * from './preview-product/preview-product.component';

export * from './account/account.component';
export * from './account/profile/profile.component';
export * from './account/products/products.component';
export * from './account/sales/sales.component';
export * from './account/payments/payments.component';
export * from './terms/terms.component';
