import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService, IsUserLoginService} from './authentication.service';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable()
export class EmailValidationGuardService implements CanActivate {
  constructor(public auth: AuthenticationService,
              public router: Router,
              public afAuth: AngularFireAuth,
              private isUserLoginService: IsUserLoginService) {}
  private checkUser(): Promise<any> {
    return new Promise((resolve) => {
      this.auth.isLogin()
        .then((user: any): any => {
          if (user && user.id) {
            if (!user.emailVerified) {
              this.isUserLoginService.onLogout();
              localStorage.removeItem('currentUser');
              localStorage.removeItem('currentBusiness');
              resolve(false);
              return false;
            }
            const localData = localStorage.getItem('currentUser');
            const currentUser = localData && localData !== 'undefined' ? JSON.parse(localData) : {};

            if (currentUser.id) {
              this.isUserLoginService.onLogin(currentUser);
              resolve(true);
            } else {
              return this.auth.pullUserData(user.id).then((res: any) => {
                if (res) {
                  this.isUserLoginService.onLogin(res.user);
                  localStorage.setItem('currentUser', JSON.stringify(res.user));
                  localStorage.setItem('currentBusiness', JSON.stringify(res.business));
                }
                resolve(true);
              });
            }
          } else {
            resolve(false);
          }
        });
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return new Promise((resolve) => {
      // https://firebase.google.com/docs/auth/custom-email-handler
      // return true to process, false to stop here
      // Get the action to complete.
      const mode: string = route.queryParams.mode;
      // Get the one-time code from the query parameter.
      const actionCode: string = route.queryParams.oobCode;
      // (Optional) Get the continue URL from the query parameter if available.
      // const continueUrl: string = this.route.snapshot.queryParams.continueUrl;
      // (Optional) Get the language code if available.
      // const lang: string = this.route.snapshot.queryParams.lang || 'en';
      // Handle the user management action.
      if (mode) {
        switch (mode) {
          case 'resetPassword':
            this.afAuth.auth.verifyPasswordResetCode(actionCode)
              .then((email) => {
                // TODO: Show the reset screen with the user's email and ask the user for
                const data = {success: true, mode: 'showResetPasswordModal', actionCode, message : 'For ' + email};
                localStorage.setItem('EmailValidationResult', JSON.stringify(data));
                return this.checkUser();
              })
              .catch((error) => {
                const data = {success: false, mode: 'showResetPasswordModal',
                  message : error.message || 'Invalid or expired action code. Ask user to try to reset the password again.'};
                return localStorage.setItem('EmailValidationResult', JSON.stringify(data));
              })
              .finally(() => {
                // Always return true
                resolve(true);
              });
            break;
          /*case 'recoverEmail':
           // Display email recovery handler and UI.
           // handleRecoverEmail(auth, actionCode, lang);
           break;*/
          case 'verifyEmail':
            this.afAuth.auth.applyActionCode(actionCode)
              .then(() => {
                const data = {success: true, mode: 'verifyEmailSuccess',
                  message : 'Your email has been verified, You can now sign in with your new account.'};
                localStorage.setItem('EmailValidationResult', JSON.stringify(data));
                return this.checkUser();
              })
              .catch((error) => {
                const data = {success: false, message : error.message || 'Code is invalid or expired. Can you verify you email address'};
                return localStorage.setItem('EmailValidationResult', JSON.stringify(data));
              })
              .finally(() => {
                // Always return true
                resolve(true);
              });

            break;
          default:
            resolve(true);
          // Error: invalid mode.
        }
      } else {
        resolve(true);
      }
    });
  }
}
